import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Plot from 'react-plotly.js';

function PlotStackBalanceEnergia(props) {
  let colores = ['#44546A', '#4473C4', '#00CAF0', '#4473C4', '#00B0F0', '#767171'];
  const { curvas } = useSelector((store) => store.balanceCurvas);

  useEffect(() => {
    // Plotly.Plots.resize();
  }, []);

  let data = [];
  for (let i = 0; i < curvas.length; i++) {
    let generador = curvas[i];
    let trace = new Object();
    trace.x = generador.tiempos;
    trace.y = generador.potencias;
    trace.name = generador.generadorNombre;
    trace.type = 'bar';
    trace.width = 940 * 60 * 5;
    trace.hoverinfo = 'y';
    trace.marker = { color: colores[i] };
    trace.hoverlabel = { font: { family: 'Montserrat', size: 10 } };
    data.push(trace);
  }

  let layout = {
    title: { text: 'Cargas de potencia - Inyectores de energía', font: { color: '#363636', family: 'Montserrat', size: 13 }, y: 0.92 },
    legend: { font: { color: '#363636', family: 'Montserrat', size: 11 }, itemsizing: 'constant', orientation: 'h', xanchor: 'center', x: 0.5, y: -0.16 },
    margin: { b: 78, l: 50, r: 40, t: 45 },
    autosize: true,
    xaxis: {
      title: { text: 'Tiempo', font: { color: '#363636', family: 'Montserrat', size: 9 } },
      tickfont: { color: '#363636', family: 'Montserrat', size: 8 },
      linecolor: '#eee',
      zerolinecolor: '#eee'
    },
    yaxis: {
      title: { text: 'Potencia [kW]', font: { color: '#363636', family: 'Montserrat', size: 9 } },
      tickfont: { color: '#363636', family: 'Montserrat', size: 8 },
      linecolor: '#eee',
      zerolinecolor: '#eee'
    },
    font: { family: 'Montserrat', size: 10 },
    barmode: 'stack'
  };

  return (
    <Plot
      data={data}
      layout={layout}
      useResizeHandler
      config={{ displaylogo: false, displayModeBar: true, modeBarButtonsToRemove: ['toImage', 'select2d', 'lasso2d', 'resetScale2d'], responsive: true }}
      className='w-100 h-100'
    />
  );
}

export default PlotStackBalanceEnergia;
