import React, { useEffect } from 'react';
import { Container, Row, Col, Navbar, Breadcrumb } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import PeriodoDropDown from '../../componentes/PeriodoDropDown';
import { getReporteCierre, updateReporteCierre } from '../../redux/reporteCierreSlice';
import { selectPeriodo } from '../../redux/periodoSlice';
import { pintaPeriodo } from '../../libreria/utilidades';
import ProcessStatus from './ProcessStatus';
import StepStatus from './StepStatus';
import './PanelProcesoCierre.css';

function PanelProcesoCierre() {
  const comunidad = useSelector((store) => store.comunidad);
  const periodo = useSelector((store) => store.periodo);
  const reporteCierre = useSelector((store) => store.reporteCierre);
  const dispatch = useDispatch();

  const tarifasResultado = () => (reporteCierre.reporte ? reporteCierre.reporte.tarifas_resultado_ejecucion : null);
  const tarifasFecha = () => (reporteCierre.reporte ? reporteCierre.reporte.tarifas_fecha_ejecucion : null);
  const tarifasNumRegistros = () => (reporteCierre.reporte ? reporteCierre.reporte.tarifas_num_registros : null);
  const compraResultado = () => (reporteCierre.reporte ? reporteCierre.reporte.compra_resultado_ejecucion : null);
  const compraFecha = () => (reporteCierre.reporte ? reporteCierre.reporte.compra_fecha_ejecucion : null);
  const compraNumRegistros = () => (reporteCierre.reporte ? reporteCierre.reporte.compra_num_registros : null);
  const dataResultado = () => (reporteCierre.reporte ? reporteCierre.reporte.data_resultado_ejecucion : null);
  const dataFecha = () => (reporteCierre.reporte ? reporteCierre.reporte.data_fecha_ejecucion : null);
  const dataNumRegistros = () => (reporteCierre.reporte ? reporteCierre.reporte.data_num_registros : null);
  const tarificacionResultado = () => (reporteCierre.reporte ? reporteCierre.reporte.tarificacion_resultado_ejecucion : null);
  const tarificacionFecha = () => (reporteCierre.reporte ? reporteCierre.reporte.tarificacion_fecha_ejecucion : null);
  const tarificacionNumRegistros = () => (reporteCierre.reporte ? reporteCierre.reporte.tarificacion_num_registros : null);
  const interesesResultado = () => (reporteCierre.reporte ? reporteCierre.reporte.intereses_resultado_ejecucion : null);
  const interesesFecha = () => (reporteCierre.reporte ? reporteCierre.reporte.intereses_fecha_ejecucion : null);
  const interesesNumRegistros = () => (reporteCierre.reporte ? reporteCierre.reporte.intereses_num_registros : null);
  const documentosResultado = () => (reporteCierre.reporte ? reporteCierre.reporte.documentos_resultado_ejecucion : null);
  const documentosFecha = () => (reporteCierre.reporte ? reporteCierre.reporte.documentos_fecha_ejecucion : null);
  const documentosNumRegistros = () => (reporteCierre.reporte ? reporteCierre.reporte.documentos_num_registros : null);

  const steps = [
    {
      nombre: 'tarifas',
      stepId: 1,
      titulo: 'Carga de tarifas del período',
      resultado: tarifasResultado,
      fecha: tarifasFecha,
      num_registros: tarifasNumRegistros,
      paginaDetalle: '/proceso-cierre/tarifas',
      descripcion: 'Carga mensual de las tarifas BT1 y BT4.3 para los distintos tipos de cargos a aplicar en el proceso de facturación.'
    },
    {
      nombre: 'compra',
      stepId: 2,
      titulo: 'Carga facturas generación y distribución',
      resultado: compraResultado,
      fecha: compraFecha,
      num_registros: compraNumRegistros,
      descripcion: 'Carga de las facturas de generación y distribución para el período en curso.'
    },
    {
      nombre: 'data',
      stepId: 3,
      titulo: 'Carga de lecturas fin del período',
      resultado: dataResultado,
      fecha: dataFecha,
      num_registros: dataNumRegistros,
      descripcion: 'Carga de la información consolidada del fin del período de lecturas y consumos, obtenida desde los medidores a través de la interfaz S04.'
    },
    {
      nombre: 'tarificacion',
      stepId: 4,
      titulo: 'Facturación del período',
      resultado: tarificacionResultado,
      fecha: tarificacionFecha,
      num_registros: tarificacionNumRegistros,
      descripcion: 'Proceso de cálculo de las tarifas a aplicar para el período en curso en base a las lecturas y consumos registrados.'
    },
    {
      nombre: 'intereses',
      stepId: 5,
      titulo: 'Cálculo saldos e intereses',
      resultado: interesesResultado,
      fecha: interesesFecha,
      num_registros: interesesNumRegistros,
      descripcion: 'Proceso de cálculo de saldos anteriores e intereses, en caso de no existir pagos para el período en curso.'
    },
    {
      nombre: 'documentos',
      stepId: 6,
      titulo: 'Generación de documentos',
      resultado: documentosResultado,
      fecha: documentosFecha,
      num_registros: documentosNumRegistros,
      paginaDetalle: '/proceso-cierre/generacion-documentos',
      descripcion: 'Proceso de envío de solicitud de generación de documentos a servicio externo a ePlat.'
    }
  ];

  function formatPeriodo(periodo) {
    return periodo.anno.toString() + '-' + (periodo.mes < 10 ? `0${periodo.mes.toString()}` : periodo.mes.toString());
  }

  function handlePeriodoClick(data) {
    dispatch(selectPeriodo(data));
  }

  function handleActualizarClick() {
    trackPromise(dispatch(updateReporteCierre(comunidad.selected.id, formatPeriodo(periodo))), 'area-cierre');
  }

  useEffect(() => {
    const onRender = () => {
      if (comunidad.isSelected) {
        trackPromise(dispatch(getReporteCierre(comunidad.selected.id, formatPeriodo(periodo))), 'area-cierre');
      }
    };
    onRender();
  }, [comunidad.selected, periodo]);

  return (
    <>
      <Container fluid className='PanelProcesoCierre d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Compra y venta de energía
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Proceso cierre mensual</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <p className='m-0 me-2 d-none d-sm-inline text-nowrap'>Período (mes/año):</p>
          <PeriodoDropDown className='me-3' onSelect={handlePeriodoClick} value={pintaPeriodo(periodo)} />
        </Navbar>
      </Container>

      {reporteCierre.reporte ? (
        <Container fluid>
          <Row className='p-2'>
            <Col xs={12} className='p-2'>
              <ProcessStatus onClick={handleActualizarClick} />
            </Col>
            {steps.map((item) => (
              <Col key={item.nombre} xs={12} className='p-2'>
                <StepStatus name={item.nombre} step={item} />
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <Container fluid className='p-0'>
          <ProcessStatus onClick={handleActualizarClick} className='m-3' />
        </Container>
      )}
    </>
  );
}

export default PanelProcesoCierre;
