import React, { useEffect, useState, forwardRef } from 'react';
import NotFoundPanel from '../NotFoundPanel';
import { Container, Row, Col, Card, Navbar, Breadcrumb, Table, Tab, Tabs, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { pintaNumero, pintaNumeroDecimales } from '../../libreria/utilidades';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { getBalanceCurvas } from '../../redux/balanceCurvasSlice';
import { getBalanceEnergia } from '../../redux/balanceEnergiaSlice';
import { getBalanceUltimoAnno } from '../../redux/balanceUltimoAnnoSlice';
import DatePicker, { registerLocale } from 'react-datepicker';
import PlotStackBalanceEnergia from './PlotStackBalanceEnergia';
import PlotLineBalanceEnergia from './PlotLineBalanceEnergia';
import './react-datepicker.css';
import es from 'date-fns/locale/es';
import { format, add, sub, toDate, startOfMonth, endOfMonth } from 'date-fns';
import './PanelBalanceEnergia.css';

registerLocale('es', es);

const RANGO_SEMANA = 7 * 24 * 60 * 60 - 1;
const RANGO_MES = 1;

function PanelBalanceEnergia() {
  /** Configuración de los rangos de fechas para las curvas de potencia */
  let hoy = new Date();
  hoy = dayInit(hoy);
  let hoyPlusSemana = add(hoy, { seconds: RANGO_SEMANA });
  const [fechaDesde, setFechaDesde] = useState(hoy);
  const [fechaHasta, setFechaHasta] = useState(hoyPlusSemana);
  /** Configuración de los rangos de fechas para las curvas de potencia */
  let hoyMes = new Date();
  hoyMes = dayInit(hoy);
  let hoyMesPlusMes = sub(add(hoyMes, { months: RANGO_MES }), { seconds: 1 });
  let rangoInicio = sub(hoyMesPlusMes, { days: 5 });
  let rangoFin = add(hoyMesPlusMes, { days: 5 });
  /** Configuración de ultimos 12 meses */
  let thisDay = new Date();
  let inicioUltimoAnno = sub(thisDay, { months: 12 });
  inicioUltimoAnno = startOfMonth(inicioUltimoAnno);
  let ultimoMes = sub(thisDay, { months: 1 });
  let finUltimoAnno = endOfMonth(ultimoMes);

  const [fechaMesDesde, setfechaMesDesde] = useState(hoyMes);
  const [fechaMesHasta, setFechaMesHasta] = useState(hoyMesPlusMes);

  const [fechaRangoInicio, setFechaRangoInicio] = useState(rangoInicio);
  const [fechaRangoFin, setFechaRangoFin] = useState(rangoFin);

  /** Configuración inicial del tipo de grafico a dibujar. */
  const [tipoGx, setTipoGx] = useState('linea');

  const tiposGx = [
    { name: 'línea', value: 'linea' },
    { name: 'stack', value: 'stack' }
  ];

  const { energias, totalEnergia, totalConsumo, totalPerdida, totalPorcentajePerdida } = useSelector((store) => store.balanceEnergia);
  const ultimoAnno = useSelector((store) => store.balanceUltimoAnno);

  const { promiseInProgress: balanceCurvasInProgress } = usePromiseTracker({ area: 'area-balanceCurvas', delay: 0 });
  const { promiseInProgress: balanceEnergiaInProgress } = usePromiseTracker({ area: 'area-balanceEnergia', delay: 0 });
  const { promiseInProgress: balanceUltimoAnnoInProgress } = usePromiseTracker({ area: 'area-balanceUltimoAnno', delay: 0 });
  const dispatch = useDispatch();
  const comunidad = useSelector((store) => store.comunidad);

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getBalanceCurvas(comunidad.selected.id, fechaDesde.toISOString(), fechaHasta.toISOString())), 'area-balanceCurvas');
      trackPromise(dispatch(getBalanceEnergia(comunidad.selected.id, fechaMesDesde.toISOString(), fechaMesHasta.toISOString())), 'area-balanceEnergia');
      trackPromise(dispatch(getBalanceUltimoAnno(comunidad.selected.id, inicioUltimoAnno.toISOString(), finUltimoAnno.toISOString())), 'area-balanceUltimoAnno');
    };

    onRender();
  }, [comunidad.selected]);

  const ButtonDatePicker = forwardRef(({ value, onClick }, ref) => (
    <Button onClick={onClick} ref={ref} variant='outline-secondary' size='sm'>
      {value}
    </Button>
  ));

  function dayInit(dia) {
    let fecha = toDate(dia);
    fecha.setHours(0);
    fecha.setMinutes(0);
    fecha.setSeconds(0);
    return fecha;
  }

  function handleTipoGx(val) {
    setTipoGx(val);
  }

  function cambiaFechaDesde(date) {
    let fechaPlusSemana = add(date, { seconds: RANGO_SEMANA });
    setFechaDesde(date);
    setFechaHasta(fechaPlusSemana);
    trackPromise(dispatch(getBalanceCurvas(comunidad.selected.id, date.toISOString(), fechaPlusSemana.toISOString())), 'area-balanceCurvas');
  }

  function cambiarFechaMesDesde(date) {
    let fechaPlusMes = sub(add(date, { months: RANGO_MES }), { seconds: 1 });
    let rinicio = sub(fechaPlusMes, { days: 5 });
    let rfin = add(fechaPlusMes, { days: 5 });
    setfechaMesDesde(date);
    setFechaMesHasta(fechaPlusMes);
    setFechaRangoInicio(rinicio);
    setFechaRangoFin(rfin);
    trackPromise(dispatch(getBalanceEnergia(comunidad.selected.id, date.toISOString(), fechaPlusMes.toISOString())), 'area-balanceEnergia');
  }

  function cambiarFechaMesHasta(date) {
    setFechaMesHasta(date);
    trackPromise(dispatch(getBalanceEnergia(comunidad.selected.id, fechaMesDesde.toISOString(), date.toISOString())), 'area-balanceEnergia');
  }

  /** En esta oportunidad se programa en "duro" que si la comunidad no es igual a
   * Chorrillos (comunidad_id = 17) entonces la funcionalidad no está habilitada.
   * De esta forma si se accede a través de una URL a la funcionalidad y no a
   * través del Menú, la aplicación responderá el error adecuadamente. */
  return comunidad.isSelected ? (
    comunidad.selected.id == 17 ? (
      <>
        <Container fluid className='PanelBalanceEnergia d-flex flex-wrap p-0 shadow-sm'>
          <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
            <Container fluid className='ms-4'>
              <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
                <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                  Compra y venta de energía
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Balance de energía</Breadcrumb.Item>
              </Breadcrumb>
            </Container>
          </Navbar>
        </Container>

        <Container fluid className='PanelBalanceEnergia'>
          <Row className='p-2'>
            <Col xs={12} lg={8} xl={8} xxl={6} className='p-2'>
              <Card className='shadow-sm'>
                <Tabs id='tabs-balance-energia' defaultActiveKey='curvas' className='misColores m-1 mb-0' fill>
                  <Tab eventKey='curvas' title='Curvas carga potencia' tabClassName='h5 fw-bold'>
                    <Card.Body className='d-flex flex-wrap m-1 my-0 border border-top-0 p-0'>
                      <Navbar className='flex-fill'>
                        <p className='m-0 me-2 d-none d-lg-inline p-0 ms-3 text-nowrap'>Rango semanal:</p>
                        <DatePicker
                          dateFormat='dd MMM, yyyy'
                          locale='es'
                          calendarStartDay={1}
                          showPopperArrow={false}
                          selected={fechaDesde}
                          onChange={cambiaFechaDesde}
                          customInput={<ButtonDatePicker />}
                        />
                        <p className='d-none d-lg-inline m-0 p-0 ms-2 me-2'>a:</p>
                        <DatePicker disabled dateFormat='dd MMM, yyyy' locale='es' selected={fechaHasta} customInput={<ButtonDatePicker />} />
                      </Navbar>
                      <Navbar className='flex-fill justify-content-end'>
                        <p className='d-none d-lg-inline m-0 p-0 ms-3 me-2'>Tipo:</p>
                        <ButtonGroup className='me-2'>
                          {tiposGx.map((tipo, idx) => (
                            <ToggleButton
                              key={idx}
                              variant='outline-secondary'
                              size='sm'
                              id={`tipo-${idx}`}
                              type='radio'
                              name='radio-tipoGx'
                              value={tipo.value}
                              checked={tipoGx === tipo.value}
                              onChange={(e) => handleTipoGx(e.currentTarget.value)}
                              className='anchoRadioButton'
                            >
                              {tipo.name}
                            </ToggleButton>
                          ))}
                        </ButtonGroup>
                      </Navbar>
                    </Card.Body>
                    <LoaderSpinner isLoading={balanceCurvasInProgress}>
                      <Card.Body className='m-1 mt-0 p-0 py-2 border border-top-0 d-flex justify-content-center'>
                        <Container style={{ minHeight: '20rem' }} className='m-0 p-0'>
                          {tipoGx === 'stack' ? <PlotStackBalanceEnergia className='m-3 p-0' /> : <PlotLineBalanceEnergia className='m-3 p-0' />}
                        </Container>
                      </Card.Body>
                    </LoaderSpinner>
                  </Tab>
                  <Tab eventKey='balance' title='Balance energía' tabClassName='h5 fw-bold'>
                    <Card.Body className='d-flex flex-wrap m-1 my-0 border border-top-0 p-0'>
                      <Navbar className='flex-fill'>
                        <p className='m-0 me-2 d-none d-lg-inline p-0 ms-3 text-nowrap'>Rango mensual:</p>
                        <DatePicker
                          dateFormat='dd MMM, yyyy'
                          locale='es'
                          calendarStartDay={1}
                          showPopperArrow={false}
                          selected={fechaMesDesde}
                          onChange={cambiarFechaMesDesde}
                          customInput={<ButtonDatePicker />}
                        />
                        <p className='d-none d-lg-inline m-0 p-0 ms-2 me-2'>a:</p>
                        <DatePicker
                          dateFormat='dd MMM, yyyy'
                          locale='es'
                          selected={fechaMesHasta}
                          minDate={fechaRangoInicio}
                          maxDate={fechaRangoFin}
                          onChange={cambiarFechaMesHasta}
                          customInput={<ButtonDatePicker />}
                        />
                      </Navbar>
                    </Card.Body>
                    <LoaderSpinner isLoading={balanceEnergiaInProgress}>
                      <Card.Body className='m-1 my-0 border border-top-0'>
                        <Card.Text className='fs-5 m-0 p-0'>
                          Período {format(fechaMesDesde, 'dd-MMM-yyyy', { locale: es })} al {format(fechaMesHasta, 'dd-MMM-yyyy', { locale: es })}
                        </Card.Text>
                        <Table borderless responsive size='sm' className='fs-6 m-0 p-0'>
                          <tbody>
                            <tr className='fw-bold text-center bgc-gris border-bottom border-white'>
                              <td className='ancho-25'></td>
                              <td className='ancho-40'>Unidad</td>
                              <td className='ancho-20'>[kWh]</td>
                              <td>FP</td>
                            </tr>
                            {energias.map((generador, index) => (
                              <tr key={index}>
                                {index == 0 ? (
                                  <td rowSpan={`${energias.length + 1}`} className='fw-bold bgc-gris border-bottom border-white align-middle'>
                                    Inyección de energía
                                  </td>
                                ) : null}
                                <td>{generador.generadorNombre}</td>
                                <td className='text-end'>{pintaNumero(generador.energiaConsumida)}</td>
                                <td className={generador.factorPlanta > 0.0 ? 'text-end' : 'text-center'}>{generador.factorPlanta > 0.0 ? pintaNumeroDecimales(generador.factorPlanta, 4) : '-'}</td>
                              </tr>
                            ))}
                            <tr className='border-top border-bottom'>
                              <td className='fw-bold'>Total</td>
                              <td className='text-end'>{pintaNumero(totalEnergia)}</td>
                              <td className='text-center'>-</td>
                            </tr>
                            <tr>
                              <td className='fw-bold bgc-gris border-white'>Venta de energía</td>
                              <td>{comunidad.selected.nombre}</td>
                              <td className='text-end'>{pintaNumero(totalConsumo)}</td>
                              <td className='text-center'>-</td>
                            </tr>
                            <tr className='fw-bold bgc-gris border-top border-white'>
                              <td colSpan='2'>Pérdidas de energía</td>
                              <td className='text-end'>{pintaNumero(totalPerdida)}</td>
                              <td className='text-end'>({pintaNumero(totalPorcentajePerdida)}%)</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </LoaderSpinner>
                    <LoaderSpinner isLoading={balanceUltimoAnnoInProgress}>
                      <Card.Body className='m-1 mt-0 border border-top-0'>
                        <Card.Text className='fs-5 m-0 p-0'>
                          Valores acumulados, últimos 12 meses, de {format(inicioUltimoAnno, 'MMM-yyyy', { locale: es })} a {format(finUltimoAnno, 'MMM-yyyy', { locale: es })}
                        </Card.Text>
                        <Table borderless responsive size='sm' className='fs-6 m-0 p-0'>
                          <tbody>
                            <tr className='fw-bold text-center bgc-gris border-bottom border-white'>
                              <td className='ancho-25'></td>
                              <td className='ancho-40'>Unidad</td>
                              <td className='ancho-20'>[kWh]</td>
                              <td>FP</td>
                            </tr>
                            {ultimoAnno.energias.map((generador, index) => (
                              <tr key={index}>
                                {index == 0 ? (
                                  <td rowSpan={`${ultimoAnno.energias.length + 1}`} className='fw-bold bgc-gris border-bottom border-white align-middle'>
                                    Inyección de energía
                                  </td>
                                ) : null}
                                <td>{generador.generadorNombre}</td>
                                <td className='text-end'>{pintaNumero(generador.energiaConsumida)}</td>
                                <td className={generador.factorPlanta > 0.0 ? 'text-end' : 'text-center'}>{generador.factorPlanta > 0.0 ? pintaNumeroDecimales(generador.factorPlanta, 4) : '-'}</td>
                              </tr>
                            ))}
                            <tr className='border-top border-bottom'>
                              <td className='fw-bold'>Total</td>
                              <td className='text-end'>{pintaNumero(ultimoAnno.totalEnergia)}</td>
                              <td className='text-center'>-</td>
                            </tr>
                            <tr>
                              <td className='fw-bold bgc-gris border-white'>Venta de energía</td>
                              <td>{comunidad.selected.nombre}</td>
                              <td className='text-end'>{pintaNumero(ultimoAnno.totalConsumo)}</td>
                              <td className='text-center'>-</td>
                            </tr>
                            <tr className='fw-bold bgc-gris border-top border-white'>
                              <td colSpan='2'>Pérdidas de energía</td>
                              <td className='text-end'>{pintaNumero(ultimoAnno.totalPerdida)}</td>
                              <td className='text-center'>({pintaNumero(ultimoAnno.totalPorcentajePerdida)}%)</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </LoaderSpinner>
                  </Tab>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    ) : (
      NotFoundPanel()
    )
  ) : (
    NotFoundPanel()
  );
}

export default PanelBalanceEnergia;
