import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useSelector } from 'react-redux';
import { pintaFecha, pintaNumero, pintaPesos, pintaEstado } from '../../libreria/utilidades';
import './Factura.css';

function Factura() {
  const factura = useSelector((store) => store.factura);
  const comunidad = useSelector((store) => store.comunidad);
  const comunidadIconos = useSelector((store) => store.comunidadIconos);

  return (
    <Card className='Factura shadow-sm'>
      <Card.Body>
        <Table size='sm' responsive borderless className='h6 mb-2'>
          <tbody>
            <tr>
              <th className='text-center warning-number'>FACTURA ELECTRÓNICA</th>
              <th className='text-center'>{comunidad.selected.emisor_razon_social}</th>
              <td rowSpan='3' className='text-center align-middle'>
                <Image src={comunidadIconos.emisorLogo} className='image-small d-none d-sm-inline' fluid></Image>
              </td>
            </tr>
            <tr>
              <th className='text-center warning-number'>Nº: {pintaNumero(factura.folio)}</th>
              <th className='text-center'>{comunidad.selected.emisor_giro}</th>
            </tr>
            <tr>
              <td className='text-center warning-number text-small'>S.I.I. SANTIAGO CENTRO</td>
              <th className='text-center '>{`${comunidad.selected.emisor_direccion}, ${comunidad.selected.emisor_comuna}`}</th>
            </tr>
          </tbody>
        </Table>
        <Row className='border bg-light m-0 mb-1 p-1'>
          <Col xs={12} md={7} className='p-0'>
            <Table size='sm' responsive borderless className='text-small m-0 factura-datos-padding'>
              <tbody>
                <tr>
                  <th className='text-end column-ancho'>Código servicio:</th>
                  <td>{pintaNumero(factura.cabecera.codigo_servicio)}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Periodo:</th>
                  <td>{factura.cabecera.periodo}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Cliente:</th>
                  <td>{factura.cabecera.nombre_cliente}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>RUT:</th>
                  <td>{factura.cabecera.rut_cliente}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Direccion:</th>
                  <td>{factura.cabecera.direccion_cliente}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Ciudad:</th>
                  <td>{factura.cabecera.ciudad_cliente}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Comuna:</th>
                  <td>{factura.cabecera.comuna_cliente}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Medidor:</th>
                  <td>{factura.cabecera.medidor_id}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs={12} md={5} className='p-0'>
            <Table size='sm' responsive borderless className='text-small m-0 factura-datos-padding'>
              <tbody>
                <tr>
                  <th className='text-end column-ancho'>Fecha Emisión:</th>
                  <td>{pintaFecha(factura.cabecera.fecha_emision)}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Fecha Vencimiento:</th>
                  <td>{pintaFecha(factura.cabecera.fecha_vencimiento)}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Fecha Inicio Lectura:</th>
                  <td>{pintaFecha(factura.cabecera.fecha_inicio_lectura)}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Fecha Fin Lectura:</th>
                  <td>{pintaFecha(factura.cabecera.fecha_fin_lectura)}</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Lectura anterior:</th>
                  <td>{pintaNumero(factura.cabecera.lectura_anterior)} [kWh]</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Lectura periodo:</th>
                  <td>{pintaNumero(factura.cabecera.lectura_actual)} [kWh]</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Consumo periodo:</th>
                  <td>{pintaNumero(factura.cabecera.consumo)} [kWh]</td>
                </tr>
                <tr>
                  <th className='text-end column-ancho'>Estado:</th>
                  <td>{pintaEstado(factura.cabecera.estado)}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Table size='sm' responsive className='text-small mb-1'>
          <thead>
            <tr className='text-muted'>
              <th className='text-center'>Descripcion del cargo</th>
              <th className='text-center'>Monto neto</th>
            </tr>
          </thead>
          <tbody>
            {factura.cargos.map((cargo) => (
              <tr key={cargo.nombre_cargo}>
                <td>{cargo.nombre_cargo}</td>
                <td className='text-end'>{pintaPesos(parseInt(cargo.monto_neto))}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Table size='sm' responsive borderless className='text-small mb-0'>
          <tbody className='text-end'>
            <tr>
              <td rowSpan='6' className='text-center align-bottom'>
                <a style={{ display: 'table-cell' }} href={factura.cabecera.link_pdf} target='_blank' rel='noopener noreferrer'>
                  <PictureAsPdfIcon className='m-1' />
                  Ver factura
                </a>
              </td>
              <th>Monto exento:</th>
              <td className='column-totales'>{pintaPesos(factura.cabecera.monto_exento)}</td>
            </tr>
            <tr>
              <th>Monto afecto:</th>
              <td className='column-totales'>{pintaPesos(factura.cabecera.monto_afecto)}</td>
            </tr>
            <tr>
              <th>Monto neto:</th>
              <td className='column-totales'>{pintaPesos(factura.cabecera.monto_neto)}</td>
            </tr>
            <tr>
              <th>I.V.A.:</th>
              <td className='column-totales'>{pintaPesos(factura.cabecera.total_iva)}</td>
            </tr>
            <tr>
              <th>Saldo anterior:</th>
              <td className='column-totales'>{pintaPesos(factura.cabecera.saldo_mes_anterior)}</td>
            </tr>
            <tr className='warning-number'>
              <th>Total a pagar:</th>
              <th className='column-totales text-nowrap'>{pintaPesos(factura.cabecera.total)}</th>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default Factura;
