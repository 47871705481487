import { Storage } from 'aws-amplify';

export const USER_IMAGES_FOLDER = 'user_images';

export async function s3getPublicURL(filepath) {
  if (filepath) {
    let result = null;
    try {
      result = await Storage.get(filepath, { download: true });
      if (result) {
        //console.log(result);
        const url = URL.createObjectURL(result.Body);
        return url;
      } else return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  } else return null;
}

export async function s3listPublicURL(filepath) {
  if (filepath) {
    let result = null;
    try {
      result = await Storage.list(filepath);
      if (result) {
        //const url = URL.createObjectURL(result.Body);
        return result;
      } else return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  } else return null;
}

export async function s3getURL(filepath) {
  if (filepath) {
    let result = null;
    try {
      result = await Storage.vault.get(filepath, { download: true });
      if (result) {
        const url = URL.createObjectURL(result.Body);
        return url;
      } else return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  } else return null;
}

export async function s3upload(filepath, file) {
  let stored = null;
  try {
    stored = await Storage.vault.put(filepath, file, { contentType: file.type });
  } catch (error) {
    console.log(error);
  }
  return stored ? stored.key : null;
}

export async function s3remove(filepath) {
  try {
    await Storage.vault.remove(filepath);
  } catch (error) {
    console.log(error);
  }
}
