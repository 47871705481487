import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu as menu } from './Menu';
import { useEPlatContext } from '../libreria/EPlatContext';
import './EPlatMenu.css';

import { useSelector } from 'react-redux';

function EPlatMenu() {
  const comunidad = useSelector((store) => store.comunidad);

  const { userSession } = useEPlatContext();
  const { attributes } = userSession;
  const [vectorLectura, setVectorLectura] = useState([false, false, false, false, false]);
  const [vectorEscritura, setVectorEscritura] = useState([false, false, false, false, false]);

  function handleMenuClick(event) {
    const key = `ePlatKEY@${attributes.email}vector-menu`;
    const vector = [...vectorEscritura];
    vector[event.target.id] = !vectorEscritura[event.target.id];
    localStorage.setItem(key, JSON.stringify(vector));
    setVectorEscritura(vector);
  }

  useEffect(() => {
    const onRender = () => {
      const key = `ePlatKEY@${attributes.email}vector-menu`;
      const vector = JSON.parse(localStorage.getItem(key));
      if (vector) {
        setVectorLectura(vector);
        setVectorEscritura([...vector]);
      }
    };

    onRender();
  }, []);

  /** Hemos puesto un código en el cual se evalúa si la comunidad es
   * Chorrillos (comunidad_id = 17), en cuyo caso se dibuja el menú
   * de "balance", en caso contrario el menú se dibuja tal cual,
   * acorde a lo que viene del arreglo de Menuy Submenu.
   * Esta es una codificación en duro que reemplaza el perfilamiento
   * que se requiere por Comunidad. */
  return (
    <div className='EPlatMenu p-2 mt-1'>
      <ul className='list-unstyled m-0 ps-0'>
        {menu.map((menuItem, index) =>
          menuItem.separador ? (
            <li key={index} className='border-top my-2'></li>
          ) : (
            <li key={index} className='mb-1'>
              <span
                className='btn btn-toggle collapsed text-nowrap'
                id={index}
                data-bs-toggle='collapse'
                onClick={handleMenuClick}
                data-bs-target={`#id-${menuItem.id}`}
                aria-expanded={vectorLectura[index]}
              >
                {menuItem.texto}
              </span>
              <div className={`collapse${vectorLectura[index] ? ' show' : ''}`} id={`id-${menuItem.id}`}>
                <ul className='btn-toggle-nav list-unstyled fw-normal pb-1 small'>
                  {menuItem.submenu.map((submenuItem) =>
                    submenuItem.id === 'balance' ? (
                      comunidad.isSelected ? (
                        comunidad.selected.id == 17 ? (
                          <li key={submenuItem.id} className='mb-1'>
                            {/* link-dark text-nowrap ---- {({ isActive }) => (isActive ? 'link-dark' : 'green')} */}
                            <NavLink to={submenuItem.link} className={({ isActive }) => `text-nowrap${isActive ? ' color-active' : ''}`}>
                              {submenuItem.icono()}
                              {submenuItem.texto}
                            </NavLink>
                          </li>
                        ) : null
                      ) : null
                    ) : (
                      <li key={submenuItem.id} className='mb-1'>
                        {/* link-dark text-nowrap ---- {({ isActive }) => (isActive ? 'link-dark' : 'green')} */}
                        <NavLink to={submenuItem.link} className={({ isActive }) => `text-nowrap${isActive ? ' color-active' : ''}`}>
                          {submenuItem.icono()}
                          {submenuItem.texto}
                        </NavLink>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
}

export default EPlatMenu;
