import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { rangos } from '../../componentes/RangoPeriodos';
import moment from 'moment';

defaults.font.family = 'Montserrat';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

function GraficoLecturasMedidor(props) {
  const { lecturas } = useSelector((store) => store.medidor);
  function getPeriodo(fecha) {
    let ano = fecha.year();
    let mes = fecha.month() + 1;
    return ano.toString() + '-' + (mes < 10 ? `0${mes.toString()}` : mes.toString());
  }
  /** Se construirán las series de datos sobre la base de el rango de periodos seleccionado. */
  let serieConsumo = [];
  let serieLectura = [];
  let labels = [];
  let colorConsumo = [];
  let colorLectura = [];
  let rangoSelected = props.rango;
  let mesIndex = moment(rangos[rangoSelected].inicio);
  let mesFin = moment(rangos[rangoSelected].fin);
  let iteraciones = mesFin.diff(mesIndex, 'months') + 1;
  iteraciones = iteraciones > 13 ? 13 : iteraciones;
  for (let i = 0; i < iteraciones; i++) {
    let periodo = getPeriodo(mesIndex);
    let periodoLectura = lecturas.find((item) => item.periodo === periodo);
    let consumo = periodoLectura ? periodoLectura.consumo : 0;
    let lectura = periodoLectura ? periodoLectura.lectura_actual : 0;
    serieConsumo.push(consumo);
    serieLectura.push(lectura);
    colorConsumo.push('#7c94bc');
    colorLectura.push('#f0cc69');
    labels.push(periodo);
    mesIndex.add(1, 'months');
  }
  /** Ahora se realiza la configuración del grafico. */
  const options = {
    responsive: true,
    animation: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Períodos',
          padding: 1,
          font: { size: 10 }
        },
        ticks: {
          padding: 1,
          maxRotation: 0,
          font: {
            size: 8,
            lineHeight: 1
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Consumo [kWh]',
          padding: 1,
          font: { size: 9 }
        },
        ticks: {
          padding: 2,
          font: { size: 10 }
        }
      },
      y1: {
        title: {
          display: true,
          text: 'Lectura [kWh]',
          padding: 1,
          font: { size: 9 }
        },
        ticks: {
          padding: 2,
          font: { size: 10 }
        },
        position: 'right',
        grid: { drawOnChartArea: false }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'left',
        labels: {
          boxWidth: 20,
          padding: 15,
          font: { size: 10 },
          color: '#000000'
        },
        align: 'center'
      },
      title: {
        display: true,
        text: `Consumo energía activa: ${rangos[rangoSelected].opcion}`
      }
    },
    elements: {
      point: {
        radius: 4
      },
      line: {
        borderWidth: 1,
        borderColor: '#6d757c'
      }
    }
  };
  /** Ahora se carga etiquetas, datos y colores en el gráfico. */
  const datos = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Consumo',
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        data: serieConsumo,
        backgroundColor: colorConsumo,
        yAxisID: 'y'
      },
      {
        type: 'bar',
        label: 'Lectura',
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        data: serieLectura,
        backgroundColor: colorLectura,
        yAxisID: 'y1'
      }
    ]
  };

  return <Chart {...props} options={options} data={datos} />;
}

export default GraficoLecturasMedidor;
