import React, { useRef, useEffect, useState } from 'react';
import { Image, Button, Modal, ModalTitle, Form } from 'react-bootstrap';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import EditIcon from '@mui/icons-material/Edit';
import CropIcon from '@mui/icons-material/Crop';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import './CambiarImagenModal.css';

function CambiarImagenModal(props) {
  const imagen = props.imagen;
  const file = useRef(null);
  // Estados de la ventana de seleccion de imagen.
  const SIN_IMAGEN = 'SIN_IMAGEN';
  const TIENE_IMAGEN = 'TIENE_IMAGEN';
  const EDITANDO_IMAGEN = 'EDITANDO_IMAGEN';
  const NUEVA_IMAGEN = 'NUEVA_IMAGEN';
  // Hooks para manejo de los estados.
  const [estado, setEstado] = useState(SIN_IMAGEN);
  const [avatar, setAvatar] = useState(null);
  // Gestión de la funcionalidad crop.
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imagenCandidata, setImagenCandidata] = useState(null);

  useEffect(() => {
    const onRender = () => {
      setEstado(imagen ? TIENE_IMAGEN : SIN_IMAGEN);
      setAvatar(imagen);
    };
    onRender();
  }, []);

  function getAvatar() {
    return estado === SIN_IMAGEN ? `${process.env.PUBLIC_URL}/images/avatar-placeholder.png` : avatar;
  }

  function cropComplete(croppedArea, croppedAreaPixels) {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  function cleanCropParameters() {
    setImagenCandidata(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
  }

  function handleFileChange(event) {
    if (event.target.files.length > 0) {
      file.current = event.target.files[0];
      setImagenCandidata(URL.createObjectURL(file.current));
      setEstado(EDITANDO_IMAGEN);
    }
  }

  function handleDelete(event) {
    props.onHide();
    props.onSelect(null);
    setAvatar(null);
    setEstado(SIN_IMAGEN);
    cleanCropParameters();
  }

  function handleEditar() {
    setEstado(EDITANDO_IMAGEN);
  }

  function handleClose(event) {
    props.onHide();
    setAvatar(imagen);
    setEstado(imagen ? TIENE_IMAGEN : SIN_IMAGEN);
    cleanCropParameters();
  }

  function handleChangeImagen(event) {
    props.onHide();
    props.onSelect(avatar);
    // setAvatar() no es necesario, dado que ya está seteado desde la selección.
    setEstado(TIENE_IMAGEN);
    cleanCropParameters();
  }

  async function handleCropReady(event) {
    try {
      const croppedImage = await getCroppedImg(imagenCandidata, croppedAreaPixels);
      setAvatar(croppedImage);
      setEstado(NUEVA_IMAGEN);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Modal {...props} backdrop='static' onHide={handleClose} fullscreen='sm-down' animation={false} centered contentClassName='CambiarImagenModal text-secondary bg-dark'>
      <Modal.Header closeButton={true} closeVariant='white' className='fs-5 text-success border-secondary py-2'>
        <ModalTitle>Cambiar imagen</ModalTitle>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column align-items-center p-2'>
        {estado === EDITANDO_IMAGEN ? (
          <div className='align-self-stretch flex-fill flex-grow-1 d-flex align-items-center crop-container mb-2'>
            <Cropper image={imagenCandidata} crop={crop} zoom={zoom} rotation={0} aspect={1} onZoomChange={setZoom} onCropChange={setCrop} onCropComplete={cropComplete} />
          </div>
        ) : (
          <div className='flex-fill flex-grow-1 d-flex align-items-center crop-container mb-2'>
            <Image fluid src={getAvatar()} className='size-imagen border border-secondary border-4' roundedCircle />
          </div>
        )}
        <div className='align-self-stretch d-flex flex-nowrap align-items-center'>
          <Form.Control id='archivo' size='sm' className='fs-5 btn-outline-success border-secondary bg-dark text-success' onChange={handleFileChange} type='file' />
        </div>
      </Modal.Body>
      <Modal.Footer className='border-secondary p-2'>
        <Button size='sm' variant='outline-success' className='m-0 me-auto' disabled={estado !== NUEVA_IMAGEN} onClick={handleChangeImagen}>
          <UploadRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />
          Subir Imagen
        </Button>
        {estado === EDITANDO_IMAGEN ? (
          <Button size='sm' variant='outline-success' className='ms-2 m-0' onClick={handleCropReady}>
            <CropIcon sx={{ fontSize: 16 }} className='icono-centrado' />
            Listo
          </Button>
        ) : estado === NUEVA_IMAGEN ? (
          <Button size='sm' variant='outline-success' className='ms-2 m-0' onClick={handleEditar}>
            <EditIcon sx={{ fontSize: 16 }} className='icono-centrado' />
            Editar
          </Button>
        ) : estado === TIENE_IMAGEN ? (
          <Button size='sm' variant='outline-success' className='ms-2 m-0' onClick={handleDelete}>
            <DeleteRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado' />
            Borrar
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}

export default CambiarImagenModal;
