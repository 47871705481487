import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import './Map.css';

function Map(props) {
  const posicion = () => ({ lat: props.lat, lng: props.lng });

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBpBFcSUjyNd3KzJF6MdLBMayeU8E9ygGs'
  });

  return (
    <div {...props} className={`Map ${props.className}`}>
      {isLoaded ? (
        <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={posicion()} zoom={15} clickableIcons={false}>
          <Marker position={posicion()} />
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Map;
