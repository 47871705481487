import { createSlice } from '@reduxjs/toolkit';
import { s3getPublicURL } from '../libreria/S3Storage';
import { LocalCache } from '../libreria/LocalCache';

export const comunidadIconosSlice = createSlice({
  name: 'comunidadIconos',
  initialState: {
    emisorIcon: null,
    emisorLabel: null,
    emisorLogo: null,
    fotoPortada: null
  },
  reducers: {
    setIconos: (state, action) => {
      state.emisorIcon = action.payload.emisorIcon;
      state.emisorLabel = action.payload.emisorLabel;
      state.emisorLogo = action.payload.emisorLogo;
      state.fotoPortada = action.payload.fotoPortada;
    }
  }
});

export const { setIconos } = comunidadIconosSlice.actions;

// Action Creators
export const getIconos = (comunidadId, filepathIcon, filepathLabel, filepathLogo, filepathFotoPortada) => async (dispatch) => {
  let item = LocalCache.get(`KEY:iconos&comunidad=${comunidadId}`);
  if (item) {
    dispatch(setIconos(item));
  } else {
    try {
      const response = await getIconosFromS3(filepathIcon, filepathLabel, filepathLogo, filepathFotoPortada);
      dispatch(setIconos(response));
      LocalCache.put(`KEY:iconos&comunidad=${comunidadId}`, response);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getIconos()', error);
    }
  }
};

async function getIconosFromS3(filepathIcon, filepathLabel, filepathLogo, filepathFotoPortada) {
  let icon = await s3getPublicURL(filepathIcon);
  let label = await s3getPublicURL(filepathLabel);
  let logo = await s3getPublicURL(filepathLogo);
  let fotoPortada = await s3getPublicURL(filepathFotoPortada);
  return { emisorIcon: icon, emisorLabel: label, emisorLogo: logo, fotoPortada: fotoPortada };
}

export default comunidadIconosSlice.reducer;
