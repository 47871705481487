import React, { useEffect } from 'react';
import { Nav, Image, Container, Dropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useEPlatContext } from '../libreria/EPlatContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectComunidad } from '../redux/comunidadSlice';
import { getIconos } from '../redux/comunidadIconosSlice';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import ContactSupportRoundedIcon from '@mui/icons-material/ContactSupportRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { Auth } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';
import './EPlatHeader.css';

function EPlatHeader() {
  const navigate = useNavigate();
  const { userSession, userHasAuthenticated, setUserSession, setAvatar } = useEPlatContext();
  const { attributes } = userSession;

  const dispatch = useDispatch();

  /** A partir de acá usaremos la store */
  const comunidad = useSelector((store) => store.comunidad);
  const comunidadIconos = useSelector((store) => store.comunidadIconos);

  useEffect(() => {
    const onRender = async () => {
      if (comunidad.isSelected) {
        dispatch(
          getIconos(
            comunidad.selected.id,
            comunidad.selected.direccion.icon_emisor,
            comunidad.selected.direccion.label_emisor,
            comunidad.selected.direccion.logo_emisor,
            comunidad.selected.direccion.foto_portada
          )
        );
      }
    };

    onRender();
  }, [comunidad.selected]);

  async function handleSelectComunidad(eventKey, event) {
    const key = `ePlatKEY@${attributes.email}comunidad-selected`;
    let selected = comunidad.comunidades.find((item) => item.nombre === eventKey);
    if (selected.nombre !== comunidad.selected.nombre) {
      /** Debieramos agregar el uso de una ventana modal que asegure que el usuario desea
       * cambiar de Comunidad, dado el impacto de redibujar el sistema completo. */
      localStorage.setItem(key, eventKey);
      dispatch(selectComunidad(selected));
    }
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setUserSession(null);
    setAvatar(null);
    LocalCache.clear();
    navigate('/');
  }

  return (
    <Container fluid className='EPlatHeader bg-dark sticky-top shadow-sm p-1 d-flex align-items-center justify-content-between'>
      <NavLink to='/' className='p-0'>
        <Image src={`${process.env.PUBLIC_URL}/images/ePlat_logo.png`} fluid></Image>
      </NavLink>
      {process.env.REACT_APP_STAGE === 'prod' ? null : <p className='d-none d-md-inline text-primary m-0 p-0 ms-2 me-auto'>Versión pre-productiva</p>}
      <Nav as='ul' className='h5 flex-nowrap d-flex align-items-center'>
        <NavLink to='/' className='align-self-stretch border-end border-secondary ms-2 p-0 pe-2 d-flex align-items-center'>
          <Image src={comunidadIconos.emisorIcon ? comunidadIconos.emisorIcon : ''} className='me-1'></Image>
          <Image src={comunidadIconos.emisorLabel ? comunidadIconos.emisorLabel : ''} className='me-1 d-none d-lg-inline' fluid></Image>
        </NavLink>
        <Dropdown as='li' className='border-end border-secondary' onSelect={handleSelectComunidad}>
          <Dropdown.Toggle as='button' id='dropdown-basic' className='h5 bg-dark border-0 texto-cabecera m-0'>
            <ApartmentRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado' />
            <p className='d-none d-lg-inline m-0 ms-1 me-1'>
              Comunidad: <b>{comunidad.selected.nombre}</b>
            </p>
          </Dropdown.Toggle>
          <Dropdown.Menu variant='dark' align='end' renderOnMount='true'>
            <Dropdown.Header>Comunidades:</Dropdown.Header>
            {comunidad.comunidades.map(({ id, nombre }) => (
              <Dropdown.Item key={id} eventKey={nombre}>
                {nombre}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Nav.Item as='li' className='border-end border-secondary'>
          <Nav.Link href='#'>
            <ContactSupportRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado' />
            <p className='d-none d-lg-inline m-0 ms-1'>Contacto</p>
          </Nav.Link>
        </Nav.Item>
        <button type='button' className='border-end border-secondary texto-cabecera' onClick={handleLogout}>
          <DisabledByDefaultRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado' />
          <p className='d-none d-lg-inline m-0 ms-1'>Cerrar sesión</p>
        </button>
      </Nav>
    </Container>
  );
}

export default EPlatHeader;
