import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { pintaFecha } from '../../libreria/utilidades';
import './StepStatus.css';

function StepStatus(props) {
  function pintaEstado(estado) {
    return estado === 'EXECUTION_OK' ? 'EJECUCIÓN COMPLETADA' : 'NO EJECUTADO';
  }
  function fechaFormat(fecha) {
    return fecha ? pintaFecha(fecha) : 'Sin fecha registrada';
  }

  return (
    <>
      <Card className='StepStatus accordion shadow-sm' id={`id${props.name}`}>
        <div className='accordion-item'>
          <h2 className='accordion-header border-bottom' id={`id-head-${props.name}`}>
            <button
              className='accordion-button collapsed ps-2 cabecera'
              id={`id-button-${props.name}`}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={`#id-coll-${props.name}`}
              aria-expanded='false'
              aria-controls={`id-coll-${props.name}`}
            >
              <div className='h3 m-0 fw-bold circulo border me-2'>{props.step.stepId}</div>
              <div className='d-flex flex-column align-items-start'>
                <p className='h5 m-0 my-1 fw-bold'>{props.step.titulo}</p>
                {props.step.resultado() === 'EXECUTION_OK' ? <p className='h6 m-0 ejecutado'>Ejecutado correctamente</p> : <p className='h6 m-0 no-ejecutado'>No ejecutado</p>}
              </div>
            </button>
          </h2>
          <div id={`id-coll-${props.name}`} className='accordion-collapse collapse' aria-labelledby={`id-head-${props.name}`} data-bs-parent={`#id${props.name}`}>
            <Card.Body className='accordion-body p-2'>
              <Table size='sm' borderless className='text-small m-0'>
                <tbody>
                  <tr>
                    <td className='p-0'>Resultado de la ejecución:</td>
                    <td className='p-0'>{pintaEstado(props.step.resultado())}</td>
                  </tr>
                  <tr>
                    <td className='p-0'>Fecha de ejecución:</td>
                    <td className='p-0'>{fechaFormat(props.step.fecha())}</td>
                  </tr>
                  <tr>
                    <td className='p-0'>Cantidad registros:</td>
                    <td className='p-0'>{props.step.num_registros()}</td>
                  </tr>
                  <tr>
                    <td colSpan='2' className='p-0'>
                      {props.step.resultado() === 'EXECUTION_OK' && props.step.paginaDetalle ? (
                        <Link to={props.step.paginaDetalle ? props.step.paginaDetalle : '#'} className='link-primary text-nowrap'>
                          Ver detalles<button className='ms-1 button-link'>...</button>
                        </Link>
                      ) : (
                        <i className='fst-normal text-muted'>Sin detalles</i>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </div>
          <Card.Footer className='h6 m-0'>{props.step.descripcion}</Card.Footer>
        </div>
      </Card>
    </>
  );
}

export default StepStatus;
