import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, defaults } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { rangos } from '../../componentes/RangoPeriodos';
import moment from 'moment';

defaults.font.family = 'Montserrat';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function GraficoVentaPagos(props) {
  const ventaPagos = useSelector((store) => store.ventaPagos);
  function getPeriodo(fecha) {
    let ano = fecha.year();
    let mes = fecha.month() + 1;
    return ano.toString() + '-' + (mes < 10 ? `0${mes.toString()}` : mes.toString());
  }
  /** Se construirán las series de datos sobre la base de el rango de periodos seleccionado. */
  let serieTotBoletas = [];
  let serieTotPagos = [];
  let labels = [];
  let colTotBoletas = [];
  let colTotPagos = [];
  let rangoSelected = props.rango;
  let mesIndex = moment(rangos[rangoSelected].inicio);
  let mesFin = moment(rangos[rangoSelected].fin);
  let iteraciones = mesFin.diff(mesIndex, 'months') + 1;
  iteraciones = iteraciones > 13 ? 13 : iteraciones;
  for (let i = 0; i < iteraciones; i++) {
    let periodo = getPeriodo(mesIndex);
    let periodoTot = ventaPagos.rows.find((item) => item.periodo === periodo);
    let totalBoletas = periodoTot ? periodoTot.total_dtes / 1000 : 0;
    let totalPagos = periodoTot ? periodoTot.total_pagos / 1000 : 0;
    serieTotBoletas.push(totalBoletas);
    colTotBoletas.push('#00b0f0');
    serieTotPagos.push(totalPagos);
    colTotPagos.push('#ffc000');
    labels.push(periodo);
    mesIndex.add(1, 'months');
  }
  /** Ahora se realiza la configuración del grafico. */
  const options = {
    responsive: true,
    animation: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Períodos',
          padding: 1,
          font: { size: 10 }
        },
        ticks: {
          padding: 1,
          maxRotation: 0,
          font: {
            size: 8,
            lineHeight: 1
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Monto $M',
          padding: 1,
          font: { size: 9 }
        },
        ticks: {
          padding: 2,
          font: { size: 10 }
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          boxWidth: 20,
          padding: 15,
          font: { size: 10 },
          color: '#000000'
        },
        align: 'center'
      },
      title: {
        display: true,
        text: `Totales documentos y pagos: ${rangos[rangoSelected].opcion}`
      }
    }
  };
  /** Ahora se carga etiquetas, datos y colores en el gráfico. */
  const datos = {
    labels,
    datasets: [
      {
        label: 'DTEs $M',
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        data: serieTotBoletas,
        backgroundColor: colTotBoletas
      },
      {
        label: 'Pagos $M',
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        data: serieTotPagos,
        backgroundColor: colTotPagos
      }
    ]
  };

  return <Bar {...props} options={options} data={datos} />;
}

export default GraficoVentaPagos;
