import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const boletaSlice = createSlice({
  name: 'boleta',
  initialState: {
    folio: 0,
    cabecera: null,
    cargos: []
  },
  reducers: {
    setBoleta: (state, action) => {
      state.folio = action.payload.folio;
      state.cabecera = action.payload.cabecera;
      state.cargos = action.payload.cargos;
    }
  }
});

export const { setBoleta } = boletaSlice.actions;

// Action Creators
export const getBoleta = (comunidadId, folio) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:boleta&comunidad=${comunidadId}&folio=${folio}`);
  if (item) {
    dispatch(setBoleta(item));
  } else {
    try {
      const response = await API.get('ePlat', `/get-boleta?comunidad=${comunidadId}&folio=${folio}`);
      let boleta = { folio: folio, cabecera: response.boleta, cargos: response.cargos };
      dispatch(setBoleta(boleta));
      LocalCache.put(`KEY:boleta&comunidad=${comunidadId}&folio=${folio}`, boleta);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getBoleta()', error);
    }
  }
};

export default boletaSlice.reducer;
