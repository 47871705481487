import React from 'react';
import Card from 'react-bootstrap/Card';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, defaults } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import moment from 'moment';

defaults.font.family = 'Montserrat';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

function GraficoConsumo() {
  const { data } = useSelector((store) => store.consumoHistorico);
  /** Se actualizan los nombre de meses en el objeto moment. */
  moment.updateLocale('es', {
    monthsShort: ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']
  });
  /** El código a continuacion crea los arreglos labels[], colores[] y
   * datosConsumo[] para pintar el Grafico de Consumo de los últimos 13 meses. */
  let labels = [],
    colores = [],
    datosConsumo = [];
  let indexPivote = 0,
    mesPivote = null;
  for (let i = 0; i < 13; i++) {
    let consumo = i < data.length ? data[i] : null;
    let isPivote = false;
    if (consumo) {
      if (!mesPivote) {
        isPivote = true;
        indexPivote = i;
        mesPivote = moment(consumo.fecha_fin_lectura);
      }
    }
    let labelMes = isPivote
      ? mesPivote.format('MMM')
      : mesPivote
      ? mesPivote
          .clone()
          .subtract(i - indexPivote, 'months')
          .format('MMM')
      : '';
    labels.unshift(labelMes);
    colores.unshift(isPivote ? '#2B66C9' : '#00B0F0');
    datosConsumo.unshift(consumo ? consumo.consumo_periodo : 0);
  }
  /** Ahora se realiza la configuración del grafico. */
  const options = {
    responsive: true,
    animation: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Meses',
          padding: 1,
          font: { size: 10 }
        },
        ticks: {
          padding: 1,
          maxRotation: 0,
          font: {
            size: 8,
            lineHeight: 1
          }
        }
      },
      y: {
        title: {
          display: true,
          text: '[kWh]',
          padding: 1,
          font: { size: 9 }
        },
        ticks: {
          padding: 2,
          font: { size: 10 }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Consumo [kWh] últimos 13 meses'
      }
    }
  };
  /** Ahora se carga etiquetas, datos y colores en el gráfico. */
  const datos = {
    labels,
    datasets: [
      {
        label: '[kWh]',
        barPercentage: 0.95,
        categoryPercentage: 0.9,
        data: datosConsumo,
        backgroundColor: colores
      }
    ]
  };

  return (
    <Card className='shadow-sm'>
      <Card.Body className='pt-0 pb-1'>
        <Bar options={options} data={datos} />
      </Card.Body>
    </Card>
  );
}

export default GraficoConsumo;
