import React, { useEffect } from 'react';
import { Container, Navbar, Breadcrumb, Table, Row, Col, Card } from 'react-bootstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectPeriodo } from '../../redux/periodoSlice';
import { getDocumentosCierre } from '../../redux/documentosCierreSlice';
import PeriodoDropDown from '../../componentes/PeriodoDropDown';
import { pintaPeriodo, pintaNumero, pintaPesos, pintaFecha } from '../../libreria/utilidades';
import WarningMessage from '../../componentes/WarningMessage';
import CardPlaceHolder from '../../componentes/CardPlaceHolder';
import moment from 'moment-timezone';
import './GeneracionDocumentos.css';

function GeneracionDocumentos() {
  const comunidad = useSelector((store) => store.comunidad);
  const periodo = useSelector((store) => store.periodo);
  const documentosCierre = useSelector((store) => store.documentosCierre);
  const { resumen, tabla, dimensiones, totalColumns, totalRows } = documentosCierre;
  const { promiseInProgress: documentosCierreInProgress } = usePromiseTracker({ area: 'area_documentos_cierre', delay: 0 });
  const dispatch = useDispatch();

  function handlePeriodoClick(data) {
    dispatch(selectPeriodo(data));
  }

  const formatPeriodo = (p) => p.anno.toString() + '-' + (p.mes < 10 ? `0${p.mes.toString()}` : p.mes.toString());

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getDocumentosCierre(comunidad.selected.id, formatPeriodo(periodo))), 'area_documentos_cierre');
    };
    onRender();
  }, [comunidad.selected, periodo]);

  const getMonto = (fila, dimension) => fila.find((item) => item.estado === dimension).monto;
  const getCantidad = (fila, dimension) => fila.find((item) => item.estado === dimension).cantidad;

  return (
    <>
      <Container fluid className='GeneracionDocumentos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Compra y venta de energía
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/proceso-cierre' className='text-decoration-none'>
                  Proceso cierre mensual
                </Link>
              </li>
              <Breadcrumb.Item active>Generación de documentos</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <p className='m-0 me-2 d-none d-sm-inline text-nowrap'>Período (mes/año):</p>
          <PeriodoDropDown className='me-3' onSelect={handlePeriodoClick} value={pintaPeriodo(periodo)} />
        </Navbar>
      </Container>
      <Container fluid className='GeneracionDocumentos'>
        <Row className='p-2'>
          <Col xs={12} md={9} lg={8} xxl={5} className='p-2'>
            <LoaderSpinner isLoading={documentosCierreInProgress}>
              {resumen ? (
                <Card className='shadow-sm'>
                  <Card.Header className='h5 fw-bold text-center'>Resultado tarea: Generación de documentos</Card.Header>
                  <Card.Body>
                    <Card.Text className='fs-6 fw-bold m-1'>
                      Resultado de la ejecución: <i className='fst-normal fw-normal'>EJECUCIÓN COMPLETADA</i>
                    </Card.Text>
                    <Card.Text className='fs-6 fw-bold m-1'>
                      Período: <i className='fst-normal fw-normal'>{pintaPeriodo(periodo)}</i>
                    </Card.Text>
                    <Card.Text className='fs-6 fw-bold m-1'>
                      Fecha ejecución: <i className='fst-normal fw-normal'>{pintaFecha(moment(resumen.fechaEmision))}</i>
                    </Card.Text>
                    <Card.Text className='fs-6 fw-bold m-1'>
                      Total documentos emitidos: <i className='fst-normal fw-normal'>{resumen.cantidadValidas}</i>
                    </Card.Text>
                    <Card.Text className='fs-6 fw-bold m-1'>
                      Total documentos anulados: <i className='fst-normal fw-normal'>{resumen.cantidadAnuladas}</i>
                    </Card.Text>
                    <Table responsive bordered size='sm' className='fs-6 color-borde mb-1'>
                      <thead className='bg-light'>
                        <tr className='border-0 text-center'>
                          <td colSpan='3' rowSpan='2' className='bg-white border-0 text-start'>
                            Resumen documentos emitidos
                          </td>
                          {dimensiones.length > 0 ? (
                            <th colSpan={dimensiones.length} className='border-1 color-borde'>
                              Estado
                            </th>
                          ) : null}
                          <th rowSpan='3' className='border-1 color-borde'>
                            Total
                          </th>
                        </tr>
                        <tr className='text-center'>
                          {dimensiones.map((item, index) => (
                            <td className='fw-bold' key={index}>
                              {item}
                            </td>
                          ))}
                        </tr>
                        <tr className='text-center'>
                          <th>Tipo</th>
                          <th>F. emisión</th>
                          <th>F. vencim.</th>
                          {dimensiones.map((item, index) => (
                            <td key={index} className='m-0 p-0'>
                              <Table borderless className='m-0 p-0 color-borde'>
                                <tbody>
                                  <tr>
                                    <td className='p-1'>Cantidad</td>
                                    <td className='p-1'>Monto</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tabla.map((item, index) => (
                          <tr key={index} className='align-middle'>
                            <td className='text-nowrap'>{item.tipo_documento}</td>
                            <td className='text-nowrap'>{pintaFecha(moment(item.dia_emision))}</td>
                            <td className='text-nowrap'>{pintaFecha(moment(item.dia_vencimiento))}</td>
                            {dimensiones.map((dimension, index) => (
                              <td key={index} className='m-0 p-0'>
                                <Table borderless className='m-0 p-0 color-borde'>
                                  <tbody>
                                    <tr>
                                      <td className='p-1 text-end text-nowrap' style={{ width: '30%' }}>
                                        {pintaNumero(getCantidad(item.columnas, dimension))}
                                      </td>
                                      <td className='p-1 text-end text-nowrap'>{pintaPesos(getMonto(item.columnas, dimension))}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </td>
                            ))}
                            <td className='bg-light text-nowrap text-end'>{pintaPesos(totalColumns[index])}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className='bg-light'>
                        <tr>
                          <th colSpan='3' className='text-end'>
                            Total
                          </th>
                          {dimensiones.map((item, index) => (
                            <td key={index} className='m-0 p-0'>
                              <Table borderless className='m-0 p-0 color-borde'>
                                <tbody>
                                  <tr>
                                    <td className='p-1 text-end text-nowrap' style={{ width: '30%' }}>
                                      {pintaNumero(getCantidad(totalRows, item))}
                                    </td>
                                    <td className='p-1 text-end text-nowrap'>{pintaPesos(getMonto(totalRows, item))}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                          ))}
                          <th className='text-end text-nowrap'>{pintaPesos(resumen.totalValidas)}</th>
                        </tr>
                      </tfoot>
                    </Table>
                  </Card.Body>
                </Card>
              ) : documentosCierreInProgress ? (
                <CardPlaceHolder />
              ) : (
                <WarningMessage>No se ha encontrado reporte de generación de documentos para el período: {pintaPeriodo(periodo)}.</WarningMessage>
              )}
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default GeneracionDocumentos;
