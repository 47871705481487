import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const balanceCurvasSlice = createSlice({
  name: 'balanceCurvas',
  initialState: {
    curvas: []
  },
  reducers: {
    setBalanceCurvas: (state, action) => {
      state.curvas = action.payload;
    }
  }
});

export const { setBalanceCurvas } = balanceCurvasSlice.actions;

// Action Creators
export const getBalanceCurvas = (comunidadId, inicio, fin) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:balanceCurvas&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setBalanceCurvas(item));
  } else {
    try {
      const response = await API.get('ePlat', `/balance-curvas?comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
      dispatch(setBalanceCurvas(response));
      LocalCache.put(`KEY:balanceCurvas&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`, response);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getBalanceCurvas()', error);
    }
  }
};

export default balanceCurvasSlice.reducer;
