import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const documentosCodServicioSlice = createSlice({
  name: 'documentosCodServicio',
  initialState: {
    rangoId: 0,
    data: null,
    documentos: []
  },
  reducers: {
    setDocumentosCodServicio: (state, action) => {
      state.rangoId = action.payload.rangoId;
      state.data = action.payload.data;
      state.documentos = action.payload.documentos;
    }
  }
});

export const { setDocumentosCodServicio } = documentosCodServicioSlice.actions;

// Action Creators
export const getDocumentosCodServicio = (comunidadId, codigo, rango) => async (dispatch, getState) => {
  const inicio = rango.inicio;
  const fin = rango.fin;
  let item = LocalCache.get(`KEY:documentosCodServicio&comunidad=${comunidadId}&codigo=${codigo}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setDocumentosCodServicio(item));
  } else {
    try {
      const response = await API.get('ePlat', `/documentos-cod-servicio?comunidad=${comunidadId}&codigo=${codigo}&inicio=${inicio}&fin=${fin}`);
      let documentos = { rangoId: rango.id, data: response.data, documentos: response.documentos };
      dispatch(setDocumentosCodServicio(documentos));
      LocalCache.put(`KEY:documentosCodServicio&comunidad=${comunidadId}&codigo=${codigo}&inicio=${inicio}&fin=${fin}`, documentos);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getDocumentosCodServicio()', error);
    }
  }
};

export default documentosCodServicioSlice.reducer;
