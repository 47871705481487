import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const comunidadSlice = createSlice({
  name: 'comunidad',
  initialState: {
    isSelected: false,
    selected: {
      id: 0,
      nombre: '[Comunidad]',
      direccion: '[Sin direccion]',
      email: '[Sin email]',
      emisor_rut: '[Sin RUT]',
      emisor_razon_social: '[Sin razon social]',
      emisor_giro: '[Sin giro]',
      emisor_direccion: '[Sin direccion emisor]',
      emisor_comuna: '[Sin comuna]',
      emisor_ciudad: '[Sin ciudad]',
      medidores: [],
      numDeptos: []
    },
    comunidades: []
  },
  reducers: {
    selectComunidad: (state, action) => {
      state.isSelected = true;
      state.selected = action.payload;
    },
    setComunidad: (state, action) => {
      state.isSelected = action.payload.isSelected;
      state.selected = action.payload.selected;
      state.comunidades = action.payload.comunidades;
    }
  }
});

export const { setComunidad, selectComunidad } = comunidadSlice.actions;

// Action Creators
export const getComunidades = (nombreComunidadSelected) => async (dispatch) => {
  let item = LocalCache.get('KEY:comunidad');
  if (item) {
    dispatch(setComunidad(item));
  } else {
    try {
      const response = await API.get('ePlat', '/comunidades');
      if (response.length > 0) {
        for (let i = 0; i < response.length; i++) response[i].direccion = JSON.parse(response[i].direccion);
        // Aca se intenta identificar cual es la comunidad seleccionada.
        let comunidadSelected = nombreComunidadSelected ? response.find((item) => item.nombre === nombreComunidadSelected) : response[0];
        let comunidad = {
          isSelected: true,
          selected: comunidadSelected,
          comunidades: response
        };
        dispatch(setComunidad(comunidad));
        LocalCache.put('KEY:comunidad', comunidad);
      } else throw new Error('Lista de comunidades vacia');
    } catch (error) {
      console.log('DVD: ERROR Llamada a getComunidades()', error);
    }
  }
};

export default comunidadSlice.reducer;
