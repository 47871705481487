import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { getIconos } from '../redux/comunidadIconosSlice';
import LoaderSpinner from '../libreria/LoaderSpinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import './MainPanel.css';

function MainPanel() {
  const dispatch = useDispatch();

  const comunidad = useSelector((store) => store.comunidad);
  const comunidadIconos = useSelector((store) => store.comunidadIconos);

  const { promiseInProgress: portadaInProgress } = usePromiseTracker({ area: 'area-portada', delay: 0 });

  useEffect(() => {
    const onRender = async () => {
      if (comunidad.isSelected) {
        trackPromise(
          dispatch(
            getIconos(
              comunidad.selected.id,
              comunidad.selected.direccion.icon_emisor,
              comunidad.selected.direccion.label_emisor,
              comunidad.selected.direccion.logo_emisor,
              comunidad.selected.direccion.foto_portada
            )
          ),
          'area-portada'
        );
      }
    };

    onRender();
  }, [comunidad.selected]);

  return (
    <Container fluid className='MainPanel'>
      <Row className='p-2'>
        <Col xs={12} xl={9} className='p-2'>
          <Card className='shadow-sm'>
            <Card.Header className='h5 fw-bold text-center'>ePlat una solución de SavE</Card.Header>
            <Card.Img src='images/foto-energia-electrica.jpg' className='img-bienvenido'></Card.Img>
            <Card.Body>
              <Card.Title className='fw-bold text-center'>Bienvenido a ePlat</Card.Title>
              <Card.Text>
                ePlat permite gestionar el servicio de facturación y pago de los departamentos de una comunidad que ha optado por la modalidad de Cliente Libre. ePlat realiza el seguimiento de tarifas
                y consumo de electricidad, gestión de cobros, intereses y pagos del servicio eléctrico.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} xl={9} className='p-2'>
          <LoaderSpinner isLoading={portadaInProgress}>
            <Card className='shadow-sm'>
              <Card.Header className='h5 fw-bold text-center'>{comunidad.selected.direccion.titulo_portada}</Card.Header>
              <Card.Img src={comunidadIconos.fotoPortada} className='img-proyecto'></Card.Img>
              <Card.Body>
                <Card.Title className='fw-bold text-center'>{comunidad.selected.direccion.subtitulo_portada}</Card.Title>
                <Card.Text>{comunidad.selected.direccion.texto_portada}</Card.Text>
              </Card.Body>
            </Card>
          </LoaderSpinner>
        </Col>
      </Row>
    </Container>
  );
}

export default MainPanel;
