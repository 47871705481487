import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';
import moment from 'moment-timezone';

export const documentosCierreSlice = createSlice({
  name: 'documentosCierre',
  initialState: {
    resumen: null,
    tabla: [],
    dimensiones: [],
    totalColumns: [],
    totalRows: []
  },
  reducers: {
    setDocumentosCierre: (state, action) => {
      state.resumen = action.payload.resumen;
      state.tabla = action.payload.tabla;
      state.dimensiones = action.payload.dimensiones;
      state.totalColumns = action.payload.totalColumns;
      state.totalRows = action.payload.totalRows;
    }
  }
});

export const { setDocumentosCierre } = documentosCierreSlice.actions;

// Action Creators
export const getDocumentosCierre = (comunidadId, periodo) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:documentosCierre&comunidad=${comunidadId}&periodo=${periodo}`);
  if (item) {
    dispatch(setDocumentosCierre(item));
  } else {
    try {
      const response = await API.get('ePlat', `/get-documentos-cierre?comunidad=${comunidadId}&periodo=${periodo}`);
      let reporte = getTablaDocumentosCierreFromDatos(response);
      dispatch(setDocumentosCierre(reporte));
      LocalCache.put(`KEY:documentosCierre&comunidad=${comunidadId}&periodo=${periodo}`, reporte);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getDocumentosCierre()', error);
    }
  }
};

/** Funcion que transforma el valor recibido a numero, sea este numero o cadena de strings. */
function toNumber(valor) {
  return valor ? (typeof valor === 'string' ? parseInt(valor) : valor) : 0;
}

/** Esta funcion construye un reporte matricial tipo Tabla Dinámica, es decir un cruce entre
 * filas y columnas, de 'Emision Documentos' versus 'Estados' para poder presentar en la pantalla
 * de la aplicación web. Se calculan además datos de resumen para presentar en pantalla. */
function getTablaDocumentosCierreFromDatos(datos) {
  let tabla = [];
  let dimensiones = [];
  let totalColumns = [];
  let totalRows = [];
  let fechaEmision = null;
  let cantidadValidas = 0;
  let cantidadAnuladas = 0;
  let totalValidas = 0;
  /** Lo primero es obtener las dimensiones existentes */
  for (let i = 0; i < datos.length; i++) {
    let dimension = dimensiones.find((item) => item === datos[i].estado);
    if (!dimension) dimensiones.push(datos[i].estado);
  }
  dimensiones.sort(); // Siempre queremos las ANULADAS primero.
  /** Luego se construye la tabla, considerando las dimensiones. */
  let filaIndex = 0;
  for (let i = 0; i < datos.length; i++) {
    let fila = tabla.find((item) => item.dia_emision === datos[i].dia_emision && item.tipo_documento === datos[i].tipo_documento);
    if (!fila) {
      fila = new Object();
      fila.tipo_documento = datos[i].tipo_documento;
      fila.dia_emision = datos[i].dia_emision;
      fila.dia_vencimiento = datos[i].dia_vencimiento;
      // Se inicializan las dimensiones.
      let columnas = [];
      for (let j = 0; j < dimensiones.length; j++) columnas.push({ estado: dimensiones[j], cantidad: 0, monto: 0 });
      fila.columnas = columnas;
      tabla.push(fila);
      // totalColumns.push(0);
      filaIndex++;
    }
    // Se agrega dimension segun corresponda.
    let columna = fila.columnas.find((item) => item.estado === datos[i].estado);
    columna.cantidad = datos[i].cantidad;
    columna.monto = datos[i].monto_total;
    // Totales por columna.
    if (columna.estado === 'VALIDA') totalColumns[filaIndex - 1] = columna.monto;
    // Datos para resumen.
    if (columna.estado === 'VALIDA') {
      let nuevaFecha = moment(fila.dia_emision);
      if (fechaEmision) {
        if (nuevaFecha.isBefore(fechaEmision)) fechaEmision = nuevaFecha;
      } else fechaEmision = nuevaFecha;
      cantidadValidas = cantidadValidas + columna.cantidad;
      totalValidas = totalValidas + toNumber(columna.monto);
    } else cantidadAnuladas = cantidadAnuladas + columna.cantidad;
  }
  // Totales por fila.
  for (let i = 0; i < tabla.length; i++) {
    let fila = tabla[i];
    for (let j = 0; j < fila.columnas.length; j++) {
      let item = fila.columnas[j];
      let totalRow = totalRows.find((total) => total.estado === item.estado);
      if (!totalRow) {
        totalRow = new Object();
        totalRow.estado = item.estado;
        totalRow.cantidad = 0;
        totalRow.monto = 0;
        totalRows.push(totalRow);
      }
      totalRow.cantidad = totalRow.cantidad + item.cantidad;
      totalRow.monto = totalRow.monto + toNumber(item.monto);
    }
  }
  // Objeto resumen.
  let resumen = fechaEmision
    ? {
        fechaEmision: fechaEmision.format('YYYY-MM-DD'),
        cantidadValidas: cantidadValidas,
        cantidadAnuladas: cantidadAnuladas,
        totalValidas: totalValidas
      }
    : null;
  // Retorna respuesta.
  return { resumen: resumen, tabla: tabla, dimensiones: dimensiones, totalColumns: totalColumns, totalRows: totalRows };
}

export default documentosCierreSlice.reducer;
