import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Navbar, Breadcrumb, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import RangoPeriodos, { rangos } from '../../componentes/RangoPeriodos';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { pintaPesos, pintaNumero } from '../../libreria/utilidades';
import { getRentabilidad } from '../../redux/rentabilidadSlice';
import GraficoRentabilidad from './GraficoRentabilidad';
import './PanelRentabilidad.css';

function PanelRentabilidad() {
  const comunidad = useSelector((store) => store.comunidad);
  const rentabilidad = useSelector((store) => store.rentabilidad);
  const [selectedRango, setSelectedRango] = useState(0);
  const { promiseInProgress: rentabilidadInProgress } = usePromiseTracker({ area: 'area-rentabilidad', delay: 0 });
  const dispatch = useDispatch();

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getRentabilidad(comunidad.selected.id, rangos[selectedRango])), 'area-rentabilidad');
    };

    onRender();
  }, [comunidad.selected]);

  function handleSelectRangoPeriodo(eventKey, event) {
    setSelectedRango(eventKey);
    trackPromise(dispatch(getRentabilidad(comunidad.selected.id, rangos[eventKey])), 'area-rentabilidad');
  }

  return (
    <>
      <Container fluid className='PanelRentabilidad d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Compra y venta de energía
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Rentabilidad</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='PanelRentabilidad'>
        <Row className='p-2'>
          <Col xs={12} lg={10} xl={9} xxl={8} className='p-2'>
            <LoaderSpinner isLoading={rentabilidadInProgress}>
              <Card className='shadow-sm'>
                <Card.Header className='bg-light d-flex flex-wrap p-0'>
                  <Navbar className='flex-fill'>
                    <Card.Title className='fs-4 m-0 ms-3 fw-bold'>Rentabilidad histórica</Card.Title>
                  </Navbar>
                  <Navbar className='flex-fill justify-content-end'>
                    <p className='d-none d-lg-inline m-0 p-0'>Rango períodos:</p>
                    <RangoPeriodos onSelect={handleSelectRangoPeriodo} selected={selectedRango} />
                  </Navbar>
                </Card.Header>
                <Card.Body className='py-2'>
                  <GraficoRentabilidad rango={selectedRango} style={{ maxHeight: '14rem', maxWidth: '680px' }} className='mb-3' />
                  <Table hover responsive size='sm' className='fs-6'>
                    <thead className='text-center bgc-gris'>
                      <tr>
                        <th className='border-end border-white'>Período</th>
                        <th className='border-end border-white'>
                          Venta neta
                          <br />
                          (Sin saldo anterior)
                        </th>
                        <th className='border-end border-white'>
                          Compra neta
                          <br />
                          (Sin saldo anterior)
                        </th>
                        <th className='border-end border-white'>Margen</th>
                        <th className='border-end border-white'>Margen %</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rentabilidad.rows.map((item, index) => (
                        <tr key={index}>
                          <td>{item.periodo}</td>
                          <td className='text-end'>{pintaPesos(item.neto_venta)}</td>
                          <td className='text-end'>{pintaPesos(item.neto_compra)}</td>
                          <td className={`text-end${item.margen < 0 ? ' negative-number' : ''}`}>{pintaPesos(item.margen)}</td>
                          <td className={`text-end${item.margen < 0 ? ' negative-number' : ''}`}>{pintaNumero(item.margenP)} %</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PanelRentabilidad;
