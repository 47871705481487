import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const departamentoSlice = createSlice({
  name: 'departamento',
  initialState: {
    numero: 0,
    datos: null
  },
  reducers: {
    setDepartamento: (state, action) => {
      state.numero = action.payload.numero;
      state.datos = action.payload.datos;
    }
  }
});

export const { setDepartamento } = departamentoSlice.actions;

// Action Creators
export const getDepartamento = (comunidadId, numero) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:departamento&comunidad=${comunidadId}&numero=${numero}`);
  if (item) {
    dispatch(setDepartamento(item));
  } else {
    try {
      const response = await API.get('ePlat', `/get-departamento?comunidad=${comunidadId}&numero=${numero}`);
      let departamento = { numero: numero, datos: response };
      dispatch(setDepartamento(departamento));
      LocalCache.put(`KEY:departamento&comunidad=${comunidadId}&numero=${numero}`, departamento);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getDepartamento()', error);
    }
  }
};

export default departamentoSlice.reducer;
