import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import SdStorageRoundedIcon from '@mui/icons-material/SdStorageRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import BalanceIcon from '@mui/icons-material/Balance';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';

export const Menu = [
  {
    id: 'business',
    texto: 'Compra y venta de energía',
    submenu: [
      {
        id: 'rentabilidad',
        texto: 'Rentabilidad',
        icono: () => <BarChartRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/rentabilidad'
      },
      {
        id: 'balance',
        texto: 'Balance de energía',
        icono: () => <BalanceIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/balance'
      },
      {
        id: 'compra',
        texto: 'Compra de energía',
        icono: () => <BoltRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/compra-energia'
      },
      {
        id: 'venta',
        texto: 'Venta de energía',
        icono: () => <PaidRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/venta-energia'
      },
      {
        id: 'cierre',
        texto: 'Proceso cierre mensual',
        icono: () => <SettingsIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/proceso-cierre'
      }
    ]
  },
  {
    id: 'community',
    texto: 'Gestión de la comunidad',
    submenu: [
      {
        id: 'comunidad',
        texto: 'Comunidad',
        icono: () => <ApartmentRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/comunidad'
      }
    ]
  },
  {
    id: 'service',
    texto: 'Gestión del servicio',
    submenu: [
      {
        id: 'medidor',
        texto: 'Datos de medidores',
        icono: () => <SdStorageRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/medidor'
      },
      {
        id: 'documento',
        texto: 'Facturas y boletas',
        icono: () => <ReceiptLongRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/documento'
      },
      {
        id: 'pago',
        texto: 'Pagos',
        icono: () => <PaymentRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/pago'
      }
    ]
  },
  { separador: true },
  {
    id: 'config',
    texto: 'Configuración',
    submenu: [
      {
        id: 'perfil',
        texto: 'Perfil',
        icono: () => <ManageAccountsRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/perfil'
      },
      {
        id: 'clave',
        texto: 'Cambio de contraseña',
        icono: () => <LockRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />,
        link: '/clave'
      }
    ]
  }
];
