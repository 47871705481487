import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const ventaSlice = createSlice({
  name: 'venta',
  initialState: {
    periodo: null,
    qtty_dtes: 0,
    qtty_boletas: 0,
    qtty_facturas: 0,
    total_boletas: null,
    total_facturas: null,
    boletas_exento: null,
    facturas_exento: null,
    boletas_afecto: null,
    facturas_afecto: null,
    boletas_iva: null,
    facturas_iva: null,
    boletas_saldo_anterior: null,
    facturas_saldo_anterior: null,
    qtty_pagos: 0,
    total_pagos: null
  },
  reducers: {
    setVenta: (state, action) => {
      let { payload } = action;
      state.periodo = payload.periodo;
      state.qtty_dtes = payload.qtty_dtes;
      state.qtty_boletas = payload.qtty_boletas;
      state.qtty = payload.qtty_facturas;
      state.total_boletas = payload.total_boletas;
      state.total_facturas = payload.total_facturas;
      state.boletas_exento = payload.boletas_exento;
      state.facturas_exento = payload.facturas_exento;
      state.boletas_afecto = payload.boletas_afecto;
      state.facturas_afecto = payload.facturas_afecto;
      state.boletas_iva = payload.boletas_iva;
      state.facturas_iva = payload.facturas_iva;
      state.boletas_saldo_anterior = payload.boletas_saldo_anterior;
      state.facturas_saldo_anterior = payload.facturas_saldo_anterior;
      state.qtty_pagos = payload.qtty_pagos;
      state.total_pagos = payload.total_pagos;
    }
  }
});

export const { setVenta } = ventaSlice.actions;

// Action Creators
export const getVenta = (comunidadId, anno, mes) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:venta&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
  if (item) {
    dispatch(setVenta(item));
  } else {
    try {
      const response = await API.get('ePlat', `/venta?comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
      const venta = {
        periodo: response.periodo,
        qtty_dtes: response.qtty_dtes,
        qtty_boletas: response.qtty_boletas,
        qtty_facturas: response.qtty_facturas,
        total_boletas: response.total_boletas,
        total_facturas: response.total_facturas,
        boletas_exento: response.boletas_exento,
        facturas_exento: response.facturas_exento,
        boletas_afecto: response.boletas_afecto,
        facturas_afecto: response.facturas_afecto,
        boletas_iva: response.boletas_iva,
        facturas_iva: response.facturas_iva,
        boletas_saldo_anterior: response.boletas_saldo_anterior,
        facturas_saldo_anterior: response.facturas_saldo_anterior,
        qtty_pagos: response.qtty_pagos,
        total_pagos: response.total_pagos
      };
      dispatch(setVenta(venta));
      LocalCache.put(`KEY:venta&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`, venta);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getVenta()', error);
    }
  }
};

export default ventaSlice.reducer;
