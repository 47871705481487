import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const consumoHistoricoSlice = createSlice({
  name: 'consumoHistorico',
  initialState: {
    data: []
  },
  reducers: {
    setConsumoHistorico: (state, action) => {
      state.data = action.payload;
    }
  }
});

export const { setConsumoHistorico } = consumoHistoricoSlice.actions;

// Action Creators
export const consumoHistorico = (comunidadId, folio, tipo) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:consumoHistorico&comunidad=${comunidadId}&folio=${folio}&tipo=${tipo}`);
  if (item) {
    dispatch(setConsumoHistorico(item));
  } else {
    try {
      const response = await API.get('ePlat', `/documento-consumo-historico?comunidad=${comunidadId}&folio=${folio}&tipo=${tipo}`);
      let consumos = response.consumos;
      dispatch(setConsumoHistorico(consumos));
      LocalCache.put(`KEY:consumoHistorico&comunidad=${comunidadId}&folio=${folio}&tipo=${tipo}`, consumos);
    } catch (error) {
      console.log('DVD: ERROR Llamada a consumoHistorico()', error);
    }
  }
};

export default consumoHistoricoSlice.reducer;
