import React, { useEffect } from 'react';
import { Container, Navbar, Breadcrumb, Table, Row, Col, Card } from 'react-bootstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectPeriodo } from '../../redux/periodoSlice';
import { getTarifasCierre } from '../../redux/tarifasCierreSlice';
import PeriodoDropDown from '../../componentes/PeriodoDropDown';
import { pintaNumero, pintaPeriodo, pintaPesosDecimales } from '../../libreria/utilidades';
import WarningMessage from '../../componentes/WarningMessage';
import CardPlaceHolder from '../../componentes/CardPlaceHolder';
import './Tarifas.css';

function Tarifas() {
  const comunidad = useSelector((store) => store.comunidad);
  const periodo = useSelector((store) => store.periodo);
  const tarifasCierre = useSelector((store) => store.tarifasCierre);
  const { resumen, bt1, bt43 } = tarifasCierre;
  const { promiseInProgress: tarifasCierreInProgress } = usePromiseTracker({ area: 'area_tarifas_cierre', delay: 0 });
  const dispatch = useDispatch();

  function handlePeriodoClick(data) {
    dispatch(selectPeriodo(data));
  }

  const formatPeriodo = (p) => p.anno.toString() + '-' + (p.mes < 10 ? `0${p.mes.toString()}` : p.mes.toString());

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getTarifasCierre(comunidad.selected.id, formatPeriodo(periodo))), 'area_tarifas_cierre');
    };
    onRender();
  }, [periodo]);

  return (
    <>
      <Container fluid className='Tarifas d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Compra y venta de energía
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/proceso-cierre' className='text-decoration-none'>
                  Proceso cierre mensual
                </Link>
              </li>
              <Breadcrumb.Item active>Tarifas</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <p className='m-0 me-2 d-none d-sm-inline text-nowrap'>Período (mes/año):</p>
          <PeriodoDropDown className='me-3' onSelect={handlePeriodoClick} value={pintaPeriodo(periodo)} />
        </Navbar>
      </Container>
      <Container fluid className='Tarifas'>
        <Row className='p-2'>
          <Col xs={12} lg={11} xl={10} xxl={8} className='p-2'>
            <LoaderSpinner isLoading={tarifasCierreInProgress}>
              {resumen ? (
                <Card className='shadow-sm'>
                  <Card.Header className='h5 fw-bold text-center'>Resultado tarea: Carga de tarifas del período</Card.Header>
                  <Card.Body>
                    <Card.Text className='fs-6 fw-bold m-1'>
                      Resultado de la ejecución: <i className='fst-normal fw-normal'>EJECUCIÓN COMPLETADA</i>
                    </Card.Text>
                    <Card.Text className='fs-6 fw-bold m-1'>
                      Período: <i className='fst-normal fw-normal'>{pintaPeriodo(periodo)}</i>
                    </Card.Text>
                    <Card.Text className='fs-6 fw-bold m-1'>
                      Cantidad registros: <i className='fst-normal fw-normal'>{pintaNumero(resumen.count)}</i>
                    </Card.Text>
                    <Row className='m-0 mt-2'>
                      <Col xs={12} sm={6} className='p-0 pe-sm-3'>
                        <div className='m-0 p-0'>
                          <Card.Text className='fs-6 fw-bold m-1'>Tarifas bt1</Card.Text>
                          <Table striped responsive size='sm' className='fs-6 mb-1'>
                            <thead>
                              <tr className='text-center'>
                                <th>Código</th>
                                <th>Valor neto</th>
                                <th>Descripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {bt1.map((tarifa, index) => (
                                <tr key={index}>
                                  <td className='fw-bold'>{tarifa.codigo}</td>
                                  <td className='fw-bold text-nowrap text-end'>{pintaPesosDecimales(tarifa.neto)}</td>
                                  <td className='text-nowrap'>{tarifa.item}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                      <Col xs={12} sm={6} className='p-0 ps-sm-3'>
                        <div className='m-0 p-0'>
                          <Card.Text className='fs-6 fw-bold m-1'>Tarifas bt4.3</Card.Text>
                          <Table striped responsive size='sm' className='fs-6 mb-1'>
                            <thead>
                              <tr className='text-center'>
                                <th>Código</th>
                                <th>Valor neto</th>
                                <th>Descripción</th>
                              </tr>
                            </thead>
                            <tbody>
                              {bt43.map((tarifa, index) => (
                                <tr key={index}>
                                  <td className='fw-bold'>{tarifa.codigo}</td>
                                  <td className='fw-bold text-nowrap text-end'>{pintaPesosDecimales(tarifa.neto)}</td>
                                  <td className='text-nowrap'>{tarifa.item}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ) : tarifasCierreInProgress ? (
                <CardPlaceHolder />
              ) : (
                <WarningMessage>No se han encontrado tarifas cargadas para el período: {pintaPeriodo(periodo)}.</WarningMessage>
              )}
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Tarifas;
