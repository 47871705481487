import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Breadcrumb, Table, Image, Row, Col, Card, Button } from 'react-bootstrap';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { downloadDocumentos } from '../../redux/downloadDocumentosSlice';
import { pintaFechaHora, pintaPeriodo } from '../../libreria/utilidades';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function DescargaDocumentos() {
  const comunidad = useSelector((store) => store.comunidad);
  const periodo = useSelector((store) => store.periodo);
  const documentos = useSelector((store) => store.downloadDocumentos);

  const dispatch = useDispatch();
  const { promiseInProgress: downloadDocumentosProgress } = usePromiseTracker({ area: 'download-documentos', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(downloadDocumentos(comunidad.selected.id, periodo.anno, periodo.mes)), 'download-documentos');
    };

    onRender();
  }, [comunidad.selected]);

  function toNumber(valor) {
    return valor ? (typeof valor === 'string' ? parseInt(valor) : valor) : 0;
  }

  function handleClickDescarga() {
    const fileName = `ePlat-Com.${comunidad.selected.id}-Documentos-${pintaPeriodo(periodo)}.xlsx`;
    let data = [];
    for (let i = 0; i < documentos.rows.length; i++) {
      let row = new Object();
      row.comunidad_id = documentos.rows[i].comunidad_id;
      row.periodo = documentos.rows[i].periodo;
      row.codido_servicio = documentos.rows[i].codigo_servicio;
      row.numero_depto = documentos.rows[i].numero_depto;
      row.medidor_id = documentos.rows[i].medidor_id;
      row.tipo_tarifa = documentos.rows[i].tipo_tarifa;
      row.business_partner = documentos.rows[i].business_partner;
      row.fecha_emision = pintaFechaHora(documentos.rows[i].fecha_emision);
      row.fecha_vencimiento = pintaFechaHora(documentos.rows[i].fecha_vencimiento);
      row.lectura_periodo = documentos.rows[i].lectura_periodo;
      row.consumo_periodo = documentos.rows[i].consumo_periodo;
      row.tipo_documento = documentos.rows[i].tipo_documento;
      row.folio = documentos.rows[i].folio;
      row.estado = documentos.rows[i].estado;
      row.monto = documentos.rows[i].tipo_documento === 'BOLETA' ? toNumber(documentos.rows[i].total_boleta) : toNumber(documentos.rows[i].total_factura);
      data.push(row);
    }
    let header = [
      'comunidad',
      'periodo',
      'codigo_servicio',
      'numero_depto',
      'medidor',
      'tipo_tarifa',
      'business_partner',
      'fecha_emision',
      'fecha_vencimiento',
      'lectura_periodo',
      'consumo_periodo',
      'tipo_documento',
      'folio',
      'estado',
      'monto'
    ];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
    const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(finalData, fileName);
  }

  return (
    <>
      <Container fluid className='PanelPagos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/documento' className='text-decoration-none'>
                  Facturas y boletas
                </Link>
              </li>
              <Breadcrumb.Item active>Descarga documentos período {pintaPeriodo(periodo)}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid>
        <Row className='p-2'>
          <Col xs={12} md={12} className='p-2'>
            <Card className='shadow-sm' style={{ maxWidth: '540px' }}>
              <LoaderSpinner isLoading={downloadDocumentosProgress}>
                <Card.Body>
                  <Table borderless className='m-0'>
                    <tbody>
                      <tr className='text-center align-middle'>
                        <td>
                          <Image fluid src={`${process.env.PUBLIC_URL}/images/success-icon.png`} style={{ minWidth: '40px' }} />
                        </td>
                        <td className='p-0'>
                          <Table borderless className='m-0'>
                            <tbody>
                              <tr>
                                <td>
                                  <Card.Title className='fs-3 fw-normal text-dark'>Se ha generado el reporte de detalle de documentos para el período: {pintaPeriodo(periodo)}</Card.Title>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Button size='sm' className='fs-6 btn-outline-secondary text-nowrap btn-actualizar' variant='white' onClick={handleClickDescarga}>
                                    <DownloadRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />
                                    Descargar
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DescargaDocumentos;
