import React from 'react';
import './EPlatFooter.css';

function EPlatFooter() {
  return (
    <footer className='EPlatFooter mt-auto d-flex justify-content-center align-items-center py-2 ancho-interno border-top'>
      <h6 className='fs-6 text-nowrap m-0'>
        <b>
          <i className='bold'>e</i>Plat <i className='fst-normal soft'>una solución de </i>Sav
          <i className='fst-normal bold'>E</i> <i className='fst-normal soft'>SpA.</i>
        </b>
      </h6>
    </footer>
  );
}

export default EPlatFooter;
