import React, { useEffect } from 'react';
import { Container, Table, Row, Col, Card, Navbar, Breadcrumb, Dropdown } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pintaNumero, pintaFecha, pintaPesos, pintaEstado, pintaPeriodo } from '../../libreria/utilidades';
import { getDocumentos } from '../../redux/documentosSlice';
import { selectPeriodo } from '../../redux/periodoSlice';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import SearchIcon from '@mui/icons-material/Search';
import SearchById from '../../componentes/SearchById';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import PeriodoDropDown from '../../componentes/PeriodoDropDown';

import './PanelDocumentos.css';

function PanelDocumentos() {
  const ROWS_QTTY = 18;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const comunidad = useSelector((store) => store.comunidad);
  const documentos = useSelector((store) => store.documentos);
  const periodo = useSelector((store) => store.periodo);
  const { promiseInProgress } = usePromiseTracker({ delay: 0 });

  useEffect(() => {
    const onRender = () => {
      if (comunidad.isSelected) {
        trackPromise(dispatch(getDocumentos(comunidad.selected.id, periodo.anno, periodo.mes, 0, ROWS_QTTY)));
      }
    };

    onRender();
  }, [comunidad.selected, periodo]);

  function handleDocumentoSearch(value) {
    navigate(`/documento/folio/${Number(value)}`);
  }

  function handleCodigoServicioSearch(value) {
    navigate(`/documento/cod-servicio/${Number(value)}`);
  }

  function handlePageClick(data) {
    trackPromise(dispatch(getDocumentos(comunidad.selected.id, periodo.anno, periodo.mes, data.selected, ROWS_QTTY)));
  }

  function handlePeriodoClick(data) {
    // El cambio de periodo no accede a base de datos, no es necesario usar trackPromise().
    dispatch(selectPeriodo(data));
  }

  function handleReportesOpcion(eventKey) {
    if (eventKey === 'download_sabana') {
      navigate(`/documento/descarga-tarifas-facturacion`);
    } else if (eventKey === 'download_documentos') {
      navigate(`/documento/descarga-documentos`);
    }
  }

  return (
    <>
      <Container fluid className='PanelDocumentos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Facturas y boletas</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
        <Navbar bg='white' className='flex-fill flex-wrap border-bottom justify-content-end'>
          <p className='m-0 me-2 d-none d-md-inline text-nowrap'>Período (mes/año):</p>
          <PeriodoDropDown className='me-2' onSelect={handlePeriodoClick} value={pintaPeriodo(periodo)} />
        </Navbar>
      </Container>
      <Container fluid className='PanelDocumentos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <SearchById placeholder='Folio docto.' btntext='Buscar' onClick={handleDocumentoSearch} />
          <div className='vr mx-2'></div>
          <p className='m-0 me-2 d-none d-md-inline text-nowrap'>Más documentos:</p>
          <SearchById placeholder='Código.serv' btntext='Buscar' onClick={handleCodigoServicioSearch} />
        </Navbar>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <Dropdown onSelect={handleReportesOpcion} className='me-2'>
            <Dropdown.Toggle variant='outline-secondary' size='sm' id='dropdown-cod-servicio'>
              <MenuRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-1' />
              Descarga reportes
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu-end'>
              <Dropdown.Item eventKey='download_sabana'>Tarifas y facturación del período</Dropdown.Item>
              <Dropdown.Item eventKey='download_documentos'>Lista documentos por período</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </Container>
      <Container fluid className='PanelDocumentos'>
        <Row className='p-2'>
          <Col xs={12} xxl={10} className='p-2'>
            <Card className='shadow-sm'>
              <Card.Header className='d-flex flex-wrap p-0'>
                <Navbar className='flex-fill'>
                  <Card.Title className='fs-4 m-0 ms-3 fw-bold'>
                    Documentos del período: <i className='fst-normal fw-normal text-nowrap'>{pintaPeriodo(periodo)}</i>
                  </Card.Title>
                </Navbar>
                <Navbar className='flex-fill flex-wrap justify-content-end'>
                  <ReactPaginate
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'..'}
                    pageCount={Math.ceil(documentos.count / ROWS_QTTY)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    forcePage={documentos.page}
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination pagination-sm m-0 me-2'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </Navbar>
              </Card.Header>
              <LoaderSpinner isLoading={promiseInProgress}>
                <Card.Body>
                  <Table striped hover responsive size='sm' className='h6'>
                    <thead>
                      <tr className='text-center'>
                        <th>Tipo</th>
                        <th>Código servicio</th>
                        <th>Depto.</th>
                        <th>Medidor</th>
                        <th>Tipo tarifa</th>
                        <th>Nombre cliente</th>
                        <th>F.emisión</th>
                        <th>F.vencimiento</th>
                        <th>Lectura [kWh]</th>
                        <th>Consumo [kWh]</th>
                        <th>Folio</th>
                        <th>Estado</th>
                        <th>Monto</th>
                        <th>Otros períodos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentos.rows.map((documento) => (
                        <tr key={documento.folio}>
                          <td className='text-center'>{documento.tipo_documento}</td>
                          <td className='text-center'>{pintaNumero(documento.codigo_servicio)}</td>
                          <td className='text-nowrap'>{documento.numero_depto}</td>
                          <td className='text-center'>
                            <Link className='text-decoration-none' to={'/medidor/' + documento.medidor_id}>
                              {documento.medidor_id}
                            </Link>
                          </td>
                          <td className='text-center'>{documento.tipo_tarifa}</td>
                          <td className='text-center text-nowrap'>{documento.business_partner}</td>
                          <td className='text-center text-nowrap'>{pintaFecha(documento.fecha_emision)}</td>
                          <td className='text-center text-nowrap'>{pintaFecha(documento.fecha_vencimiento)}</td>
                          <td className='text-end'>{pintaNumero(documento.lectura_periodo)}</td>
                          <td className='text-end'>{pintaNumero(documento.consumo_periodo)}</td>
                          <td className='text-end'>
                            <Link className='text-decoration-none' to={documento.tipo_documento === 'BOLETA' ? `/boleta/${documento.folio}` : `/factura/${documento.folio}`}>
                              {pintaNumero(documento.folio)}
                            </Link>
                          </td>
                          <td className='text-center'>{pintaEstado(documento.estado)}</td>
                          <td className='text-end text-nowrap'>
                            {documento.tipo_documento === 'BOLETA' ? pintaPesos(parseInt(documento.total_boleta)) : pintaPesos(parseInt(documento.total_factura))}
                          </td>
                          <td className='text-end'>
                            <Link to={`/documento/cod-servicio/${documento.codigo_servicio}`} className='link-primary fw-light text-decoration-none text-nowrap'>
                              Ver doctos.<button className='ms-1 button-link'>...</button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PanelDocumentos;
