import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const facturaSlice = createSlice({
  name: 'factura',
  initialState: {
    folio: 0,
    cabecera: null,
    cargos: []
  },
  reducers: {
    setFactura: (state, action) => {
      state.folio = action.payload.folio;
      state.cabecera = action.payload.cabecera;
      state.cargos = action.payload.cargos;
    }
  }
});

export const { setFactura } = facturaSlice.actions;

// Action Creators
export const getFactura = (comunidadId, folio) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:factura&comunidad=${comunidadId}&folio=${folio}`);
  if (item) {
    dispatch(setFactura(item));
  } else {
    try {
      const response = await API.get('ePlat', `/get-factura?comunidad=${comunidadId}&folio=${folio}`);
      let factura = { folio: folio, cabecera: response.factura, cargos: response.cargos };
      dispatch(setFactura(factura));
      LocalCache.put(`KEY:factura&comunidad=${comunidadId}&folio=${folio}`, factura);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getFactura()', error);
    }
  }
};

export default facturaSlice.reducer;
