import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Breadcrumb, Table, Image, Row, Col, Card, Button } from 'react-bootstrap';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPagos } from '../../redux/downloadPagosSlice';
import { pintaFechaHora, pintaPeriodo, pintaOrigenPago } from '../../libreria/utilidades';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function DescargaPagosPeriodo() {
  const comunidad = useSelector((store) => store.comunidad);
  const periodo = useSelector((store) => store.periodo);
  const pagosToDownload = useSelector((store) => store.downloadPagos);

  const dispatch = useDispatch();
  const { promiseInProgress: downloadPagosInProgress } = usePromiseTracker({ area: 'download-pagos', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(downloadPagos(comunidad.selected.id, periodo.anno, periodo.mes)), 'download-pagos');
    };

    onRender();
  }, [comunidad.selected]);

  function handleClickDescarga() {
    const fileName = `ePlat-Com.${comunidad.selected.id}-PagosPeriodo-${pintaPeriodo(periodo)}.xlsx`;
    let data = [];
    for (let i = 0; i < pagosToDownload.rows.length; i++) {
      let row = new Object();
      row.comunidad_id = pagosToDownload.rows[i].comunidad_id;
      row.periodo = pagosToDownload.rows[i].periodo;
      row.codido_servicio = pagosToDownload.rows[i].codigo_servicio;
      row.numero_depto = pagosToDownload.rows[i].numero_depto;
      row.medidor_id = pagosToDownload.rows[i].medidor_id;
      row.tipo_tarifa = pagosToDownload.rows[i].tipo_tarifa;
      row.business_partner = pagosToDownload.rows[i].business_partner;
      row.fecha_pago = pintaFechaHora(pagosToDownload.rows[i].fecha_pago);
      row.folio_boleta = pagosToDownload.rows[i].folio;
      row.fecha_vencimiento = pintaFechaHora(pagosToDownload.rows[i].fecha_vencimiento);
      row.origen = pintaOrigenPago(pagosToDownload.rows[i].origen);
      row.monto = pagosToDownload.rows[i].monto;
      data.push(row);
    }
    let header = [
      'comunidad',
      'periodo',
      'codigo_servicio',
      'numero_depto',
      'medidor',
      'tipo_tarifa',
      'business_partner',
      'fecha_pago',
      'folio_boleta',
      'fecha_vencimiento',
      'origen_pago',
      'monto_pago'
    ];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
    const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(finalData, fileName);
  }

  return (
    <>
      <Container fluid className='PanelPagos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/pago' className='text-decoration-none'>
                  Pagos
                </Link>
              </li>
              <Breadcrumb.Item active>Descarga pagos período {pintaPeriodo(periodo)}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid>
        <Row className='p-2'>
          <Col xs={12} md={12} className='p-2'>
            <Card className='shadow-sm' style={{ maxWidth: '540px' }}>
              <LoaderSpinner isLoading={downloadPagosInProgress}>
                <Card.Body>
                  <Table borderless className='m-0'>
                    <tbody>
                      <tr className='text-center align-middle'>
                        <td>
                          <Image fluid src={`${process.env.PUBLIC_URL}/images/success-icon.png`} style={{ minWidth: '40px' }} />
                        </td>
                        <td className='p-0'>
                          <Table borderless className='m-0'>
                            <tbody>
                              <tr>
                                <td>
                                  <Card.Title className='fs-3 fw-normal text-dark'>Se ha generado el reporte de detalle de pagos para el período: {pintaPeriodo(periodo)}</Card.Title>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Button size='sm' className='fs-6 btn-outline-secondary text-nowrap btn-actualizar' variant='white' onClick={handleClickDescarga}>
                                    <DownloadRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />
                                    Descargar
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DescargaPagosPeriodo;
