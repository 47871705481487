import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const ventaPagosSlice = createSlice({
  name: 'ventaPagos',
  initialState: {
    rows: []
  },
  reducers: {
    setVentaPagos: (state, action) => {
      state.rows = action.payload;
    }
  }
});

export const { setVentaPagos } = ventaPagosSlice.actions;

// Action Creators
export const getVentaPagos = (comunidadId, rango) => async (dispatch, getState) => {
  const inicio = rango.inicio;
  const fin = rango.fin;
  let item = LocalCache.get(`KEY:ventaPagos&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setVentaPagos(item));
  } else {
    try {
      const response = await API.get('ePlat', `/venta-pagos?comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
      let rendered = renderVentasPagos(response);
      dispatch(setVentaPagos(rendered));
      LocalCache.put(`KEY:ventaPagos&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`, rendered);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getVentaPagos()', error);
    }
  }
};

// Crea render de objeto para tabla en pantalla de Ventas pagos.
function renderVentasPagos(datos) {
  /** Se construye una respuesta adhoc para el reporte consolidado de pagos que se presenta en la capa
   * cliente, en la funcionalidad de Ventas, donde se muestra una tabla con todas los documentos y pagos.
   * Este respuesta consiste en un arreglo de periodos, con un arreglo interno de origenes de pago, y
   * los totales de documentos y pagos por cada periodo. */
  let respuesta = [];
  for (let i = 0; i < datos.length; i++) {
    let current_period = datos[i].periodo;
    let resumen_periodo = respuesta.find((item) => item.periodo === current_period);
    if (resumen_periodo) {
      resumen_periodo.total_dtes = resumen_periodo.total_dtes + datos[i].total_dte;
      resumen_periodo.cantidad_pagos = resumen_periodo.cantidad_pagos + datos[i].qtty;
      resumen_periodo.total_pagos = resumen_periodo.total_pagos + datos[i].total_pago;
      let current_origen = datos[i].origen;
      if (current_origen) {
        let item_origen = { origen: datos[i].origen, cantidad: datos[i].qtty, monto: datos[i].total_pago };
        resumen_periodo.count = resumen_periodo.count + 1;
        if (resumen_periodo.origenes) {
          let acum_origen = resumen_periodo.origenes.find((item) => item.origen === item_origen.origen);
          if (acum_origen) {
            acum_origen.cantidad = acum_origen.cantidad + item_origen.cantidad;
            acum_origen.monto = acum_origen.monto + item_origen.monto;
          } else {
            resumen_periodo.origenes.push(item_origen);
          }
        } else resumen_periodo.origenes = [item_origen];
      } else resumen_periodo.total_pendiente = resumen_periodo.total_pendiente + datos[i].total_dte;
    } else {
      let nuevo_resumen = new Object();
      nuevo_resumen.count = 0;
      nuevo_resumen.periodo = datos[i].periodo;
      nuevo_resumen.total_dtes = datos[i].total_dte;
      nuevo_resumen.cantidad_pagos = datos[i].qtty;
      nuevo_resumen.total_pagos = datos[i].total_pago;
      nuevo_resumen.total_pendiente = 0;
      let current_origen = datos[i].origen;
      if (current_origen) {
        let item_origen = { origen: datos[i].origen, cantidad: datos[i].qtty, monto: datos[i].total_pago };
        nuevo_resumen.count = 1;
        nuevo_resumen.origenes = [item_origen];
      } else {
        nuevo_resumen.total_pendiente = datos[i].total_dte;
        nuevo_resumen.origenes = [];
      }
      respuesta.push(nuevo_resumen);
    }
  }
  respuesta.sort();
  respuesta.reverse();
  return respuesta;
}

export default ventaPagosSlice.reducer;
