import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Navbar, Breadcrumb, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import PeriodoDropDown from '../../componentes/PeriodoDropDown';
import RangoPeriodos, { rangos } from '../../componentes/RangoPeriodos';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { pintaPeriodo, pintaPesos, pintaNumero } from '../../libreria/utilidades';
import { getVenta } from '../../redux/ventaSlice';
import { selectPeriodo } from '../../redux/periodoSlice';
import { getVentaPagos } from '../../redux/ventaPagosSlice';
import GraficoVentaPagos from './GraficoVentaPagos';
import './PanelVentaEnergia.css';

function PanelVentaEnergia() {
  const comunidad = useSelector((store) => store.comunidad);
  const periodo = useSelector((store) => store.periodo);
  const venta = useSelector((store) => store.venta);
  const ventaPagos = useSelector((store) => store.ventaPagos);
  const [selectedRango, setSelectedRango] = useState(0);
  const { promiseInProgress: ventaInProgress } = usePromiseTracker({ area: 'area-venta', delay: 0 });
  const { promiseInProgress: ventaPagosInProgress } = usePromiseTracker({ area: 'area-ventaPagos', delay: 0 });
  const dispatch = useDispatch();

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getVenta(comunidad.selected.id, periodo.anno, periodo.mes)), 'area-venta');
      trackPromise(dispatch(getVentaPagos(comunidad.selected.id, rangos[selectedRango])), 'area-ventaPagos');
    };

    onRender();
  }, [comunidad.selected, periodo]);

  function handlePeriodoClick(data) {
    dispatch(selectPeriodo(data));
  }

  function handleSelectRangoPeriodo(eventKey, event) {
    setSelectedRango(eventKey);
    trackPromise(dispatch(getVentaPagos(comunidad.selected.id, rangos[eventKey])), 'area-ventaPagos');
  }

  function toNumber(valor) {
    return valor ? (typeof valor === 'string' ? parseInt(valor) : valor) : 0;
  }

  function pintaOrigen(origen) {
    switch (origen) {
      case 'SERVIPAG_ONLINE':
        return 'Servipag';
      case 'SERVIPAG_REPORTE':
        return 'Servipag (Rep.)';
      case 'MANUAL':
        return 'Pago manual';
      case 'SERVIPAG_MULTICANAL':
        return 'Servipag (MC.)';
      case 'SERVIPAG_MULTICANAL_REPORTE':
        return 'Servipag (MC Rep.)';
      default:
        return '[No definido]';
    }
  }

  return (
    <>
      <Container fluid className='PanelVentaEnergia d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Compra y venta de energía
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Venta de energía</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <p className='m-0 me-2 d-none d-sm-inline text-nowrap'>Período (mes/año):</p>
          <PeriodoDropDown className='me-3' onSelect={handlePeriodoClick} value={pintaPeriodo(periodo)} />
        </Navbar>
      </Container>
      <Container fluid className='PanelVentaEnergia'>
        <Row className='p-2'>
          <Col xs={12} lg={8} xxl={6} className='p-2'>
            <LoaderSpinner isLoading={ventaInProgress}>
              <Card className='shadow-sm'>
                <Card.Body>
                  <Card.Title className='fs-4 fw-bold text-center mb-2'>
                    Detalle venta período: <i className='fst-normal fw-normal'>{pintaPeriodo(periodo)}</i>
                  </Card.Title>
                  <Table responsive size='sm' className='fs-6 m-0'>
                    <thead className='bg-light'>
                      <tr>
                        <td></td>
                        <th className='text-center'>Cantidad</th>
                        <th className='text-center'>Monto neto</th>
                        <th className='text-center'>Monto exento</th>
                        <th className='text-center'>IVA</th>
                        <th className='text-center'>Saldo anterior</th>
                        <th className='text-center'>Monto total</th>
                      </tr>
                    </thead>
                    <tbody className='text-end'>
                      <tr>
                        <td className='text-start text-nowrap fw-bold bgc-gris'>Documentos emitidos</td>
                        <td>{pintaNumero(venta.qtty_dtes)}</td>
                        <td className='text-nowrap'>{pintaPesos(venta.boletas_afecto + venta.facturas_afecto)}</td>
                        <td className='text-nowrap'>{pintaPesos(venta.boletas_exento + venta.facturas_exento)}</td>
                        <td className='text-nowrap'>{pintaPesos(venta.boletas_iva + venta.facturas_iva)}</td>
                        <td className='text-nowrap'>{pintaPesos(venta.boletas_saldo_anterior + venta.facturas_saldo_anterior)}</td>
                        <td className='text-nowrap fw-bold'>{pintaPesos(venta.total_boletas + venta.total_facturas)}</td>
                      </tr>
                      <tr>
                        <td className='text-start text-nowrap fw-bold bgc-gris'>Pagos recibidos</td>
                        <td>{pintaNumero(venta.qtty_pagos)}</td>
                        <td colSpan='5' className='text-nowrap fw-bold'>
                          {pintaPesos(venta.total_pagos)}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-start text-nowrap fw-bold bgc-gris'>Pendiente de pago</td>
                        <td colSpan='6' className='text-nowrap fw-bold warning-number'>
                          {pintaPesos(toNumber(venta.total_boletas + venta.total_facturas) - toNumber(venta.total_pagos))}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </LoaderSpinner>
          </Col>
          <Col xs={12} lg={10} xl={9} xxl={8} className='p-2'>
            <LoaderSpinner isLoading={ventaPagosInProgress}>
              <Card className='shadow-sm'>
                <Card.Header className='bg-light d-flex flex-wrap p-0'>
                  <Navbar className='flex-fill'>
                    <Card.Title className='fs-4 m-0 ms-3 fw-bold'>Histórico totales documentos y pagos</Card.Title>
                  </Navbar>
                  <Navbar className='flex-fill justify-content-end'>
                    <p className='d-none d-lg-inline m-0 p-0'>Rango períodos:</p>
                    <RangoPeriodos onSelect={handleSelectRangoPeriodo} selected={selectedRango} />
                  </Navbar>
                </Card.Header>
                <Card.Body className='py-2'>
                  <GraficoVentaPagos rango={selectedRango} style={{ maxHeight: '11rem', maxWidth: '680px' }} className='mb-3' />
                  <Table hover responsive size='sm' className='h6'>
                    <thead className='bgc-gris'>
                      <tr className='text-center border-white'>
                        <th rowSpan='2' className='border-end border-white'>
                          Período
                        </th>
                        <th rowSpan='2' className='border-end border-white'>
                          Total DTEs
                        </th>
                        <th colSpan='3' className='border-end border-white'>
                          Pagos
                        </th>
                        <th colSpan='2' className='border-end border-white'>
                          Total pagos
                        </th>
                        <th rowSpan='2'>Pendientes de pago</th>
                      </tr>
                      <tr className='text-center'>
                        <td className='border-end border-white'>Origen</td>
                        <td className='border-end border-white'>Cant.</td>
                        <td className='border-end border-white'>Monto</td>
                        <td className='border-end border-white'>Cantidad</td>
                        <td className='border-end border-white'>Monto</td>
                      </tr>
                    </thead>
                    <tbody>
                      {ventaPagos.rows.map((item, index) => (
                        <tr key={index}>
                          <td className='text-nowrap'>{item.periodo}</td>
                          <td className='text-end text-nowrap fw-bold'>{pintaPesos(item.total_dtes)}</td>
                          <td colSpan='3' className='p-0'>
                            <Table responsive size='sm' borderless className='m-0'>
                              <tbody>
                                {item.origenes.map((origen, ix) => (
                                  <tr key={ix} className='text-small'>
                                    <td className='text-nowrap ps-2 ancho-45'>{pintaOrigen(origen.origen)}</td>
                                    <td className='text-end text-nowrap ancho-22'>{pintaNumero(origen.cantidad)}</td>
                                    <td className='text-end text-nowrap'>{pintaPesos(origen.monto)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                          <td className='text-end text-nowrap'>{pintaNumero(item.cantidad_pagos)}</td>
                          <td className='text-end text-nowrap fw-bold'>{pintaPesos(item.total_pagos)}</td>
                          <td className='text-end text-nowrap warning-number fw-bold'>{pintaPesos(item.total_pendiente)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PanelVentaEnergia;
