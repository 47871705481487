import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const balanceUltimoAnnoSlice = createSlice({
  name: 'balanceUltimoAnno',
  initialState: {
    energias: [],
    totalEnergia: 0.0,
    totalConsumo: 0.0,
    totalPerdida: 0.0,
    totalPorcentajePerdida: 0.0
  },
  reducers: {
    setBalanceUltimoAnno: (state, action) => {
      state.energias = action.payload.energias;
      state.totalEnergia = action.payload.totalEnergia;
      state.totalConsumo = action.payload.totalConsumo;
      state.totalPerdida = action.payload.totalPerdida;
      state.totalPorcentajePerdida = action.payload.totalPorcentajePerdida;
    }
  }
});

export const { setBalanceUltimoAnno } = balanceUltimoAnnoSlice.actions;

// Action Creators
export const getBalanceUltimoAnno = (comunidadId, inicio, fin) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:balanceUltimoAnno&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setBalanceUltimoAnno(item));
  } else {
    try {
      const response = await API.get('ePlat', `/balance-ultimo-anno?comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
      dispatch(setBalanceUltimoAnno(response));
      LocalCache.put(`KEY:balanceUltimoAnno&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`, response);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getBalanceUltimoAnno()', error);
    }
  }
};

export default balanceUltimoAnnoSlice.reducer;
