import React from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { usePromiseTracker } from 'react-promise-tracker';
import { pintaPeriodo } from '../../libreria/utilidades';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './ProcessStatus.css';

function ProcessStatus(props) {
  const statePeriodo = useSelector((store) => store.periodo);
  const stateReporte = useSelector((store) => store.reporteCierre);
  const { promiseInProgress: cierreInProgress } = usePromiseTracker({ area: 'area-cierre', delay: 0 });

  function pintaEstadoProceso(reporte) {
    if (reporte) {
      switch (reporte.status) {
        case 'NO_INICIADO':
          return 'No iniciado';
        case 'PARCIAL':
          return 'Iniciado, no completado';
        case 'COMPLETADO':
          return 'Completado, no cerrado';
        case 'FINALIZADO':
          return 'Finalizado y cerrado';
        default:
          return null;
      }
    } else return 'No iniciado';
  }

  function isReporteIniciado() {
    return stateReporte.reporte ? (stateReporte.reporte.status !== 'NO_INICIADO' ? true : false) : false;
  }

  function isButtonAvailable() {
    let future = moment().add(2, 'months').endOf('month');
    let selectedPeriod = moment({ year: statePeriodo.anno, month: statePeriodo.mes - 1 }).endOf('month');
    if (selectedPeriod.isAfter(future)) return false;
    else if (statePeriodo.anno <= 2021 && statePeriodo.mes <= 9) return false;
    else if (stateReporte.reporte) return stateReporte.reporte.status !== 'FINALIZADO';
    else return true;
  }

  function handleActualizarClick(event) {
    event.preventDefault();
  }

  return (
    <>
      <Card {...props} className={`ProcessStatus ${props.className} shadow-sm`} onClick={handleActualizarClick}>
        <LoaderSpinner isLoading={cierreInProgress}>
          <Card.Header className='bg-white'>
            <Card.Title className='fw-bold m-0'>
              Reporte proceso cierre:
              <i className={`ms-2 fst-normal ${isReporteIniciado() ? 'text-info' : 'text-muted'}`}>Período {pintaPeriodo(statePeriodo)}</i>
            </Card.Title>
          </Card.Header>
          <Card.Body className='p-2'>
            <Table borderless size='sm' className='text-small m-0'>
              <tbody>
                <tr>
                  <td style={{ width: '30%' }}>Estado del Proceso:</td>
                  <td className={`fw-bold ${isReporteIniciado() ? 'text-info' : 'text-muted'}`}>{pintaEstadoProceso(stateReporte.reporte)}</td>
                  {isButtonAvailable() ? (
                    <td className='text-end p-0'>
                      <Button size='sm' className='fs-6 btn-outline-secondary text-nowrap btn-actualizar' variant='white' onClick={props.onClick}>
                        <ReplayRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-1' />
                        Actualizar
                      </Button>
                    </td>
                  ) : null}
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </LoaderSpinner>
      </Card>
    </>
  );
}

export default ProcessStatus;
