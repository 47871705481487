import React from 'react';
import { Carousel, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import './FotosCarousel.css';

function FotosCarousel(props) {
  const fotos = useSelector((store) => store.comunidadFotosCarousel.fotos);

  return (
    <Carousel {...props} className={`FotosCarousel ${props.className}`}>
      {fotos.map((foto, index) => (
        <Carousel.Item key={index} interval='10000'>
          <Image fluid className='d-block w-100 estilo-fotos' src={foto} alt='First slide' />
          <Carousel.Caption>
            <h3>{props.caption}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default FotosCarousel;
