const LARGO_REPOSITORIO = 100;
let MAX_PRIORITARIO = -1;
let MIN_PRIORITARIO = 0;

export const LocalCache = {
  datos: [],
  getNewPosition() {
    MAX_PRIORITARIO = MAX_PRIORITARIO + 1;
    if (MAX_PRIORITARIO >= LARGO_REPOSITORIO) MAX_PRIORITARIO = 0;
    MIN_PRIORITARIO = MAX_PRIORITARIO + 1;
    if (MIN_PRIORITARIO >= LARGO_REPOSITORIO) MIN_PRIORITARIO = 0;
    return MAX_PRIORITARIO;
  },
  calibrate(index) {
    let temporaryKey = this.datos[index];
    let numeroIteraciones = MAX_PRIORITARIO > index ? MAX_PRIORITARIO - index : LARGO_REPOSITORIO + MAX_PRIORITARIO - index;
    for (let i = 0; i < numeroIteraciones; i++) {
      let iDestino = index + i >= LARGO_REPOSITORIO ? index + i - LARGO_REPOSITORIO : index + i;
      let iOrigen = iDestino + 1 >= LARGO_REPOSITORIO ? 0 : iDestino + 1;
      this.datos[iDestino] = this.datos[iOrigen];
    }
    this.datos[MAX_PRIORITARIO] = temporaryKey;
  },
  isThere(key) {
    let index = this.datos.findIndex((dato) => dato === key);
    if (index >= 0) {
      if (index !== MAX_PRIORITARIO) this.calibrate(index);
      return true;
    } else return false;
  },
  put(key, value) {
    if (this.isThere(key)) {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      let position = this.getNewPosition();
      if (this.datos.length <= position) {
        this.datos.push(key);
        sessionStorage.setItem(key, JSON.stringify(value));
      } else {
        let previousKey = this.datos[position];
        sessionStorage.removeItem(previousKey);
        this.datos[position] = key;
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    }
  },
  get(key) {
    if (this.isThere(key)) {
      return JSON.parse(sessionStorage.getItem(key));
    } else return null;
  },
  print() {
    console.log(`MAX: ${MAX_PRIORITARIO}, MIN: ${MIN_PRIORITARIO}, datos: ${JSON.stringify(this.datos)}`);
  },
  clear() {
    MAX_PRIORITARIO = -1;
    MIN_PRIORITARIO = 0;
    this.datos = [];
    sessionStorage.clear();
  }
};
