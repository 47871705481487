import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Navbar, Breadcrumb, Table, Image, Row, Col, Card, Button } from 'react-bootstrap';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { useDispatch, useSelector } from 'react-redux';
import { getCuentaCorriente } from '../../redux/cuentaCorrienteSlice';
import { pintaFechaHora, pintaOrigenPago } from '../../libreria/utilidades';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function DescargaCuentaCorriente() {
  const { codServicio } = useParams();
  const comunidad = useSelector((store) => store.comunidad);
  const cuentaCorriente = useSelector((store) => store.cuentaCorriente);
  const dispatch = useDispatch();
  const { promiseInProgress: descargaCuentaCorrienteInProgress } = usePromiseTracker({ area: 'descarga-cuenta-corriente', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getCuentaCorriente(comunidad.selected.id, codServicio)), 'cuenta-corriente');
    };

    onRender();
  }, [comunidad.selected, codServicio]);

  function toNumber(valor) {
    return valor ? (typeof valor === 'string' ? parseInt(valor) : valor) : 0;
  }

  function calcMontoPeriodo(item) {
    return parseInt(item.monto) - (item.interes ? item.interes : 0) + (item.cargo ? item.cargo : 0) - item.saldo_anterior;
  }

  function calcSaldoPeriodo(item) {
    return 0 - parseInt(item.monto) + item.monto_pago;
  }

  function handleClickDescarga() {
    const fileName = `ePlat-Com.${comunidad.selected.id}-CuentaCorriente-${codServicio}.xlsx`;
    let data = [];
    for (let i = 0; i < cuentaCorriente.transacciones.length; i++) {
      let tipo_trx = cuentaCorriente.transacciones[i].tipo_transaccion;
      let row = new Object();
      row.comunidad_id = comunidad.selected.id;
      row.codido_servicio = codServicio;
      row.cliente = cuentaCorriente.data.cliente;
      row.departamento = cuentaCorriente.data.departamento;
      row.medidor = cuentaCorriente.data.medidor;
      row.tipo_tarifa = cuentaCorriente.data.tipo_tarifa;
      row.fecha_transaccion = pintaFechaHora(cuentaCorriente.transacciones[i].fecha_transaccion);
      row.tipo_transaccion = tipo_trx;
      row.folio_boleta = cuentaCorriente.transacciones[i].folio;
      row.periodo = cuentaCorriente.transacciones[i].periodo;
      row.fecha_inicio_lectura = pintaFechaHora(cuentaCorriente.transacciones[i].fecha_inicio_lectura);
      row.fecha_fin_lectura = pintaFechaHora(cuentaCorriente.transacciones[i].fecha_fin_lectura);
      row.monto_periodo = tipo_trx === 'facturacion' ? toNumber(-1 * calcMontoPeriodo(cuentaCorriente.transacciones[i])) : '';
      row.interes = tipo_trx === 'facturacion' ? -1 * toNumber(cuentaCorriente.transacciones[i].interes) : '';
      row.cargo = tipo_trx === 'facturacion' ? -1 * toNumber(cuentaCorriente.transacciones[i].cargo) : '';
      row.saldo_anterior = tipo_trx === 'facturacion' ? -1 * toNumber(cuentaCorriente.transacciones[i].saldo_anterior) : '';
      row.total_facturado = tipo_trx === 'facturacion' ? -1 * toNumber(cuentaCorriente.transacciones[i].monto) : '';
      row.monto_pagado = tipo_trx === 'pago' ? toNumber(cuentaCorriente.transacciones[i].monto_pago) : '';
      row.saldo_periodo = calcSaldoPeriodo(cuentaCorriente.transacciones[i]);
      data.push(row);
    }
    let header = [
      'comunidad',
      'codigo_servicio',
      'cliente',
      'departamento',
      'medidor',
      'tipo_tarifa',
      'fecha_transaccion',
      'tipo_transaccion',
      'folio_boleta',
      'periodo',
      'fecha_inicio_lectura',
      'fecha_fin_lectura',
      'monto_periodo',
      'interes',
      'cargo',
      'saldo_anterior',
      'total_facturado',
      'monto_pagado',
      'saldo_periodo'
    ];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
    const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(finalData, fileName);
  }

  return (
    <>
      <Container fluid className='PanelPagos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/pago' className='text-decoration-none'>
                  Pagos
                </Link>
              </li>
              <Breadcrumb.Item active>Descarga cuenta corriente servicio {codServicio}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid>
        <Row className='p-2'>
          <Col xs={12} md={12} className='p-2'>
            <Card className='shadow-sm' style={{ maxWidth: '540px' }}>
              <LoaderSpinner isLoading={descargaCuentaCorrienteInProgress}>
                <Card.Body>
                  <Table borderless className='m-0'>
                    <tbody>
                      <tr className='text-center align-middle'>
                        <td>
                          <Image fluid src={`${process.env.PUBLIC_URL}/images/success-icon.png`} style={{ minWidth: '40px' }} />
                        </td>
                        <td className='p-0'>
                          <Table borderless className='m-0'>
                            <tbody>
                              <tr>
                                <td>
                                  <Card.Title className='fs-3 fw-normal text-dark'>Se ha generado el reporte de cuenta corriente para el código de servicio: {codServicio}</Card.Title>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Button size='sm' className='fs-6 btn-outline-secondary text-nowrap btn-actualizar' variant='white' onClick={handleClickDescarga}>
                                    <DownloadRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />
                                    Descargar
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DescargaCuentaCorriente;
