import React, { useEffect } from 'react';
import { Container, Navbar, Breadcrumb, Row, Col, Card, ListGroup, Table, FormControl } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartamento } from '../../redux/departamentoSlice';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { pintaFecha, pintaNumero, pintaPesos } from '../../libreria/utilidades';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import CardPlaceHolder from '../../componentes/CardPlaceHolder';
import SearchIcon from '@mui/icons-material/Search';
import WarningMessage from '../../componentes/WarningMessage';
import NumberFormat from 'react-number-format';
import './Departamento.css';

function Departamento() {
  const { numDepto } = useParams();
  const comunidad = useSelector((store) => store.comunidad);
  const numDeptos = comunidad.selected.numDeptos;
  const departamento = useSelector((store) => store.departamento);
  const { datos } = departamento;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { promiseInProgress: departamentoInProgress } = usePromiseTracker({ area: 'departamento', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getDepartamento(comunidad.selected.id, numDepto)), 'departamento');
    };

    onRender();
  }, [comunidad.selected, numDepto]);

  const direccion = () => (datos ? JSON.parse(datos.direccion) : null);
  const direccionLarga = () => (datos ? `${direccion().direccion} - ${datos.numero}` : null);

  function handleMedidorChange(event, newValue) {
    if (newValue) {
      let numDepto = numDeptos.find((item) => item === newValue);
      if (numDepto) {
        navigate(`/comunidad/departamento/${numDepto}`);
      }
    }
  }

  return (
    <>
      <Container fluid className='Departamento d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-md-inline text-secondary'>
                Gestión de la comunidad
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/comunidad' className='text-decoration-none'>
                  Comunidad
                </Link>
              </li>
              <Breadcrumb.Item active>Número departamento {pintaNumero(numDepto)}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='PanelComunidad d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <Autocomplete
            className='autocomplete bg-white'
            sx={{ width: 200 }}
            size='small'
            options={numDeptos}
            onChange={handleMedidorChange}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <FormControl {...params.inputProps} className='fst-normal bg-white' size='sm' placeholder='Numero depto.' />
              </div>
            )}
          />
        </Navbar>
      </Container>
      <Container fluid className='Departamento'>
        <Row className='p-2'>
          <Col xs={12} md={6} lg={5} xxl={4} className='p-2'>
            <LoaderSpinner isLoading={departamentoInProgress}>
              {datos ? (
                <Card className='shadow-sm'>
                  <Card.Header>
                    <Card.Title className='m-1 fs-4 fw-bold text-center'>Departamento: {numDepto}</Card.Title>
                  </Card.Header>
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      <Card.Text className='fs-6 fw-bold m-0'>
                        Comunidad: <i className='fst-normal color-gris'>{comunidad.selected.nombre}</i>
                      </Card.Text>
                      <Card.Text className='fs-6 fw-bold m-0'>
                        Piso: <i className='fst-normal color-gris'>{datos.torre ? datos.torre + ' - ' + datos.piso : datos.piso}</i>
                      </Card.Text>
                      <Card.Text className='fs-6 fw-bold m-0'>
                        Dirección: <i className='fst-normal color-gris'>{direccionLarga()}</i>
                      </Card.Text>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Table borderless responsive className='fs-6 m-0' size='sm'>
                        <tbody>
                          <tr>
                            <td className='px-0 py-1 fw-bold text-nowrap'>Código de servicio:</td>
                            <td className='ps-2 pe-0 pt-0 fw-bold py-1'>
                              <NumberFormat value={datos.codigo_servicio} decimalSeparator={','} thousandSeparator={'.'} displayType='input' className='input-cod-servicio' disabled />
                            </td>
                          </tr>
                          <tr>
                            <td className='p-0 pb-1 fw-bold align-middle text-nowrap'>Cliente:</td>
                            <td className='ps-2 pe-0 pt-0 pb-1 text-nowrap'>
                              <FormControl className='fs-6 bg-white' size='sm' value={datos.business_partner} disabled />
                            </td>
                          </tr>
                          <tr>
                            <td className='p-0 pb-1 fw-bold align-middle text-nowrap'>Tipo tarifa:</td>
                            <td className='ps-2 pe-0 pt-0 pb-1 text-nowrap'>
                              <FormControl className='fs-6 bg-white' size='sm' value={datos.tipo_tarifa} disabled />
                            </td>
                          </tr>
                          <tr>
                            <td className='p-0 pt-1 pb-1 fw-bold align-middle text-nowrap'>Medidor:</td>
                            <td className='ps-2 pe-0 pt-1 pb-1 text-nowrap'>
                              <Link className='fw-bold text-decoration' to={'/medidor/' + datos.medidor}>
                                {datos.medidor}
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </ListGroup.Item>
                    <ListGroup.Item className='bg-light'>
                      <Card.Title className='m-0 fs-5 fw-bold'>Documentos</Card.Title>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Table borderless responsive className='fs-6 m-0' size='sm'>
                        <tbody>
                          <tr className='align-middle'>
                            <td className='px-0 py-1 fw-bold text-nowrap' style={{ width: '40%' }}>
                              Último documento (folio):
                            </td>
                            <td className='ps-2 pe-0 fw-bold py-1 text-nowrap text-primary'>
                              <Link className='text-decoration' to={'/boleta/' + datos.folio}>
                                {pintaNumero(datos.folio)}
                              </Link>
                            </td>
                            <td className='px-0 ps-2 py-1 fw-bold text-nowrap text-end'>Período:</td>
                            <td className='ps-2 pe-0 py-1 text-nowrap'>{datos.periodo}</td>
                          </tr>
                          <tr>
                            <td className='px-0 py-1 text-nowrap'>Monto total documento:</td>
                            <td colSpan='3' className='ps-2 pe-0 py-1 text-nowrap '>
                              {pintaPesos(datos.monto)}
                            </td>
                          </tr>
                          <tr>
                            <td className='px-0 py-1 text-nowrap'>Fecha de vencimiento:</td>
                            <td colSpan='3' className='ps-2 pe-0 py-1 text-nowrap '>
                              {pintaFecha(datos.fecha_vencimiento)}
                            </td>
                          </tr>
                          <tr>
                            <td className='px-0 py-1 align-middle text-nowrap'>Última lectura:</td>
                            <td colSpan='3' className='ps-2 pe-0'>
                              {datos.lectura_periodo} [kWh]
                            </td>
                          </tr>
                          <tr>
                            <td className='p-0 pb-1 align-middle text-nowrap'>Último consumo:</td>
                            <td colSpan='3' className='ps-2 pe-0'>
                              {datos.consumo_periodo} [kWh]
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </ListGroup.Item>
                  </ListGroup>
                  <Card.Footer className='bg-white'>
                    <Table borderless responsive className='fs-6 m-0 mt-1' size='sm'>
                      <tbody>
                        <tr>
                          <td colSpan='2' className='p-0 py-1 fw-bold align-middle text-nowrap'>
                            <Link to={`/documento/cod-servicio/${datos.codigo_servicio}`} className='link-primary text-decoration-none'>
                              <button className='me-2 button-link'>...</button>
                              Ver documentos
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan='2' className='p-0 py-1 fw-bold align-middle text-nowrap'>
                            <Link to={`/pago/cta-cte/${datos.codigo_servicio}`} className='link-primary text-decoration-none'>
                              <button className='me-2 button-link'>...</button>
                              Ver cuenta corriente
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Footer>
                </Card>
              ) : departamentoInProgress ? (
                <CardPlaceHolder />
              ) : (
                <WarningMessage>No se ha encontrado el departamento: {numDepto}.</WarningMessage>
              )}
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Departamento;
