import React, { useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import './SearchById.css';

function SearchById(props) {
  const [text, setText] = useState('');

  function validateSearch() {
    return text.length > 0;
  }

  function handleClick(event) {
    event.preventDefault();
  }

  function search() {
    props.onClick(text);
    setText('');
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' && validateSearch()) search();
  }

  function handleChange(event) {
    let valor = Number(event.target.value);
    if (!isNaN(valor)) {
      if (valor === 0) setText('');
      else setText(valor.toString());
    }
  }

  return (
    <InputGroup {...props} className={`SearchById ${props.className} flex-nowrap`} size='sm' onClick={handleClick}>
      <FormControl placeholder={props.placeholder} aria-label='Buscar boleta' aria-describedby='buscar-boleta' onChange={handleChange} value={text} onKeyDown={handleKeyDown} className='text-end' />
      <Button variant='outline-secondary' id='buscar-boleta' disabled={!validateSearch()} onClick={() => search()}>
        <i className='fst-normal d-none d-sm-inline'>{props.btntext}</i>
        <i className='fst-normal d-sm-none'>...</i>
      </Button>
    </InputGroup>
  );
}

export default SearchById;
