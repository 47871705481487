import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const cuentaCorrienteSlice = createSlice({
  name: 'cuentaCorriente',
  initialState: {
    data: null,
    transacciones: [],
    totales: null
  },
  reducers: {
    setCuentaCorriente: (state, action) => {
      state.data = action.payload.data;
      state.transacciones = action.payload.transacciones;
      state.totales = action.payload.totales;
    }
  }
});

export const { setCuentaCorriente } = cuentaCorrienteSlice.actions;

// Action Creators
export const getCuentaCorriente = (comunidadId, codigo) => async (dispatch) => {
  let item = LocalCache.get(`KEY:cuentaCorriente&comunidad=${comunidadId}&codigo=${codigo}`);
  if (item) {
    dispatch(setCuentaCorriente(item));
  } else {
    try {
      const response = await API.get('ePlat', `/cuenta-corriente?comunidad=${comunidadId}&codigo=${codigo}`);
      let cuentaCorriente = { data: getData(response), transacciones: response, totales: getTotales(response) };
      dispatch(setCuentaCorriente(cuentaCorriente));
      LocalCache.put(`KEY:cuentaCorriente&comunidad=${comunidadId}&codigo=${codigo}`, cuentaCorriente);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getCuentaCorriente()', error);
    }
  }
};

function getData(response) {
  return response.length > 0
    ? {
        cliente: response[0].contacto_cliente,
        departamento: response[0].numero,
        medidor: response[0].medidor_id,
        tipo_tarifa: response[0].tipo_tarifa
      }
    : null;
}

function getTotales(response) {
  if (response.length > 0) {
    let lastPeriodo = '';
    let ctotal = 0;
    let cpago = 0;
    for (let i = 0; i < response.length; i++) {
      if (response[i].tipo_transaccion === 'facturacion') {
        ctotal = parseInt(response[i].monto);
        lastPeriodo = response[i].periodo;
        cpago = 0;
      } else if (response[i].tipo_transaccion === 'pago') {
        if (response[i].periodo === lastPeriodo) cpago = response[i].monto_pago;
      }
    }
    return {
      total: ctotal,
      pago: cpago,
      saldo_total: ctotal - cpago
    };
  } else return null;
}

export default cuentaCorrienteSlice.reducer;
