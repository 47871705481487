import { createSlice } from '@reduxjs/toolkit';
import { s3getPublicURL, s3listPublicURL } from '../libreria/S3Storage';
import { LocalCache } from '../libreria/LocalCache';

export const comunidadFotosCarouselSlice = createSlice({
  name: 'comunidadFotosCarousel',
  initialState: {
    fotos: []
  },
  reducers: {
    setFotos: (state, action) => {
      state.fotos = action.payload;
    }
  }
});

export const { setFotos } = comunidadFotosCarouselSlice.actions;

// Action Creators
export const getFotos = (comunidadId, filepath) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:fotos_carousel&comunidad=${comunidadId}`);
  if (item) {
    dispatch(setFotos(item));
  } else {
    try {
      const response = await s3listPublicURL(filepath);
      let fotos = await getFotosArray(response);
      dispatch(setFotos(fotos));
      LocalCache.put(`KEY:fotos_carousel&comunidad=${comunidadId}`, fotos);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getFotos()', error);
    }
  }
};

async function getFotosArray(data) {
  const fotos = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].size > 0) {
      let dato = await s3getPublicURL(data[i].key);
      fotos.push(dato);
    }
  }
  return fotos;
}

export default comunidadFotosCarouselSlice.reducer;
