import React, { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import CloseButton from 'react-bootstrap/CloseButton';
import Navbar from 'react-bootstrap/Navbar';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import EPlatMenu from './EPlatMenu';
import EPlatFooter from './EPlatFooter';
import ComunidadActual from './ComunidadActual';
import UsuarioConectado from './UsuarioConectado';
import './EPlatSidebar.css';

export default class EPlatSidebar extends React.Component {
  constructor(props) {
    super(props);
    // Bind the method in the constructor instead of binding it in render, so you only do it once.
    this.handleHidden = this.handleHidden.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  componentDidMount() {
    this.ePlatSidebar.addEventListener('hidden.bs.collapse', this.handleHidden);
    this.ePlatSidebar.addEventListener('show.bs.collapse', this.handleShow);
  }

  componentWillUnmount() {
    this.ePlatSidebar.removeEventListener('hidden.bs.collapse', this.handleHidden);
    this.ePlatSidebar.removeEventListener('show.bs.collapse', this.handleShow);
  }

  handleHidden(event) {
    if (event.target.id === 'eplat-sidebar') {
      this.ePlatFloatButton.style.visibility = 'visible';
    }
  }

  handleShow(event) {
    if (event.target.id === 'eplat-sidebar') {
      this.ePlatFloatButton.style.visibility = 'hidden';
    }
  }

  render() {
    return (
      <Fragment>
        <div
          className='EPlatSidebar border-end flex-shrink-0 overflow-auto shadow collapse collapse-horizontal show'
          id='eplat-sidebar'
          ref={(node) => {
            this.ePlatSidebar = node;
          }}
        >
          <div className='altura-restante d-flex flex-column'>
            <Navbar variant='light' className='altura-navbar m-0 border-bottom'>
              <Container fluid className='p-0 mx-2 d-flex flex-nowrap align-items-start'>
                <ComunidadActual />
                <CloseButton className='ms-auto h6' data-bs-toggle='collapse' data-bs-target='#eplat-sidebar' aria-expanded='true' aria-controls='eplat-sidebar' />
              </Container>
            </Navbar>
            <UsuarioConectado />
            <EPlatMenu />
            <EPlatFooter />
          </div>
        </div>
        <div className='EPlatFloatButton'>
          <button
            type='button'
            className='btn p-0 m-0 float-button shadow-sm'
            data-bs-toggle='collapse'
            data-bs-target='#eplat-sidebar'
            aria-expanded='true'
            aria-controls='eplat-sidebar'
            id='boton-show'
            ref={(node) => {
              this.ePlatFloatButton = node;
            }}
          >
            <MenuRoundedIcon className='icono-centrado' />
          </button>
        </div>
      </Fragment>
    );
  }
}
