import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const compraSlice = createSlice({
  name: 'compra',
  initialState: {
    suministro: null,
    distribucion: null
  },
  reducers: {
    setCompra: (state, action) => {
      let { payload } = action;
      state.suministro = payload.suministro;
      state.distribucion = payload.distribucion;
    }
  }
});

export const { setCompra } = compraSlice.actions;

// Action Creators
export const getCompra = (comunidadId, anno, mes) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:compra&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
  if (item) {
    dispatch(setCompra(item));
  } else {
    try {
      const response = await API.get('ePlat', `/compra?comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
      const compra = {
        suministro: response.suministro,
        distribucion: response.distribucion
      };
      dispatch(setCompra(compra));
      LocalCache.put(`KEY:compra&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`, compra);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getCompra()', error);
    }
  }
};

export default compraSlice.reducer;
