import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { useEPlatContext } from '../libreria/EPlatContext';
import './UsuarioConectado.css';

function UsuarioConectado() {
  const { userSession, avatar } = useEPlatContext();
  const { attributes } = userSession;
  const [nombre, setNombre] = useState('');
  const [perfil, setPerfil] = useState('');

  useEffect(() => {
    const onRender = () => {
      setNombre(attributes.name ? attributes.name : '[Nombre usuario]');
      setPerfil(attributes.profile ? attributes.profile : '[Perfil usuario]');
    };

    onRender();
  }, [attributes]);

  function getAvatar() {
    return avatar ? avatar : `${process.env.PUBLIC_URL}/images/avatar-placeholder.png`;
  }

  return (
    <Container className='UsuarioConectado d-flex flex-column border-bottom align-items-center p-2'>
      <Image src={getAvatar()} className='size-imagen mt-1 mb-2' roundedCircle />
      <p className='mb-0 ancho-texto text-truncate fw-bold'>{nombre}</p>
      <h6 className='mb-1 text-primary ancho-texto text-truncate'>{attributes.email}</h6>
      <h5 className='m-0'>{perfil}</h5>
    </Container>
  );
}

export default UsuarioConectado;
