import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function PanelCambioClave() {
  return (
    <Container fluid>
      <Row className='p-2'>
        <Col xs={12} lg={6} className='p-2'>
          <Card className='shadow-sm'>
            <Card.Header className='h5 fw-bold text-center'>Cambio de Contraseña</Card.Header>
            <Card.Body>
              <Card.Title className='fw-bold text-center'>Funcionalidad de cambio de contraseña no desplegada</Card.Title>
              <Card.Text>Funcionalidad que permite cambiar la contraseña al usuario conectado con el rol de Administrador.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default PanelCambioClave;
