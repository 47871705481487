import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Navbar, Breadcrumb, FormControl } from 'react-bootstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { pintaNumero } from '../../libreria/utilidades';
import { getMedidor } from '../../redux/medidorSlice';
import CardPlaceHolder from '../../componentes/CardPlaceHolder';
import WarningMessage from '../../componentes/WarningMessage';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import SearchIcon from '@mui/icons-material/Search';
import SearchById from '../../componentes/SearchById';
import GraficoLecturasMedidor from './GraficoLecturasMedidor';
import RangoPeriodos, { rangos } from '../../componentes/RangoPeriodos';

import './Medidor.css';

function Medidor() {
  const navigate = useNavigate();
  const comunidad = useSelector((store) => store.comunidad);
  const codigos = comunidad.selected.medidores;
  const medidor = useSelector((store) => store.medidor);
  const { numeroMedidor } = useParams();
  const [selectedRango, setSelectedRango] = useState(0);
  const { promiseInProgress: medidorInProgress } = usePromiseTracker({ area: 'area-medidor', delay: 0 });
  const dispatch = useDispatch();

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getMedidor(comunidad.selected.id, numeroMedidor, rangos[selectedRango])), 'area-medidor');
    };

    onRender();
  }, [comunidad.selected, numeroMedidor]);

  function handleCodigoServicioSearch(value) {
    //navigate(`/documento/cod-servicio/${Number(value)}`);
  }

  function handleNumDeptoSearch(value) {
    //navigate(`/comunidad/departamento/${Number(value)}`);
  }

  function handleSelectRangoPeriodo(eventKey, event) {
    setSelectedRango(eventKey);
    trackPromise(dispatch(getMedidor(comunidad.selected.id, numeroMedidor, rangos[eventKey])), 'area-medidor');
  }

  function handleMedidorChange(event, newValue) {
    if (newValue) {
      let codigoMedidor = codigos.find((item) => item === newValue);
      if (codigoMedidor) {
        navigate(`/medidor/${codigoMedidor}`);
      }
    }
  }

  return (
    <>
      <Container fluid className='Medidor d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/medidor' className='text-decoration-none'>
                  Datos de medidores
                </Link>
              </li>
              <Breadcrumb.Item active>{numeroMedidor}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='Medidor d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <p className='m-0 me-2 d-none d-md-inline text-nowrap'>Medidor:</p>
          <Autocomplete
            className='autocomplete bg-white'
            sx={{ width: 150 }}
            size='small'
            options={codigos}
            onChange={handleMedidorChange}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <FormControl {...params.inputProps} className='fst-normal bg-white' size='sm' placeholder='Código medidor' />
              </div>
            )}
          />
        </Navbar>
      </Container>
      <Container fluid>
        <Row className='p-2'>
          <Col xs={12} md={11} lg={9} xl={8} xxl={6} className='p-2'>
            <LoaderSpinner isLoading={medidorInProgress}>
              {medidor.cabecera ? (
                <Card className='shadow-sm'>
                  <Card.Header className='bg-light d-flex flex-wrap p-0'>
                    <Navbar className='flex-fill'>
                      <Card.Title className='fs-4 m-0 ms-3 fw-bold'>
                        Medidor: <i className='fst-normal fw-normal'>{numeroMedidor}</i>
                      </Card.Title>
                    </Navbar>
                    <Navbar className='flex-fill justify-content-end'>
                      <p className='d-none d-lg-inline m-0 p-0'>Rango períodos:</p>
                      <RangoPeriodos onSelect={handleSelectRangoPeriodo} selected={selectedRango} />
                    </Navbar>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>
                      <Container fluid className='fs-6 d-flex flex-wrap p-0'>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Codigo servicio:</b>
                          <FormControl className='fs-6 bg-white' size='sm' value={medidor.cabecera.codigo_servicio} disabled />
                        </div>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Departamento:</b>
                          <FormControl className='fs-6 bg-white' size='sm' value={medidor.cabecera.numero} disabled />
                        </div>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Tipo tarifa:</b>
                          <FormControl className='fs-6 bg-white' size='sm' value={medidor.cabecera.tipo_tarifa} disabled />
                        </div>
                      </Container>
                    </Card.Title>
                    <GraficoLecturasMedidor rango={selectedRango} style={{ maxHeight: '11rem', maxWidth: '680px' }} className='mb-3' />
                    <Table striped hover responsive size='sm' className='h6'>
                      <thead>
                        <tr className='text-center'>
                          <th>Período</th>
                          <th>Lectura [kWh]</th>
                          <th>Lectura mes anterior [kWh]</th>
                          <th>Consumo [kWh]</th>
                        </tr>
                      </thead>
                      <tbody>
                        {medidor.lecturas.map((lectura) => (
                          <tr key={lectura.periodo}>
                            <td className='text-center'>{lectura.periodo}</td>
                            <td className='text-end'>{pintaNumero(lectura.lectura_actual)}</td>
                            <td className='text-end'>{pintaNumero(lectura.lectura_anterior)}</td>
                            <td className='text-end'>{pintaNumero(lectura.consumo)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              ) : medidorInProgress ? (
                <CardPlaceHolder />
              ) : (
                <WarningMessage>No se han encontrado documentos para el código de servicio: {numeroMedidor}.</WarningMessage>
              )}
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Medidor;
