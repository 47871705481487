import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const rentabilidadSlice = createSlice({
  name: 'rentabilidad',
  initialState: {
    rows: []
  },
  reducers: {
    setRentabilidad: (state, action) => {
      state.rows = action.payload;
    }
  }
});

export const { setRentabilidad } = rentabilidadSlice.actions;

// Action Creators
export const getRentabilidad = (comunidadId, rango) => async (dispatch, getState) => {
  const inicio = rango.inicio;
  const fin = rango.fin;
  let item = LocalCache.get(`KEY:rentabilidad&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setRentabilidad(item));
  } else {
    try {
      const response = await API.get('ePlat', `/rentabilidad?comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
      let rentabilidadArray = rentXPeriodo(response);
      dispatch(setRentabilidad(rentabilidadArray));
      LocalCache.put(`KEY:rentabilidad&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`, rentabilidadArray);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getRentabilidad()', error);
    }
  }
};

function rentXPeriodo(response) {
  let respuesta = [];
  for (let i = 0; i < response.length; i++) {
    let rentPeriodo = new Object();
    rentPeriodo.periodo = response[i].periodo;
    rentPeriodo.neto_venta = response[i].neto_venta;
    rentPeriodo.neto_compra = response[i].neto_compra;
    rentPeriodo.margen = response[i].neto_venta - response[i].neto_compra;
    rentPeriodo.margenP = response[i].neto_venta > 0 ? (rentPeriodo.margenP = (rentPeriodo.margen / response[i].neto_venta) * 100) : 0;
    respuesta.push(rentPeriodo);
  }
  respuesta.reverse();
  return respuesta;
}

export default rentabilidadSlice.reducer;
