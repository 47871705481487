import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const totalesPagosSlice = createSlice({
  name: 'totalesPagos',
  initialState: {
    fecha_vencimiento: null,
    total_facturado: 0,
    qtty: 0,
    total: 0,
    qtty_plazo: 0,
    total_plazo: 0,
    qtty_fuera_plazo: 0,
    total_fuera_plazo: 0
  },
  reducers: {
    setTotalesPagos: (state, action) => {
      state.fecha_vencimiento = action.payload.fecha_vencimiento;
      state.total_facturado = action.payload.total_facturado;
      state.qtty = action.payload.qtty;
      state.total = action.payload.total;
      state.qtty_plazo = action.payload.qtty_plazo;
      state.total_plazo = action.payload.total_plazo;
      state.qtty_fuera_plazo = action.payload.qtty_fuera_plazo;
      state.total_fuera_plazo = action.payload.total_fuera_plazo;
    }
  }
});

export const { setTotalesPagos } = totalesPagosSlice.actions;

// Action Creators
export const getTotalesPagos = (comunidadId, anno, mes) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:totalesPagos&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
  if (item) {
    dispatch(setTotalesPagos(item));
  } else {
    try {
      const response = await API.get('ePlat', `/total-pagos?comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
      let totales = {
        fecha_vencimiento: response.fecha_vencimiento,
        total_facturado: response.total_facturado,
        qtty: response.qtty,
        total: response.total,
        qtty_plazo: response.qtty_plazo,
        total_plazo: response.total_plazo,
        qtty_fuera_plazo: response.qtty_fuera_plazo,
        total_fuera_plazo: response.total_fuera_plazo
      };
      dispatch(setTotalesPagos(totales));
      LocalCache.put(`KEY:totalesPagos&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`, totales);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getTotalesPagos()', error);
    }
  }
};

export default totalesPagosSlice.reducer;
