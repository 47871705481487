let off = {
  MAX_ATTACHMENT_SIZE: 5000000,
  // Backend config
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'eplat-client-images-qa'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://4mxh80k1jd.execute-api.us-east-1.amazonaws.com/qa'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_X9VPViOQO',
    APP_CLIENT_ID: '7tk2fpcsgsggiao8c0klavkeho',
    IDENTITY_POOL_ID: 'us-east-1:4bc95a9c-f156-48cb-bd56-0da5fc8b8c54'
  }
};

let qa = {
  MAX_ATTACHMENT_SIZE: 5000000,
  // Backend config
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'eplat-client-images-qa'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://4mxh80k1jd.execute-api.us-east-1.amazonaws.com/qa'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_X9VPViOQO',
    APP_CLIENT_ID: '7tk2fpcsgsggiao8c0klavkeho',
    IDENTITY_POOL_ID: 'us-east-1:4bc95a9c-f156-48cb-bd56-0da5fc8b8c54'
  }
};

let prod = {
  MAX_ATTACHMENT_SIZE: 5000000,
  // Backend config
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'eplat-client-images-prod'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://qlagsvr3o9.execute-api.us-east-1.amazonaws.com/prod'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_F9GP7IJM4',
    APP_CLIENT_ID: '4cc2s8cqqfop8d50i638a6g0mt',
    IDENTITY_POOL_ID: 'us-east-1:9bddc7b3-fc2e-4922-8d0b-74beed61f4a2'
  }
};

let config = {
  ...(process.env.REACT_APP_STAGE === 'prod' ? prod : process.env.REACT_APP_STAGE === 'qa' ? qa : off)
};

export default config;
