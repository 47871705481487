import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const comprasRangoSlice = createSlice({
  name: 'comprasRango',
  initialState: {
    rows: []
  },
  reducers: {
    setComprasRango: (state, action) => {
      state.rows = action.payload;
    }
  }
});

export const { setComprasRango } = comprasRangoSlice.actions;

// Action Creators
export const getComprasRango = (comunidadId, rango) => async (dispatch, getState) => {
  const inicio = rango.inicio;
  const fin = rango.fin;
  let item = LocalCache.get(`KEY:comprasRango&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setComprasRango(item));
  } else {
    try {
      const response = await API.get('ePlat', `/compras-rango?comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
      let respuesta = response.reverse();
      dispatch(setComprasRango(respuesta));
      LocalCache.put(`KEY:comprasRango&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`, respuesta);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getComprasRango()', error);
    }
  }
};

export default comprasRangoSlice.reducer;
