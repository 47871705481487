import { configureStore } from '@reduxjs/toolkit';
import rentabilidad from './rentabilidadSlice';
import balanceCurvas from './balanceCurvasSlice';
import balanceEnergia from './balanceEnergiaSlice';
import balanceUltimoAnno from './balanceUltimoAnnoSlice';
import compra from './compraSlice';
import comprasRango from './comprasRangoSlice';
import venta from './ventaSlice';
import ventaPagos from './ventaPagosSlice';
import comunidad from './comunidadSlice';
import comunidadIconos from './comunidadIconosSlice';
import comunidadFotosCarousel from './comunidadFotosCarouselSlice';
import unidades from './unidadesSlice';
import departamento from './departamentoSlice';
import periodo from './periodoSlice';
import boleta from './boletaSlice';
import factura from './facturaSlice';
import consumoHistorico from './consumoHistoricoSlice';
import documentos from './documentosSlice';
import documentosFolio from './documentosFolioSlice';
import documentosCodServicio from './documentosCodServicioSlice';
import downloadDocumentos from './downloadDocumentosSlice';
import downloadSabana from './downloadSabanaSlice';
import pagos from './pagosSlice';
import totalesPagos from './totalesPagosSlice';
import pagosPeriodo from './pagosPeriodoSlice';
import cuentaCorriente from './cuentaCorrienteSlice';
import downloadPagos from './downloadPagosSlice';
import downloadSaldos from './downloadSaldosSlice';
import reporteCierre from './reporteCierreSlice';
import documentosCierre from './documentosCierreSlice';
import tarifasCierre from './tarifasCierreSlice';
import medidor from './medidorSlice';
import totalesMedidores from './totalesMedidoresSlice';

export const store = configureStore({
  reducer: {
    rentabilidad,
    balanceCurvas,
    balanceEnergia,
    balanceUltimoAnno,
    compra,
    comprasRango,
    venta,
    ventaPagos,
    comunidad,
    comunidadIconos,
    comunidadFotosCarousel,
    unidades,
    departamento,
    periodo,
    boleta,
    factura,
    consumoHistorico,
    documentos,
    documentosFolio,
    documentosCodServicio,
    downloadDocumentos,
    downloadSabana,
    pagos,
    totalesPagos,
    pagosPeriodo,
    cuentaCorriente,
    downloadPagos,
    downloadSaldos,
    reporteCierre,
    documentosCierre,
    tarifasCierre,
    medidor,
    totalesMedidores
  }
});
