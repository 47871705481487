import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const tarifasCierreSlice = createSlice({
  name: 'tarifasCierre',
  initialState: {
    resumen: null,
    bt1: [],
    bt43: []
  },
  reducers: {
    setTarifasCierre: (state, action) => {
      state.resumen = action.payload.resumen;
      state.bt1 = action.payload.bt1;
      state.bt43 = action.payload.bt43;
    }
  }
});

export const { setTarifasCierre } = tarifasCierreSlice.actions;

// Action Creators
export const getTarifasCierre = (comunidadId, periodo) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:tarifasCierre&comunidad=${comunidadId}&periodo=${periodo}`);
  if (item) {
    dispatch(setTarifasCierre(item));
  } else {
    try {
      const response = await API.get('ePlat', `/get-tarifas-cierre?comunidad=${comunidadId}&periodo=${periodo}`);
      let reporte = getTablaTarifasCierreFromDatos(response);
      dispatch(setTarifasCierre(reporte));
      LocalCache.put(`KEY:tarifasCierre&comunidad=${comunidadId}&periodo=${periodo}`, reporte);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getTarifasCierre()', error);
    }
  }
};

/** Esta funcion construye un reporte separado por tipo de tarifa. */
function getTablaTarifasCierreFromDatos(datos) {
  let bt1 = [];
  let bt43 = [];
  for (let i = 0; i < datos.length; i++) {
    if (datos[i].tipo_tarifa == 0) bt1.push(datos[i]);
    else bt43.push(datos[i]);
  }
  let resumen = datos.length > 0 ? { count: datos.length } : null;
  return { resumen: resumen, bt1: bt1, bt43: bt43 };
}

export default tarifasCierreSlice.reducer;
