import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const reporteCierreSlice = createSlice({
  name: 'reporteCierre',
  initialState: {
    reporte: null
  },
  reducers: {
    setReporteCierre: (state, action) => {
      state.reporte = action.payload;
    }
  }
});

export const { setReporteCierre } = reporteCierreSlice.actions;

// Action Creators
export const getReporteCierre = (comunidadId, periodo) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:reporte-cierre&comunidad=${comunidadId}&periodo=${periodo}`);
  if (item) {
    dispatch(setReporteCierre(item));
  } else {
    try {
      const reporte = await API.get('ePlat', `/get-reporte-cierre?comunidad=${comunidadId}&periodo=${periodo}`);
      dispatch(setReporteCierre(reporte));
      LocalCache.put(`KEY:reporte-cierre&comunidad=${comunidadId}&periodo=${periodo}`, reporte);
    } catch (error) {
      console.log('DVD: ERROR Llamada a obtenerReporte', error);
    }
  }
};

export const updateReporteCierre = (comunidadId, periodo) => async (dispatch, getState) => {
  try {
    const reporte = await API.post('ePlat', '/create-reporte-cierre', { body: { comunidad: comunidadId, periodo: periodo } });
    dispatch(setReporteCierre(reporte));
    LocalCache.put(`KEY:reporte-cierre&comunidad=${comunidadId}&periodo=${periodo}`, reporte);
  } catch (error) {
    console.log('DVD: ERROR Llamada a updateReporteCierre()', error);
  }
};

export default reporteCierreSlice.reducer;
