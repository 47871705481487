import React, { useEffect } from 'react';
import { Container, Table, Row, Col, Card, Navbar, Breadcrumb, FormControl, Dropdown } from 'react-bootstrap';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import SearchById from '../../componentes/SearchById';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCuentaCorriente } from '../../redux/cuentaCorrienteSlice';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { pintaFecha, pintaFechaHora, pintaNumero, pintaPesos } from '../../libreria/utilidades';
import CardPlaceHolder from '../../componentes/CardPlaceHolder';
import SearchIcon from '@mui/icons-material/Search';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import WarningMessage from '../../componentes/WarningMessage';
import moment from 'moment';
import './CuentaCorriente.css';

function CuentaCorriente() {
  const { codServicio } = useParams();
  const comunidad = useSelector((store) => store.comunidad);
  const cuentaCorriente = useSelector((store) => store.cuentaCorriente);
  const { data, transacciones, totales } = cuentaCorriente;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { promiseInProgress: cuentaCorrienteInProgress } = usePromiseTracker({ area: 'cuenta-corriente', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getCuentaCorriente(comunidad.selected.id, codServicio)), 'cuenta-corriente');
    };

    onRender();
  }, [comunidad.selected, codServicio]);

  function pintaTipoTransaccion(tipo) {
    switch (tipo) {
      case 'facturacion':
        return 'Facturación';
      case 'pago':
        return 'Pago';
      default:
        return '[No definido]';
    }
  }

  function handleCtaCteSearch(value) {
    navigate(`/pago/cta-cte/${value}`);
  }

  function calcInteresPlusCargo(item) {
    return (item.interes ? item.interes : 0) + (item.cargo ? item.cargo : 0);
  }

  function calcMontoPeriodo(item) {
    return parseInt(item.monto) - calcInteresPlusCargo(item) - item.saldo_anterior;
  }

  function calcSaldoPeriodo(item) {
    return 0 - parseInt(item.monto) + item.monto_pago;
  }

  function handleReportesOpcion(eventKey) {
    if (eventKey === 'cuenta_corriente') {
      navigate(`/pago/cta-cte/descarga-cuenta-corriente/${codServicio}`);
    }
  }

  return (
    <>
      <Container fluid className='CuentaCorriente d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/pago' className='text-decoration-none'>
                  Pagos
                </Link>
              </li>
              <Breadcrumb.Item active>Cuenta corriente servicio {pintaNumero(codServicio)}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='PanelBoletas d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <p className='m-0 me-2 d-none d-sm-inline text-nowrap'>Cuenta corriente:</p>
          <SearchById className='me-2' placeholder='Código.serv' btntext='Buscar' onClick={handleCtaCteSearch} />
        </Navbar>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <Dropdown onSelect={handleReportesOpcion} className='me-2'>
            <Dropdown.Toggle variant='outline-secondary' size='sm' id='dropdown-cod-servicio'>
              <MenuRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-1' />
              Descarga reportes
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu-end'>
              <Dropdown.Item eventKey='cuenta_corriente'>Cuenta corriente</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </Container>
      <Container fluid className='CuentaCorriente'>
        <Row className='p-2'>
          <Col xs={12} lg={11} xl={10} xxl={9} className='p-2'>
            <LoaderSpinner isLoading={cuentaCorrienteInProgress}>
              {data ? (
                <Card className='shadow-sm'>
                  <Card.Header className='bg-light d-flex flex-wrap p-0'>
                    <Navbar className='flex-fill'>
                      <Card.Title className='fs-4 m-0 ms-3 fw-bold'>
                        Código servicio: <i className='fst-normal fw-normal'>{pintaNumero(codServicio)}</i>
                      </Card.Title>
                    </Navbar>
                    <Navbar className='flex-fill justify-content-end'>
                      <p className='d-none d-lg-inline m-0 me-2 p-0 text-nowrap'>Fecha reporte:</p>
                      <FormControl className='bg-light me-2 ancho-input text-center' size='sm' value={moment().format('DD-MM-YYYY')} disabled />
                    </Navbar>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>
                      <Container fluid className='fs-6 d-flex flex-wrap p-0'>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Cliente:</b>
                          <FormControl className='fs-6 bg-white' size='sm' value={data.cliente} disabled />
                        </div>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Departamento: </b>
                          <FormControl className='fs-6 bg-white' size='sm' value={data.departamento} disabled />
                        </div>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Medidor: </b>
                          <FormControl className='fs-6 bg-white' size='sm' value={data.medidor} disabled />
                        </div>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Tipo tarifa: </b>
                          <FormControl className='fs-6 bg-white' size='sm' value={data.tipo_tarifa} disabled />
                        </div>
                      </Container>
                    </Card.Title>
                    <Table responsive size='sm' className='fs-6 m-0'>
                      <thead className='bgc-gris'>
                        <tr className='text-center border-white'>
                          <th colSpan='3' className='border-end border-white'>
                            Transacción
                          </th>
                          <th colSpan='2' className='border-end border-white'>
                            Período de consumo
                          </th>
                          <th colSpan='4' className='border-end border-white'>
                            Monto facturado
                          </th>
                          <th rowSpan='2' className='border-end border-white'>
                            Monto pagado
                          </th>
                          <th rowSpan='2'>Saldo período</th>
                        </tr>
                        <tr className='text-center'>
                          <td>Fecha-Hora</td>
                          <td>Tipo</td>
                          <td className='border-end border-white'>Folio docto.</td>
                          <td>Período</td>
                          <td className='border-end border-white'>Inicio - Fin</td>
                          <td className='border-end border-white'>Monto período</td>
                          <td className='border-end border-white'>Cargos e intereses</td>
                          <td className='border-end border-white'>Saldo anterior</td>
                          <th className='border-end border-white'>Total facturado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transacciones.map((item, index) => (
                          <tr key={index} className='align-middle'>
                            <td className='text-center text-nowrap'>{pintaFechaHora(item.fecha_transaccion)}</td>
                            <td>{pintaTipoTransaccion(item.tipo_transaccion)}</td>
                            <td className='text-end'>
                              <Link className='text-decoration-none' to={item.tipo_documento === 'BOLETA' ? `/boleta/${item.folio}` : `/factura/${item.folio}`}>
                                {pintaNumero(item.folio)}
                              </Link>
                            </td>
                            <td className='text-nowrap'>{item.periodo}</td>
                            <td className='text-center text-nowrap text-small'>{`${pintaFecha(item.fecha_inicio_lectura)} al ${pintaFecha(item.fecha_fin_lectura)}`}</td>
                            <td className={`text-end text-nowrap${calcMontoPeriodo(item) > 0 ? ' negative-number' : ''}`}>
                              {item.tipo_transaccion === 'facturacion' ? pintaPesos(-1 * calcMontoPeriodo(item)) : ''}
                            </td>
                            <td className={`text-end text-nowrap${calcInteresPlusCargo(item) > 0 ? ' negative-number' : ''}`}>
                              {item.tipo_transaccion === 'facturacion' ? pintaPesos(-1 * calcInteresPlusCargo(item)) : ''}
                            </td>
                            <td className={`text-end text-nowrap${item.saldo_anterior > 0 ? ' negative-number' : ''}`}>
                              {item.tipo_transaccion === 'facturacion' ? pintaPesos(-1 * item.saldo_anterior) : ''}
                            </td>
                            <td className={`text-end text-nowrap fw-bold${parseInt(item.monto) > 0 ? ' negative-number' : ''}`}>
                              {item.tipo_transaccion === 'facturacion' ? pintaPesos(-1 * parseInt(item.monto)) : ''}
                            </td>
                            <td className={`text-end text-nowrap fw-bold${parseInt(item.monto_pago) < 0 ? ' negative-number' : ''}`}>
                              {item.tipo_transaccion === 'pago' ? pintaPesos(item.monto_pago) : ''}
                            </td>
                            <td className={`text-end text-nowrap fw-bold${calcSaldoPeriodo(item) < 0 ? ' negative-number' : ''}`}>{pintaPesos(calcSaldoPeriodo(item))}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className='bgc-gris'>
                        <tr>
                          <th colSpan='8' className='text-end'>
                            Saldo final
                          </th>
                          <th className={`text-end text-nowrap fw-bold${(totales ? totales.total : 0) > 0 ? ' negative-number' : ''}`}>{pintaPesos(-1 * (totales ? totales.total : 0))}</th>
                          <th className={`text-end text-nowrap fw-bold${(totales ? totales.pago : 0) < 0 ? ' negative-number' : ''}`}>{pintaPesos(totales ? totales.pago : 0)}</th>
                          <th className={`text-end text-nowrap fw-bold${-1 * (totales ? totales.saldo_total : 0) < 0 ? ' negative-number' : ''}`}>
                            {pintaPesos(-1 * (totales ? totales.saldo_total : 0))}
                          </th>
                        </tr>
                      </tfoot>
                    </Table>
                  </Card.Body>
                </Card>
              ) : cuentaCorrienteInProgress ? (
                <CardPlaceHolder />
              ) : (
                <WarningMessage>No se ha encontrado cuenta corriente para el código de servicio: {codServicio}.</WarningMessage>
              )}
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CuentaCorriente;
