import React, { useState, useEffect } from 'react';
import { Container, Navbar, Breadcrumb, Image, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Auth } from 'aws-amplify';
import CambiarImagenModal from './CambiarImagenModal';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { useEPlatContext } from '../../libreria/EPlatContext';
import { USER_IMAGES_FOLDER, s3upload, s3remove } from '../../libreria/S3Storage';
import './PanelPerfil.css';

function PanelPerfil() {
  const { userSession, setUserSession, avatar, setAvatar } = useEPlatContext();
  const { attributes } = userSession;
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const { promiseInProgress: perfilInProgress } = usePromiseTracker({ area: 'area-perfil', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      setNombres(attributes.name ? attributes.name : '');
      setApellidos(attributes.family_name ? attributes.family_name : '');
    };

    onRender();
  }, []);

  function getAvatar() {
    return avatar ? avatar : `${process.env.PUBLIC_URL}/images/avatar-placeholder.png`;
  }

  function validateForm() {
    return nombres.length > 0;
  }

  function handleModal() {
    setModalVisible(!modalVisible);
  }

  async function getFileFromUrl(url, name) {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type
    });
  }

  async function uploadImage(url) {
    let filename = `${USER_IMAGES_FOLDER}/${attributes.email}.png`;
    if (url) {
      let file = await getFileFromUrl(url, filename);
      let key = await s3upload(filename, file);
      await Auth.updateUserAttributes(userSession, { picture: key });
      setAvatar(url);
    } else {
      await s3remove(filename);
      await Auth.deleteUserAttributes(userSession, ['picture']);
      setAvatar(null);
    }
  }

  async function updateUserAttributes() {
    await Auth.updateUserAttributes(userSession, { name: nombres, family_name: apellidos });
    const userUpdated = await Auth.currentAuthenticatedUser();
    setUserSession(userUpdated);
  }

  function handleChangeImagen(url) {
    trackPromise(uploadImage(url), 'area-perfil');
  }

  function handleSubmit(event) {
    event.preventDefault();
    trackPromise(updateUserAttributes(), 'area-perfil');
  }

  return (
    <>
      <Container fluid className='PanelPerfil d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Configuración
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Perfil</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='PanelPerfil'>
        <Row className='p-2'>
          <Col xs={12} lg={8} className='p-2'>
            <Card className='shadow-sm'>
              <Card.Header className='h5 fw-bold text-center'>Configuración perfil de usuario Administrador</Card.Header>
              <LoaderSpinner isLoading={perfilInProgress}>
                <Card.Body>
                  <Card.Text className='fs-5' align='justify'>
                    La configuración del usuario permite editar los atributos que corresponden al nombre completo, e imagen del usuario a desplegar. Los usuarios que pueden editar su perfil son
                    aquellos que cuentan con el rol de <b>Administrador</b>.
                  </Card.Text>
                  {/* <Card.Text className='fs-5' align='justify'>
                  Para aquellos usuarios residentes de la comunidad, sus atributos pueden ser editados a través de la funcionalidad disponible en el menú de <b>Comunidad</b>.
                </Card.Text> */}
                  <hr className='bg-secondary border-2 border-top border-secondary' />
                  <Container fluid className='d-flex flex-column flex-sm-row bg-light border rounded p-0 align-content-stretch'>
                    <div className='p-2 pb-0 p-sm-3 d-flex flex-column align-items-center align-self-sm-start'>
                      <button type='button' className='border-0 bg-light' onClick={handleModal}>
                        <Image src={getAvatar()} className='size-imagen border border-white border-4' roundedCircle />
                      </button>
                      <button type='button' className='fs-6 fst-normal text-primary m-1 border-0 bg-light' onClick={handleModal}>
                        Cambiar imagen
                      </button>
                    </div>
                    <div className='p-3 pt-2 p-sm-3 ps-sm-1 flex-fill overflow-hidden'>
                      <Card.Title className='fs-4 text-primary text-center text-sm-start text-nowrap overflow-scroll'>
                        <i className='fst-normal d-none d-sm-inline'>Usuario: </i>
                        <i className='fst-normal fw-bold text-nowrap'>{attributes.email}</i>
                      </Card.Title>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className='mb-1' controlId='nombreForm.nombres'>
                          <Form.Label className='fs-5 mb-1'>* Nombres:</Form.Label>
                          <Form.Control size='sm' type='text' value={nombres} onChange={(e) => setNombres(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='mb-1' controlId='nombreForm.apellidos'>
                          <Form.Label className='fs-5 mb-1'>Apellidos:</Form.Label>
                          <Form.Control size='sm' type='text' value={apellidos} onChange={(e) => setApellidos(e.target.value)} />
                        </Form.Group>
                        <Button className='mt-3' variant='primary' size='sm' type='submit' disabled={!validateForm()}>
                          Cambiar
                        </Button>
                      </Form>
                    </div>
                  </Container>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
      <CambiarImagenModal show={modalVisible} imagen={avatar} onHide={handleModal} onSelect={handleChangeImagen} />
    </>
  );
}

export default PanelPerfil;
