import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Auth } from 'aws-amplify';
import { useEPlatContext } from '../libreria/EPlatContext';
import LoaderButton from '../libreria/LoaderButton';
import { onError } from '../libreria/ErrorHandler';
import { s3getURL } from '../libreria/S3Storage';

function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { userHasAuthenticated, setUserSession, setAvatar } = useEPlatContext();
  // DVD: Gestionaremos la autenticacion temporal para obligar el cambio de password cuando es requerido.
  const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  // DVD: Campos de formularios que deben ser validados.
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function validateChangePasswordForm() {
    return newPassword.length > 0 && confirmedPassword.length > 0 && newPassword === confirmedPassword;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      let user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setAuthenticatedUser(user);
        setPasswordChangeRequired(true);
        setIsLoading(false);
      } else {
        setUserAsAuthenticated();
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleChangePasswordSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.completeNewPassword(authenticatedUser, newPassword);
      setUserAsAuthenticated();
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function setUserAsAuthenticated() {
    let user = await Auth.currentAuthenticatedUser();
    /** Se setean los valores en EPlatContext. Es importante primero setear la
     * sesion del usuario con setUserSession(), y luego indicar que el usuario
     * está autenticado con userHasAuthenticated(true). */
    setUserSession(user);
    userHasAuthenticated(true);
    /** Se obtiene la imagen del usuario desde S3, con base en el atributo picture */
    let avatar = await s3getURL(user.attributes.picture);
    setAvatar(avatar);
    navigate('/');
  }

  async function handleCancel(event) {
    await Auth.signOut();
    setPassword('');
    setNewPassword('');
    setConfirmedPassword('');
    setAuthenticatedUser(null);
    setPasswordChangeRequired(false);
  }

  function renderLoginForm() {
    return (
      <Card className='shadow-lg border-0'>
        <Card.Body className='m-4'>
          <Form onSubmit={handleSubmit}>
            <h2 className='mb-5 fw-light text-dark text-center'>
              Conectarse a{' '}
              <b className='fw-normal'>
                <i className='text-primary'>e</i>Plat
              </b>
            </h2>
            <Form.Group className='mb-3' controlId='email'>
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control autoFocus type='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='usuario@correo.com' />
            </Form.Group>
            <Form.Group className='mb-3' controlId='password'>
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Tu contraseña' />
            </Form.Group>
            <h6 className='d-flex flex-wrap p-0 mb-5 justify-content-between'>
              <Form.Check type='checkbox' className='me-5' label='Recordarme' id='recordar' disabled></Form.Check>
              <a href='/' className='text-muted'>
                Recuperar contraseña
              </a>
            </h6>
            <div className='d-flex'>
              <LoaderButton id='aceptar' type='submit' variant='primary' className='flex-fill' isLoading={isLoading} disabled={!validateForm()}>
                Aceptar
              </LoaderButton>
            </div>
          </Form>
        </Card.Body>
      </Card>
    );
  }

  function renderChangePasswordForm() {
    return (
      <Card className='shadow-lg border-0'>
        <Card.Body className='m-4'>
          <Form onSubmit={handleChangePasswordSubmit}>
            <h2 className='mb-5 fw-light text-dark text-center'>Cambie su contraseña</h2>
            <Form.Group className='mb-3' controlId='email'>
              <Form.Label className='text-muted'>Correo electrónico</Form.Label>
              <Form.Control value={email} disabled />
            </Form.Group>
            <Form.Group className='mb-3' controlId='newPassword'>
              <Form.Label>Contraseña</Form.Label>
              <Form.Control autoFocus type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder='Tu nueva contraseña' />
            </Form.Group>
            <Form.Group className='mb-5' controlId='confirmedPassword'>
              <Form.Control type='password' value={confirmedPassword} onChange={(e) => setConfirmedPassword(e.target.value)} placeholder='Confirmar contraseña' />
            </Form.Group>
            <div className='d-flex'>
              <Button type='button' variant='secondary' className='flex-fill me-2' disabled={isLoading} onClick={!isLoading ? handleCancel : null}>
                Cancelar
              </Button>
              <LoaderButton type='submit' variant='primary' className='flex-fill' isLoading={isLoading} disabled={!validateChangePasswordForm()}>
                Aceptar
              </LoaderButton>
            </div>
          </Form>
        </Card.Body>
      </Card>
    );
  }

  return passwordChangeRequired ? renderChangePasswordForm() : renderLoginForm();
}

export default Login;
