import React from 'react';
import { Oval } from 'react-loader-spinner';

export default function LoaderSpinner({ isLoading, disabled = false, ...props }) {
  return (
    <div disabled={disabled || isLoading} {...props} style={{ position: 'relative' }}>
      {isLoading && (
        <Oval
          ariaLabel='loading-indicator'
          height={50}
          width={50}
          strokeWidth={7}
          color='#b24a3a'
          secondaryColor='#e0baa5'
          wrapperStyle={{
            margin: '0',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            height: '100%',
            width: '100%',
            borderRadius: '4px',
            top: '50%',
            left: '50%',
            zIndex: '899',
            backgroundColor: 'rgba(255,255,255,0.8)',
            msTransform: 'translate(-50%, -50%)',
            transform: 'translate(-50%, -50%)'
          }}
        />
      )}
      {props.children}
    </div>
  );
}
