import React, { useState } from 'react';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import './PeriodoDropDown.css';

function PeriodoDropDown(props) {
  const MES_PREVIO = 0;
  const MES_ULTIMO = 13;
  const [isShown, setIsShown] = useState(false);
  const [mesProp, setMesProp] = useState(moment().month() + 1);
  const [annoProp, setAnnoProp] = useState(moment().year());
  const meses = [
    { id: 1, mes: 'Ene' },
    { id: 2, mes: 'Feb' },
    { id: 3, mes: 'Mar' },
    { id: 4, mes: 'Abr' },
    { id: 5, mes: 'May' },
    { id: 6, mes: 'Jun' },
    { id: 7, mes: 'Jul' },
    { id: 8, mes: 'Ago' },
    { id: 9, mes: 'Sep' },
    { id: 10, mes: 'Oct' },
    { id: 11, mes: 'Nov' },
    { id: 12, mes: 'Dic' }
  ];

  function handleToogle(isOpen, event, metadata) {
    if (isOpen) {
      setMesProp(meses.find((item) => item.mes === props.value.substring(0, 3)).id);
      setAnnoProp(parseInt(props.value.substring(4, 8)));
    }
    setIsShown(isOpen);
  }
  function previous(event) {
    setAnnoProp(annoProp <= 2020 ? 2020 : annoProp - 1);
  }
  function next(event) {
    setAnnoProp(annoProp >= 2050 ? 2050 : annoProp + 1);
  }
  function seleccionar(data) {
    let periodSelec;
    if (data === MES_PREVIO) periodSelec = moment().subtract(2, 'months');
    else if (data === MES_ULTIMO) periodSelec = moment().subtract(1, 'months');
    else periodSelec = moment({ year: annoProp, month: data - 1 });
    // Se oculta el Dropdown calendar y luego se invoca a la funcion padre.
    setIsShown(false);
    props.onSelect({ anno: periodSelec.year(), mes: periodSelec.month() + 1 });
  }

  return (
    <Dropdown {...props} show={isShown} className={`PeriodoDropDown ${props.className}`} onToggle={handleToogle}>
      <Dropdown.Toggle className='btn btn-sm btn-outline-dark' id='boton-periodo' variant='white'>
        <DateRangeRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-1' />
        <i className='fst-normal px-1'>{props.value}</i>
      </Dropdown.Toggle>
      <Dropdown.Menu className='p-1 dropdown-menu-end shadow rounded-3 cal-width'>
        <div className='d-grid gap-1'>
          <div className='cal-year rounded'>
            <button className='btn cal-btn' type='button' onClick={previous}>
              <ArrowLeftIcon />
            </button>
            <button className='btn cal-btn cal-text-sm' onClick={previous}>
              {annoProp - 1}
            </button>
            <span className='cal-no-btn fw-bold'>{annoProp}</span>
            <button className='btn cal-btn cal-text-sm' onClick={next}>
              {annoProp + 1}
            </button>
            <button className='btn cal-btn' type='button' onClick={next}>
              <ArrowRightIcon />
            </button>
          </div>
          <div className='cal-opcion'>
            <button type='button' className='btn cal-btn cal-text-sm' onClick={() => seleccionar(MES_PREVIO)}>
              Penúltimo
            </button>
            <button type='button' className='btn cal-btn cal-text-sm' onClick={() => seleccionar(MES_ULTIMO)}>
              Último mes
            </button>
          </div>
          <div className='cal-month'>
            {meses.map((item) => (
              <button key={item.id} className={`btn cal-btn border-0${item.id === mesProp ? ' btn-dark' : ''}`} type='button' onClick={() => seleccionar(item.id)}>
                {item.mes}
              </button>
            ))}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PeriodoDropDown;
