import React, { useEffect } from 'react';
import { Container, Navbar, Breadcrumb, Row, Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { getFactura } from '../../redux/facturaSlice';
import { consumoHistorico } from '../../redux/consumoHistoricoSlice';
import { pintaNumero } from '../../libreria/utilidades';
import Factura from './Factura';
import GraficoConsumo from '../documentos/GraficoConsumo';
import SearchIcon from '@mui/icons-material/Search';
import SearchById from '../../componentes/SearchById';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import CardPlaceHolder from '../../componentes/CardPlaceHolder';
import WarningMessage from '../../componentes/WarningMessage';
import './FacturaFolio.css';

function FacturaFolio() {
  const { folio } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { promiseInProgress: facturaInProgress } = usePromiseTracker({ area: 'area-factura', delay: 0 });
  const { promiseInProgress: graficoInProgress } = usePromiseTracker({ area: 'area-grafico', delay: 0 });
  const comunidad = useSelector((store) => store.comunidad);
  const factura = useSelector((store) => store.factura);

  function handleDocumentoSearch(value) {
    navigate(`/documento/folio/${Number(value)}`);
  }

  function handleSelectCodServicioOpcion(eventKey) {
    if (factura.cabecera) {
      if (eventKey === 'documentos') {
        navigate(`/documento/cod-servicio/${factura.cabecera.codigo_servicio}`);
      } else if (eventKey === 'cta-cte') {
        navigate(`/pago/cta-cte/${factura.cabecera.codigo_servicio}`);
      }
    }
  }

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getFactura(comunidad.selected.id, folio)), 'area-factura');
      trackPromise(dispatch(consumoHistorico(comunidad.selected.id, folio, 'FACTURA')), 'area-grafico');
    };

    onRender();
  }, [comunidad.selected, folio]);

  function pintaCodigoServicio() {
    return facturaInProgress || factura.cabecera === null ? '' : pintaNumero(factura.cabecera.codigo_servicio);
  }

  function LoaderSpinnerPlaceHolder() {
    return (
      <LoaderSpinner isLoading={true}>
        <CardPlaceHolder />
      </LoaderSpinner>
    );
  }

  return (
    <>
      <Container fluid className='FacturaFolio d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-md-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/documento' className='text-decoration-none'>
                  Facturas y boletas
                </Link>
              </li>
              <Breadcrumb.Item active>Factura {pintaNumero(folio)}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='PanelFacturas d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <SearchById className='me-2' placeholder='Folio docto.' btntext='Buscar' onClick={handleDocumentoSearch} />
          <div className='vr mx-2'></div>
          <Dropdown onSelect={handleSelectCodServicioOpcion}>
            <Dropdown.Toggle variant='outline-secondary' size='sm' id='dropdown-cod-servicio'>
              Código servicio: <i className='fst-normal fw-bold'>{pintaCodigoServicio()}</i>
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu-end'>
              <Dropdown.Item eventKey='documentos'>Ver otros documentos</Dropdown.Item>
              <Dropdown.Item eventKey='cta-cte'>Ver cuenta corriente</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </Container>

      <Container fluid>
        {facturaInProgress || graficoInProgress ? (
          <Row className='p-2'>
            <Col xs={12} md={12} lg={7} xl={6} className='p-2'>
              <LoaderSpinnerPlaceHolder />
            </Col>
            <Col xs={12} md={10} lg={5} xl={4} className='p-2'>
              <LoaderSpinnerPlaceHolder />
            </Col>
          </Row>
        ) : factura.cabecera ? (
          <Row className='p-2'>
            <Col xs={12} md={12} lg={7} xl={6} className='p-2'>
              <Factura />
            </Col>
            <Col xs={12} md={10} lg={5} xl={4} className='p-2'>
              <GraficoConsumo />
            </Col>
          </Row>
        ) : (
          <Row className='p-2'>
            <Col xs={12} md={12} className='p-2'>
              <WarningMessage>No se ha encontrado factura.</WarningMessage>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}

export default FacturaFolio;
