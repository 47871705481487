import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Navbar, Breadcrumb, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import PeriodoDropDown from '../../componentes/PeriodoDropDown';
import RangoPeriodos, { rangos } from '../../componentes/RangoPeriodos';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { pintaPeriodo, pintaPesos, pintaNumero, pintaFecha } from '../../libreria/utilidades';
import { getCompra } from '../../redux/compraSlice';
import { selectPeriodo } from '../../redux/periodoSlice';
import { getComprasRango } from '../../redux/comprasRangoSlice';
import GraficoCompraEnergia from './GraficoCompraEnergia';
import './PanelCompraEnergia.css';

function PanelCompraEnergia() {
  const comunidad = useSelector((store) => store.comunidad);
  const periodo = useSelector((store) => store.periodo);
  const { suministro, distribucion } = useSelector((store) => store.compra);
  const comprasRango = useSelector((store) => store.comprasRango);
  const [selectedRango, setSelectedRango] = useState(0);
  const { promiseInProgress: compraInProgress } = usePromiseTracker({ area: 'area-compra', delay: 0 });
  const { promiseInProgress: comprasRangoInProgress } = usePromiseTracker({ area: 'area-comprasRango', delay: 0 });
  const dispatch = useDispatch();

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getCompra(comunidad.selected.id, periodo.anno, periodo.mes)), 'area-compra');
      trackPromise(dispatch(getComprasRango(comunidad.selected.id, rangos[selectedRango])), 'area-comprasRango');
    };

    onRender();
  }, [comunidad.selected, periodo]);

  function handlePeriodoClick(data) {
    dispatch(selectPeriodo(data));
  }

  function handleSelectRangoPeriodo(eventKey, event) {
    setSelectedRango(eventKey);
    trackPromise(dispatch(getComprasRango(comunidad.selected.id, rangos[eventKey])), 'area-comprasRango');
  }

  return (
    <>
      <Container fluid className='PanelCompraEnergia d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Compra y venta de energía
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Compra de energía</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <p className='m-0 me-2 d-none d-sm-inline text-nowrap'>Período (mes/año):</p>
          <PeriodoDropDown className='me-3' onSelect={handlePeriodoClick} value={pintaPeriodo(periodo)} />
        </Navbar>
      </Container>
      <Container fluid className='PanelCompraEnergia'>
        <Row className='p-2'>
          <Col xs={12} lg={8} xxl={6} className='p-2'>
            <LoaderSpinner isLoading={compraInProgress}>
              <Card className='shadow-sm'>
                <Card.Body>
                  <Card.Title className='fs-4 fw-bold text-center mb-2'>
                    Detalle compra período: <i className='fst-normal fw-normal'>{pintaPeriodo(periodo)}</i>
                  </Card.Title>
                  <Container fluid className='accordion p-0'>
                    <Row className='p-0'>
                      <Col xs={12} className='p-2 pt-0'>
                        <div className='border accordion-item'>
                          <h2 className='accordion-header' id='headingOne'>
                            <button
                              className='accordion-button collapsed fs-5 fw-bold'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseOne'
                              aria-expanded='false'
                              aria-controls='collapseOne'
                            >
                              Suministro de energía
                            </button>
                          </h2>
                          <div id='collapseOne' className='accordion-collapse collapse' aria-labelledby='headingOne' data-bs-parent='#accordionExample'>
                            <div className='accordion-body p-0'>
                              {suministro ? (
                                <Table size='sm' borderless className='m-0 p-0 fs-6'>
                                  <tbody>
                                    <tr>
                                      <td className='fw-bold'>Proveedor:</td>
                                      <td>{suministro.razon_social_proveedor}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Folio factura:</td>
                                      <td>{pintaNumero(suministro.folio)}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Fecha emisión:</td>
                                      <td>{pintaFecha(suministro.fecha_emision)}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Periodo:</td>
                                      <td>{suministro.periodo}</td>
                                    </tr>
                                    <tr className='border-top'>
                                      <td className='fw-bold'>Energía [kWh]:</td>
                                      <td className='text-end'>{pintaNumero(suministro.energia)}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Potencia [kW]:</td>
                                      <td className='text-end'>{pintaNumero(suministro.potencia)}</td>
                                    </tr>
                                    {suministro.detalles.map((detalle, index) => (
                                      <tr key={index} className={index == 0 ? 'border-top' : ''}>
                                        <td className='fw-bold'>{detalle.nombre_cargo}</td>
                                        <td className='text-end'>{pintaPesos(detalle.monto_total)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              ) : (
                                <i className='fst-normal fs-6 p-2 m-0'>Sin datos de suministro</i>
                              )}
                            </div>
                          </div>
                        </div>
                        {suministro ? (
                          <Table responsive borderless size='sm' className='fs-6 m-0'>
                            <tbody className='text-end'>
                              <tr className='color-gris bg-light'>
                                <th className='text-center border-start'>Folio factura</th>
                                <th className='text-center'>Monto neto</th>
                                <th className='text-center'>Monto exento</th>
                                <th className='text-center'>IVA</th>
                                <th className='text-center'>Saldo anterior</th>
                                <th className='text-center border-end'>Monto total</th>
                              </tr>
                              <tr className='border-bottom'>
                                <td className='text-nowrap border-start fw-bold'>{pintaNumero(suministro.folio)}</td>
                                <td className='text-nowrap'>{pintaPesos(suministro.monto_neto)}</td>
                                <td className='text-nowrap'>{pintaPesos(suministro.monto_exento)}</td>
                                <td className='text-nowrap'>{pintaPesos(suministro.total_iva)}</td>
                                <td className='text-nowrap'>{pintaPesos(suministro.saldo_mes_anterior)}</td>
                                <td className='text-nowrap border-end fw-bold'>{pintaPesos(suministro.total)}</td>
                              </tr>
                            </tbody>
                          </Table>
                        ) : null}
                      </Col>
                      <Col xs={12} className='p-2 pb-0'>
                        <div className='border accordion-item'>
                          <h2 className='accordion-header' id='headingTwo'>
                            <button
                              className='accordion-button collapsed fs-5 fw-bold'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseTwo'
                              aria-expanded='false'
                              aria-controls='collapseTwo'
                            >
                              Peaje en distribución
                            </button>
                          </h2>
                          <div id='collapseTwo' className='accordion-collapse collapse' aria-labelledby='headingTwo' data-bs-parent='#accordionExample'>
                            <div className='accordion-body p-0'>
                              {distribucion ? (
                                <Table size='sm' borderless className='m-0 p-0 fs-6'>
                                  <tbody>
                                    <tr>
                                      <td className='fw-bold'>Proveedor:</td>
                                      <td>{distribucion.razon_social_proveedor}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Folio factura:</td>
                                      <td>{pintaNumero(distribucion.folio)}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Fecha emisión:</td>
                                      <td>{pintaFecha(distribucion.fecha_emision)}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Periodo:</td>
                                      <td>{distribucion.periodo}</td>
                                    </tr>
                                    <tr className='border-top'>
                                      <td className='fw-bold'>Energía [kWh]:</td>
                                      <td className='text-end'>{pintaNumero(distribucion.energia)}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Cpa.Pot. [kW]:</td>
                                      <td className='text-end'>{pintaNumero(distribucion.compra_potencia)}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Demanda Horas Punta [kW]:</td>
                                      <td className='text-end'>{pintaNumero(distribucion.demanda_hora_punta)}</td>
                                    </tr>
                                    <tr>
                                      <td className='fw-bold'>Demanda suministrada [kW]:</td>
                                      <td className='text-end'>{pintaNumero(distribucion.demanda_suministrada)}</td>
                                    </tr>
                                    {distribucion.detalles.map((detalle, index) => (
                                      <tr key={index} className={index == 0 ? 'border-top' : ''}>
                                        <td className='fw-bold'>{detalle.nombre_cargo}</td>
                                        <td className='text-end'>{pintaPesos(detalle.monto_total)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              ) : (
                                <i className='fst-normal fs-6 p-2 m-0'>Sin datos de distribucion</i>
                              )}
                            </div>
                          </div>
                        </div>
                        {distribucion ? (
                          <Table responsive borderless size='sm' className='fs-6 m-0'>
                            <tbody className='text-end'>
                              <tr className='color-gris bg-light'>
                                <th className='text-center border-start'>Folio factura</th>
                                <th className='text-center'>Monto neto</th>
                                <th className='text-center'>Monto exento</th>
                                <th className='text-center'>IVA</th>
                                <th className='text-center'>Saldo anterior</th>
                                <th className='text-center border-end'>Monto total</th>
                              </tr>
                              <tr className='border-bottom'>
                                <td className='text-nowrap border-start fw-bold'>{pintaNumero(distribucion.folio)}</td>
                                <td className='text-nowrap'>{pintaPesos(distribucion.monto_neto)}</td>
                                <td className='text-nowrap'>{pintaPesos(distribucion.monto_exento)}</td>
                                <td className='text-nowrap'>{pintaPesos(distribucion.total_iva)}</td>
                                <td className='text-nowrap'>{pintaPesos(distribucion.saldo_mes_anterior)}</td>
                                <td className='text-nowrap border-end fw-bold'>{pintaPesos(distribucion.total)}</td>
                              </tr>
                            </tbody>
                          </Table>
                        ) : null}
                      </Col>
                    </Row>
                  </Container>
                  <Container fluid className='p-0'></Container>
                </Card.Body>
              </Card>
            </LoaderSpinner>
          </Col>
          <Col xs={12} lg={10} xl={9} xxl={8} className='p-2'>
            <LoaderSpinner isLoading={comprasRangoInProgress}>
              <Card className='shadow-sm'>
                <Card.Header className='bg-light d-flex flex-wrap p-0'>
                  <Navbar className='flex-fill'>
                    <Card.Title className='fs-4 m-0 ms-3 fw-bold'>Histórico suministro y distribución</Card.Title>
                  </Navbar>
                  <Navbar className='flex-fill justify-content-end'>
                    <p className='d-none d-lg-inline m-0 p-0'>Rango períodos:</p>
                    <RangoPeriodos onSelect={handleSelectRangoPeriodo} selected={selectedRango} />
                  </Navbar>
                </Card.Header>
                <Card.Body className='py-2'>
                  <GraficoCompraEnergia rango={selectedRango} style={{ maxHeight: '11rem', maxWidth: '680px' }} className='mb-3' />
                  <Table hover responsive size='sm' className='fs-6'>
                    <thead className='text-center bgc-gris'>
                      <tr>
                        <th rowSpan='2' className='border-end border-white'>
                          Período
                        </th>
                        <th colSpan='4' className='border-end border-white'>
                          Suministro energía
                        </th>
                        <th colSpan='4' className='border-end border-white'>
                          Peaje distribución
                        </th>
                      </tr>
                      <tr>
                        <td className='border-end border-white'>Neto</td>
                        <td className='border-end border-white'>Exento</td>
                        <td className='border-end border-white'>IVA</td>
                        <td className='border-end border-white'>Total</td>
                        <td className='border-end border-white'>Neto</td>
                        <td className='border-end border-white'>Exento</td>
                        <td className='border-end border-white'>IVA</td>
                        <td>Total</td>
                      </tr>
                    </thead>
                    <tbody>
                      {comprasRango.rows.map((item, index) => (
                        <tr key={index}>
                          <td>{item.periodo}</td>
                          <td className='text-end'>{pintaPesos(item.monto_neto_suministro)}</td>
                          <td className='text-end'>{pintaPesos(item.monto_exento_suministro)}</td>
                          <td className='text-end'>{pintaPesos(item.total_iva_suministro)}</td>
                          <td className='text-end'>{pintaPesos(item.total_suministro)}</td>
                          <td className='text-end'>{pintaPesos(item.monto_neto_distribucion)}</td>
                          <td className='text-end'>{pintaPesos(item.monto_exento_distribucion)}</td>
                          <td className='text-end'>{pintaPesos(item.total_iva_distribucion)}</td>
                          <td className='text-end'>{pintaPesos(item.total_distribucion)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PanelCompraEnergia;
