import React, { useEffect } from 'react';
import './EPlatHome.css';
import { useEPlatContext } from '../libreria/EPlatContext';
import Container from 'react-bootstrap/Container';
import EPlatHeader from '../componentes/EPlatHeader';
import EPlatSidebar from '../componentes/EPlatSidebar';
import EPlatMain from '../componentes/EPlatMain';
import { useDispatch } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { getComunidades } from '../redux/comunidadSlice';
import { useSelector } from 'react-redux';
import LoaderSpinner from '../libreria/LoaderSpinner';

function EPlatHome() {
  const { userSession } = useEPlatContext();
  const { attributes } = userSession;
  const dispatch = useDispatch();
  const comunidad = useSelector((store) => store.comunidad);
  const { promiseInProgress: comunidadInProgress } = usePromiseTracker({ area: 'comunidad', delay: 0 });

  useEffect(() => {
    const onLoad = () => {
      const key = `ePlatKEY@${attributes.email}comunidad-selected`;
      let nombreComunidadSelected = localStorage.getItem(key);
      trackPromise(dispatch(getComunidades(nombreComunidadSelected)), 'comunidad');
    };

    onLoad();
  }, []);

  return (
    <div className='EPlatHome'>
      <LoaderSpinner isLoading={comunidadInProgress}>
        <EPlatHeader />
        <Container fluid className='p-0 d-flex'>
          <EPlatSidebar />
          {comunidad.isSelected ? <EPlatMain /> : null}
        </Container>
      </LoaderSpinner>
    </div>
  );
}

export default EPlatHome;
