import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const documentosFolioSlice = createSlice({
  name: 'documentosFolio',
  initialState: {
    rows: []
  },
  reducers: {
    setDocumentosFolio: (state, action) => {
      state.rows = action.payload;
    }
  }
});

export const { setDocumentosFolio } = documentosFolioSlice.actions;

// Action Creators
export const getDocumentosFolio = (comunidadId, folio) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:documentosFolio&comunidad=${comunidadId}&folio=${folio}`);
  if (item) {
    dispatch(setDocumentosFolio(item));
  } else {
    try {
      const response = await API.get('ePlat', `/documentos-folio?comunidad=${comunidadId}&folio=${folio}`);
      dispatch(setDocumentosFolio(response));
      LocalCache.put(`KEY:documentosFolio&comunidad=${comunidadId}&folio=${folio}`, response);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getDocumentosFolio()', error);
    }
  }
};

export default documentosFolioSlice.reducer;
