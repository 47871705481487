import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const ultimoMes = moment().subtract(1, 'months');

export const periodoSlice = createSlice({
  name: 'periodo',
  initialState: {
    mes: ultimoMes.month() + 1,
    anno: ultimoMes.year()
  },
  reducers: {
    selectPeriodo: (state, action) => {
      state.anno = action.payload.anno;
      state.mes = action.payload.mes;
    }
  }
});

export const { selectPeriodo } = periodoSlice.actions;

export default periodoSlice.reducer;
