import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const documentosSlice = createSlice({
  name: 'documentos',
  initialState: {
    page: -1,
    count: 0,
    rows: []
  },
  reducers: {
    setDocumentos: (state, action) => {
      state.page = action.payload.page;
      state.count = action.payload.count;
      state.rows = action.payload.rows;
    }
  }
});

export const { setDocumentos } = documentosSlice.actions;

// Action Creators
export const getDocumentos = (comunidadId, anno, mes, page, size) => async (dispatch, getState) => {
  let offset = page * size;
  let item = LocalCache.get(`KEY:documentos&comunidad=${comunidadId}&ano=${anno}&mes=${mes}&offset=${offset}`);
  if (item) {
    dispatch(setDocumentos(item));
  } else {
    try {
      const response = await API.get('ePlat', `/documentos?comunidad=${comunidadId}&ano=${anno}&mes=${mes}&offset=${offset}&size=${size}`);
      let documentos = { page: response.count > 0 ? page : -1, count: response.count, rows: response.rows };
      dispatch(setDocumentos(documentos));
      LocalCache.put(`KEY:documentos&comunidad=${comunidadId}&ano=${anno}&mes=${mes}&offset=${offset}`, documentos);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getDocumentos()', error);
    }
  }
};

export default documentosSlice.reducer;
