import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Navbar, Breadcrumb, ListGroup, Table, FormControl } from 'react-bootstrap';
import { getTotalesMedidores } from '../../redux/totalesMedidoresSlice';
import { pintaNumero } from '../../libreria/utilidades';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import SearchIcon from '@mui/icons-material/Search';
import GraficoLecturasComunidad from './GraficoLecturasComunidad';
import RangoPeriodos, { rangos } from '../../componentes/RangoPeriodos';

import './PanelMedidor.css';

function PanelMedidor() {
  const navigate = useNavigate();
  const comunidad = useSelector((store) => store.comunidad);
  const codigos = comunidad.selected.medidores;
  const { data, lecturas } = useSelector((store) => store.totalesMedidores);
  const [selectedRango, setSelectedRango] = useState(0);
  const { promiseInProgress: totalMedidoresInProgress } = usePromiseTracker({ area: 'area-total-medidor', delay: 0 });
  const dispatch = useDispatch();

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getTotalesMedidores(comunidad.selected.id, rangos[selectedRango])), 'area-total-medidor');
    };

    onRender();
  }, [comunidad.selected]);

  function handleMedidorChange(event, newValue) {
    if (newValue) {
      let codigoMedidor = codigos.find((item) => item === newValue);
      if (codigoMedidor) {
        navigate(`/medidor/${codigoMedidor}`);
      }
    }
  }

  function handleSelectRangoPeriodo(eventKey, event) {
    setSelectedRango(eventKey);
    trackPromise(dispatch(getTotalesMedidores(comunidad.selected.id, rangos[eventKey])), 'area-total-medidor');
  }

  return (
    <>
      <Container fluid className='PanelMedidor d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Datos de medidores</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='PanelMedidor d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <p className='m-0 me-2 d-none d-md-inline text-nowrap'>Medidor:</p>
          <Autocomplete
            className='autocomplete bg-white'
            sx={{ width: 150 }}
            size='small'
            options={codigos}
            onChange={handleMedidorChange}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <FormControl {...params.inputProps} className='fst-normal bg-white' size='sm' placeholder='Código medidor' />
              </div>
            )}
          />
        </Navbar>
      </Container>
      <Container fluid className='PanelMedidor'>
        <Row className='p-2'>
          <Col xs={12} md={11} lg={9} xl={8} xxl={6} className='p-2'>
            <LoaderSpinner isLoading={totalMedidoresInProgress}>
              <Card className='shadow-sm'>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <Card.Title className='m-0 fw-bold'>Comunidad: {comunidad.selected.nombre}</Card.Title>
                  </ListGroup.Item>
                  <ListGroup.Item className='py-1 bg-light fs-6 fw-bold'>Medidores</ListGroup.Item>
                  <ListGroup.Item>
                    <Table borderless responsive className='fs-6 m-0 ' size='sm'>
                      <tbody>
                        <tr>
                          <td className='p-0 fw-bold color-gris' colSpan='3'>
                            Cantidad total
                          </td>
                        </tr>
                        <tr>
                          <td className='p-0 fw-bold' colSpan='3'>
                            {data.total}
                          </td>
                        </tr>
                        <tr>
                          <td className='p-0 pt-1 fw-bold color-gris column-ancho border-end'>Activos</td>
                          <td className='p-0 pt-1 ps-2 fw-bold color-gris column-ancho border-end'>Medidores bt1</td>
                          <td className='p-0 pt-1 ps-2 fw-bold color-gris'>Medidores bt4.3</td>
                        </tr>
                        <tr>
                          <td className='p-0 fw-bold border-end'>{data.activos}</td>
                          <td className='p-0 ps-2 fw-bold border-end'>{data.medidoresBT1}</td>
                          <td className='p-0 ps-2 fw-bold'>{data.medidoresBT43}</td>
                        </tr>
                        <tr>
                          <td className='p-0 pt-1 fw-bold color-gris' colSpan='3'>
                            Inactivos
                          </td>
                        </tr>
                        <tr>
                          <td className='p-0 fw-bold' colSpan='3'>
                            {data.inactivos}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </ListGroup.Item>
                </ListGroup>
                <Card.Header className='bg-light d-flex flex-wrap p-0'>
                  <Navbar className='flex-fill'>
                    <Card.Title className='fs-4 m-0 ms-3 fw-bold'>Energía consumida [kWh]</Card.Title>
                  </Navbar>
                  <Navbar className='flex-fill justify-content-end'>
                    <p className='d-none d-lg-inline m-0 p-0'>Rango períodos:</p>
                    <RangoPeriodos onSelect={handleSelectRangoPeriodo} selected={selectedRango} />
                  </Navbar>
                </Card.Header>
                <Card.Body className='p-1 d-flex flex-column align-items-center'>
                  <GraficoLecturasComunidad rango={selectedRango} style={{ maxHeight: '11rem', maxWidth: '540px' }} className='mb-3' />
                </Card.Body>
                <Card.Body className='py-0 d-flex flex-column'>
                  <Card.Subtitle className='mb-1 fw-bold fs-6'>Consumos comunidad: {comunidad.selected.nombre}</Card.Subtitle>
                  <Table bordered responsive size='sm'>
                    <tbody>
                      <tr className='text-small'>
                        <th className='text-center'>Periodo</th>
                        {lecturas.map((item, index) => (
                          <td key={index} className='text-center text-nowrap'>
                            {item.periodo}
                          </td>
                        ))}
                      </tr>
                      <tr className='text-small'>
                        <th className='text-center'>[kWh]</th>
                        {lecturas.map((item, index) => (
                          <td key={index} className='text-end'>
                            {pintaNumero(item.consumo)}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PanelMedidor;
