import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col, Card, Navbar, Breadcrumb, FormControl, Dropdown } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentosCodServicio } from '../../redux/documentosCodServicioSlice';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { pintaFecha, pintaNumero, pintaPesos, pintaEstado } from '../../libreria/utilidades';
import CardPlaceHolder from '../../componentes/CardPlaceHolder';
import WarningMessage from '../../componentes/WarningMessage';
import RangoPeriodos, { rangos } from '../../componentes/RangoPeriodos';
import SearchIcon from '@mui/icons-material/Search';
import SearchById from '../../componentes/SearchById';
import './DocumentosCodServicio.css';

function DocumentosCodServicio() {
  const { codServicio } = useParams();
  const comunidad = useSelector((store) => store.comunidad);
  const documentos = useSelector((store) => store.documentosCodServicio);
  const [estado, setEstado] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker({ delay: 0 });

  const filtroEstados = [
    { id: 0, estado: 'VALIDA' },
    { id: 1, estado: 'ANULADA' },
    { id: 2, estado: 'Todas' }
  ];

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getDocumentosCodServicio(comunidad.selected.id, codServicio, rangos[documentos.rangoId])));
    };

    onRender();
  }, [comunidad.selected, codServicio]);

  function handleSelectRangoPeriodo(eventKey, event) {
    trackPromise(dispatch(getDocumentosCodServicio(comunidad.selected.id, codServicio, rangos[eventKey])));
  }

  function handleSelectEstado(eventKey, event) {
    setEstado(eventKey);
  }

  function handleCodigoServicioSearch(value) {
    navigate(`/documento/cod-servicio/${Number(value)}`);
  }

  return (
    <>
      <Container fluid className='DocumentosCodServicio d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/documento' className='text-decoration-none'>
                  Facturas y boletas
                </Link>
              </li>
              <Breadcrumb.Item active>Código servicio {pintaNumero(codServicio)}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <p className='d-none d-lg-inline m-0 p-0'>Rango períodos:</p>
          <RangoPeriodos onSelect={handleSelectRangoPeriodo} selected={documentos.rangoId} />
        </Navbar>
      </Container>
      <Container fluid className='PanelDocumentos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <SearchById placeholder='Código.serv' btntext='Buscar' onClick={handleCodigoServicioSearch} />
        </Navbar>
      </Container>
      <Container fluid>
        <Row className='p-2'>
          <Col xs={12} xl={11} xxl={9} className='p-2'>
            <LoaderSpinner isLoading={promiseInProgress}>
              {documentos.data ? (
                <Card className='shadow-sm'>
                  <Card.Header className='bg-light d-flex flex-wrap p-0'>
                    <Navbar className='flex-fill'>
                      <Card.Title className='fs-4 m-0 ms-3 fw-bold'>
                        Código servicio: <i className='fst-normal fw-normal'>{pintaNumero(codServicio)}</i>
                      </Card.Title>
                    </Navbar>
                    <Navbar className='flex-fill justify-content-end'>
                      <p className='d-none d-lg-inline m-0 p-0'>Estado documentos:</p>
                      <Dropdown onSelect={handleSelectEstado}>
                        <Dropdown.Toggle variant='light' size='sm' id='dropdown-filtro-periodos' className='h5 m-0 ms-2 me-2 border'>
                          {filtroEstados[estado].estado}
                        </Dropdown.Toggle>
                        <Dropdown.Menu renderOnMount='true' align='end'>
                          {filtroEstados.map((item) => (
                            <Dropdown.Item key={item.id} eventKey={item.id}>
                              {item.estado}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Navbar>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>
                      <Container fluid className='fs-6 d-flex flex-wrap p-0'>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Cliente:</b>
                          <FormControl className='fs-6 bg-white' size='sm' value={documentos.data.nombreCliente} disabled />
                        </div>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Departamento: </b>
                          <FormControl className='fs-6 bg-white' size='sm' value={documentos.data.numeroDepto} disabled />
                        </div>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Medidor: </b>
                          <FormControl className='fs-6 bg-white' size='sm' value={documentos.data.medidor} disabled />
                        </div>
                        <div className='me-1 mb-1'>
                          <b className='text-nowrap'>Tipo tarifa: </b>
                          <FormControl className='fs-6 bg-white' size='sm' value={documentos.data.tipoTarifa} disabled />
                        </div>
                      </Container>
                    </Card.Title>
                    <Table striped hover responsive size='sm' className='h6'>
                      <thead>
                        <tr className='text-center'>
                          <th>Tipo</th>
                          <th>Período</th>
                          <th>Fecha emisión</th>
                          <th>Fecha vencimiento</th>
                          <th>Lectura [kWh]</th>
                          <th>Consumo [kWh]</th>
                          <th>Folio</th>
                          <th>Estado</th>
                          <th>Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentos.documentos.map((documento) =>
                          filtroEstados[estado].estado === documento.estado || estado == 2 ? (
                            <tr key={documento.folio}>
                              <td className='text-center'>{documento.tipo_documento}</td>
                              <td className='text-center'>{documento.periodo}</td>
                              <td className='text-center text-nowrap'>{pintaFecha(documento.fecha_emision)}</td>
                              <td className='text-center text-nowrap'>{pintaFecha(documento.fecha_vencimiento)}</td>
                              <td className='text-end'>{pintaNumero(documento.lectura_actual)}</td>
                              <td className='text-end'>{pintaNumero(documento.consumo)}</td>
                              <td className='text-end'>
                                <Link className='text-decoration-none' to={documento.tipo_documento === 'BOLETA' ? `/boleta/${documento.folio}` : `/factura/${documento.folio}`}>
                                  {pintaNumero(documento.folio)}
                                </Link>
                              </td>
                              <td className='text-center'>{pintaEstado(documento.estado)}</td>
                              <td className='text-end text-nowrap'>{pintaPesos(parseInt(documento.total))}</td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              ) : promiseInProgress ? (
                <CardPlaceHolder />
              ) : (
                <WarningMessage>No se han encontrado documentos para el código de servicio: {codServicio}.</WarningMessage>
              )}
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DocumentosCodServicio;
