import React, { useState, useEffect } from 'react';
import { EPlatContext } from './libreria/EPlatContext';
import { Provider } from 'react-redux';
import { store } from './redux/EPlatStore';
import EPlatHome from './contenedores/EPlatHome';
import LoginHome from './contenedores/LoginHome';
import { Auth } from 'aws-amplify';
import { onError } from './libreria/ErrorHandler';
import { s3getURL } from './libreria/S3Storage';

// Customizaciòn para el uso de MUI Material (https://mui.com/material-ui/getting-started/overview/)
import { createTheme, ThemeProvider } from '@mui/material';

import './App.scss';

// Utilizado para customizar los temas de MUI Material.  Usado en componentes con Autocomplete.
const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat'].join(','),
    fontSize: 13
  }
});

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  // Las siguientes variables serán usadas en EPlatContext.
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [userSession, setUserSession] = useState(null);
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    const onLoad = async () => {
      try {
        await Auth.currentSession();
        setUserAsAuthenticated();
      } catch (e) {
        if (e !== 'No current user') {
          onError(e);
        }
      }
      setIsAuthenticating(false);
    };

    onLoad();
  }, []);

  async function setUserAsAuthenticated() {
    let user = await Auth.currentAuthenticatedUser();
    /** Se setean los valores en EPlatContext. Es importante primero setear la
     * sesion del usuario con setUserSession(), y luego indicar que el usuario
     * está autenticado con userHasAuthenticated(true). */
    setUserSession(user);
    userHasAuthenticated(true);
    /** Se obtiene la imagen del usuario desde AWS S3, con base en el atributo picture */
    let avatar = await s3getURL(user.attributes.picture);
    setAvatar(avatar);
  }

  return (
    !isAuthenticating && (
      <div className='App'>
        <EPlatContext.Provider value={{ isAuthenticated, userHasAuthenticated, userSession, setUserSession, avatar, setAvatar }}>
          {/** Se evalua si el usuario está autenticado. */}
          {isAuthenticated ? (
            /** En caso de estar autenticado EPlatHome */
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <EPlatHome />
              </ThemeProvider>
            </Provider>
          ) : (
            /** En caso de no estar autenticado LoginHome */
            <LoginHome />
          )}
        </EPlatContext.Provider>
      </div>
    )
  );
}

export default App;
