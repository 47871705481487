import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';

function toNumber(valor) {
  return valor ? (typeof valor === 'string' ? parseInt(valor) : valor) : 0;
}

function toFloat(valor) {
  return valor ? (typeof valor === 'string' ? parseFloat(valor) : valor) : 0;
}

export function pintaFecha(fecha) {
  return fecha ? moment(fecha).format('DD-MM-YYYY') : 'Fecha no definida';
}

export function pintaFechaHora(fecha) {
  if (fecha) {
    moment.tz.setDefault('America/Santiago');
    return moment(fecha).format('DD-MM-YYYY HH:mm:ss');
  } else return '';
}

export function pintaNumero(numero) {
  return <NumberFormat value={toNumber(numero)} decimalSeparator={','} thousandSeparator={'.'} decimalScale={2} displayType='text' />;
}

export function pintaNumeroDecimales(numero, qtty) {
  return <NumberFormat value={toNumber(numero)} decimalSeparator={','} thousandSeparator={'.'} decimalScale={qtty} displayType='text' />;
}

export function pintaPesos(numero) {
  return numero ? <NumberFormat value={toNumber(numero)} decimalSeparator={','} thousandSeparator={'.'} displayType='text' prefix={'$ '} /> : '$ 0';
}

export function pintaPesosDecimales(numero) {
  return numero ? <NumberFormat value={toFloat(numero)} decimalScale={3} fixedDecimalScale={false} decimalSeparator={','} thousandSeparator={'.'} displayType='text' prefix={'$ '} /> : '$ 0';
}

export function pintaEstado(estado) {
  return estado === 'ANULADA' ? <b style={{ color: 'red' }}>{estado}</b> : estado;
}

export function pintaPeriodo(periodo) {
  const meses = [
    { id: 1, mes: 'Ene' },
    { id: 2, mes: 'Feb' },
    { id: 3, mes: 'Mar' },
    { id: 4, mes: 'Abr' },
    { id: 5, mes: 'May' },
    { id: 6, mes: 'Jun' },
    { id: 7, mes: 'Jul' },
    { id: 8, mes: 'Ago' },
    { id: 9, mes: 'Sep' },
    { id: 10, mes: 'Oct' },
    { id: 11, mes: 'Nov' },
    { id: 12, mes: 'Dic' }
  ];
  return `${meses.find((item) => item.id === periodo.mes).mes}-${periodo.anno}`;
}

export function pintaOrigenPago(origen) {
  switch (origen) {
    case 'SERVIPAG_ONLINE':
      return 'Servipag';
    case 'SERVIPAG_REPORTE':
      return 'Servipag (Rep.)';
    case 'MANUAL':
      return 'Pago manual';
    case 'SERVIPAG_MULTICANAL':
      return 'Servipag Multicanal';
    case 'SERVIPAG_MULTICANAL_REPORTE':
      return 'Servipag (Multicanal Rep.)';
    default:
      return '[No definido]';
  }
}
