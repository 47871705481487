import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const balanceEnergiaSlice = createSlice({
  name: 'balanceEnergia',
  initialState: {
    energias: [],
    totalEnergia: 0.0,
    totalConsumo: 0.0,
    totalPerdida: 0.0,
    totalPorcentajePerdida: 0.0
  },
  reducers: {
    setBalanceEnergia: (state, action) => {
      state.energias = action.payload.energias;
      state.totalEnergia = action.payload.totalEnergia;
      state.totalConsumo = action.payload.totalConsumo;
      state.totalPerdida = action.payload.totalPerdida;
      state.totalPorcentajePerdida = action.payload.totalPorcentajePerdida;
    }
  }
});

export const { setBalanceEnergia } = balanceEnergiaSlice.actions;

// Action Creators
export const getBalanceEnergia = (comunidadId, inicio, fin) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:balanceEnergia&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setBalanceEnergia(item));
  } else {
    try {
      const response = await API.get('ePlat', `/balance-energia?comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
      dispatch(setBalanceEnergia(response));
      LocalCache.put(`KEY:balanceEnergia&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`, response);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getBalanceEnergia()', error);
    }
  }
};

export default balanceEnergiaSlice.reducer;
