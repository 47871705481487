import React, { useEffect } from 'react';
import { Container, Table, Row, Col, Card, Navbar, Breadcrumb, Dropdown } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pintaNumero, pintaFecha, pintaPesos, pintaPeriodo, pintaOrigenPago } from '../../libreria/utilidades';
import { getPagos } from '../../redux/pagosSlice';
import { getTotalesPagos } from '../../redux/totalesPagosSlice';
import { getPagosPeriodo } from '../../redux/pagosPeriodoSlice';
import { selectPeriodo } from '../../redux/periodoSlice';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import SearchIcon from '@mui/icons-material/Search';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import SearchById from '../../componentes/SearchById';
import PeriodoDropDown from '../../componentes/PeriodoDropDown';
import GraficoPagos from './GraficoPagos';

import './PanelPagos.css';

function PanelPagos() {
  const ROWS_QTTY = 18;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const comunidad = useSelector((store) => store.comunidad);
  const pagos = useSelector((store) => store.pagos);
  const totalesPagos = useSelector((store) => store.totalesPagos);
  const periodo = useSelector((store) => store.periodo);
  const { promiseInProgress: pagosInProgress } = usePromiseTracker({ area: 'area-pagos', delay: 0 });
  const { promiseInProgress: totalesInProgress } = usePromiseTracker({ area: 'area-totalesPagos', delay: 0 });
  const { promiseInProgress: graficoInProgress } = usePromiseTracker({ area: 'area-graficoPagos', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      if (comunidad.isSelected) {
        trackPromise(dispatch(getPagos(comunidad.selected.id, periodo.anno, periodo.mes, 0, ROWS_QTTY)), 'area-pagos');
        trackPromise(dispatch(getTotalesPagos(comunidad.selected.id, periodo.anno, periodo.mes)), 'area-totalesPagos');
        trackPromise(dispatch(getPagosPeriodo(comunidad.selected.id, periodo.anno, periodo.mes)), 'area-graficoPagos');
      }
    };

    onRender();
  }, [comunidad.selected, periodo]);

  function handleCtaCteSearch(value) {
    navigate(`/pago/cta-cte/${value}`);
  }

  function handlePageClick(data) {
    trackPromise(dispatch(getPagos(comunidad.selected.id, periodo.anno, periodo.mes, data.selected, ROWS_QTTY)), 'area-pagos');
  }

  function handlePeriodoClick(data) {
    // El cambio de periodo no accede a base de datos, no es necesario usar trackPromise().
    dispatch(selectPeriodo(data));
  }

  function handleReportesOpcion(eventKey) {
    if (eventKey === 'pagos_periodo') {
      navigate(`/pago/descarga-pagos-periodo`);
    } else if (eventKey === 'saldos_cliente') {
      navigate(`/pago/descarga-saldos-cliente`);
    }
  }

  return (
    <>
      <Container fluid className='PanelPagos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Pagos</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <p className='m-0 me-2 d-none d-sm-inline text-nowrap'>Período (mes/año):</p>
          <PeriodoDropDown className='me-2' onSelect={handlePeriodoClick} value={pintaPeriodo(periodo)} />
        </Navbar>
      </Container>
      <Container fluid className='PanelPagos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <p className='m-0 me-2 d-none d-sm-inline text-nowrap'>Cuenta corriente:</p>
          <SearchById placeholder='Código.serv' btntext='Buscar' onClick={handleCtaCteSearch} />
        </Navbar>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar justify-content-end'>
          <Dropdown onSelect={handleReportesOpcion} className='me-2'>
            <Dropdown.Toggle variant='outline-secondary' size='sm' id='dropdown-cod-servicio'>
              <MenuRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-1' />
              Descarga reportes
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu-end'>
              <Dropdown.Item eventKey='pagos_periodo'>Pagos del período</Dropdown.Item>
              <Dropdown.Item eventKey='saldos_cliente'>Saldos pendientes</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar>
      </Container>
      <Container fluid className='PanelPagos'>
        <Row className='p-2'>
          <Col xs={12} lg={6} xl={5} xxl={4} className='p-2'>
            <Card className='shadow-sm'>
              <LoaderSpinner isLoading={totalesInProgress}>
                <Card.Body>
                  <Card.Title className='fw-bold text-center'>
                    Total pagos período: <i className='fst-normal fw-normal'>{pintaPeriodo(periodo)}</i>
                  </Card.Title>
                  <Card.Text className='fs-6 mb-1 text-primary text-center'>
                    Fecha de vencimiento periodo: <i className='fst-normal fw-bold text-nowrap'>{pintaFecha(totalesPagos.fecha_vencimiento)}</i>
                  </Card.Text>
                  <Table responsive size='sm' className='fs-6 m-0'>
                    <tbody className='text-end'>
                      <tr>
                        <td className='text-nowrap'>Total pagado en plazo ({pintaNumero(totalesPagos.qtty_plazo)} pagos):</td>
                        <td className='text-nowrap'>{pintaPesos(totalesPagos.total_plazo)}</td>
                      </tr>
                      <tr>
                        <td className='text-nowrap'>Total fuera de plazo ({pintaNumero(totalesPagos.qtty_fuera_plazo)} pagos):</td>
                        <td className='text-nowrap'>{pintaPesos(totalesPagos.total_fuera_plazo)}</td>
                      </tr>
                      <tr>
                        <td className='text-nowrap'>Total pagado ({pintaNumero(totalesPagos.qtty)} pagos):</td>
                        <td className='fw-bold text-nowrap'>{pintaPesos(totalesPagos.total)}</td>
                      </tr>
                      <tr>
                        <td className='text-nowrap'>Pendiente de pago (de {pintaPesos(totalesPagos.total_facturado)}):</td>
                        <td className='fw-bold text-nowrap'>{pintaPesos(totalesPagos.total_facturado - totalesPagos.total)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
          <Col xs={12} lg={6} xl={5} xxl={4} className='p-2'>
            <LoaderSpinner isLoading={graficoInProgress}>
              <GraficoPagos />
            </LoaderSpinner>
          </Col>
          <Col xs={12} xxl={10} className='p-2'>
            <Card className='shadow-sm'>
              <Card.Header className='d-flex flex-wrap p-0'>
                <Navbar className='flex-fill'>
                  <Card.Title className='fs-4 m-0 ms-3 fw-bold'>
                    Pagos del período: <i className='fst-normal fw-normal'>{pintaPeriodo(periodo)}</i>
                  </Card.Title>
                </Navbar>
                <Navbar className='flex-fill justify-content-end'>
                  <ReactPaginate
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'..'}
                    pageCount={Math.ceil(pagos.count / ROWS_QTTY)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    forcePage={pagos.page}
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination pagination-sm m-0 me-2'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </Navbar>
              </Card.Header>
              <LoaderSpinner isLoading={pagosInProgress}>
                <Card.Body>
                  <Table striped hover responsive size='sm' className='h6'>
                    <thead>
                      <tr className='text-center'>
                        <th>Código servicio</th>
                        <th>Depto.</th>
                        <th>Medidor</th>
                        <th>Tipo tarifa</th>
                        <th>Nombre cliente</th>
                        <th>Fecha pago</th>
                        <th>Folio docto.</th>
                        <th>Fecha vencimiento</th>
                        <th>Origen</th>
                        <th>Monto</th>
                        <th>Cuenta corriente</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pagos.rows.map((pago) => (
                        <tr key={pago.folio}>
                          <td className='text-center'>{pintaNumero(pago.codigo_servicio)}</td>
                          <td>{pago.numero_depto}</td>
                          <td className='text-center'>
                            <Link className='text-decoration-none' to={'/medidor/' + pago.medidor_id}>
                              {pago.medidor_id}
                            </Link>
                          </td>
                          <td className='text-center'>{pago.tipo_tarifa}</td>
                          <td className='text-center text-nowrap'>{pago.business_partner}</td>
                          <td className='text-center text-nowrap'>{pintaFecha(pago.fecha_pago)}</td>
                          <td className='text-end'>
                            <Link className='text-decoration-none' to={`/boleta/${pago.folio}`}>
                              {pintaNumero(pago.folio)}
                            </Link>
                          </td>
                          <td className='text-center text-nowrap'>{pintaFecha(pago.fecha_vencimiento)}</td>
                          <td className='text-nowrap'>{pintaOrigenPago(pago.origen)}</td>
                          <td className='text-end text-nowrap'>{pintaPesos(pago.monto)}</td>
                          <td className='text-end'>
                            <Link to={`/pago/cta-cte/${pago.codigo_servicio}`} className='link-primary fw-light text-decoration-none text-nowrap'>
                              Ver cuenta corriente<button className='ms-1 button-link'>...</button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PanelPagos;
