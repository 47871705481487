import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';

function WarningMessage(props) {
  return (
    <>
      <Card className='shadow-sm' style={{ maxWidth: '540px' }}>
        <Card.Body>
          <Table borderless className='m-0'>
            <tbody>
              <tr className='text-center align-middle'>
                <td>
                  <Image fluid src={`${process.env.PUBLIC_URL}/images/warning-icon.png`} style={{ minWidth: '40px' }} />
                </td>
                <td>
                  <Card.Title className='fs-3 fw-normal text-dark'>{props.children}</Card.Title>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}

export default WarningMessage;
