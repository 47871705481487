import React, { useEffect } from 'react';
import { Container, Table, Row, Col, Card, Navbar, Breadcrumb, FormControl } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Autocomplete } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { pintaNumero } from '../../libreria/utilidades';
import { getUnidades } from '../../redux/unidadesSlice';
import { getFotos } from '../../redux/comunidadFotosCarouselSlice';
import SearchIcon from '@mui/icons-material/Search';
import Map from './Map';
import FotosCarousel from './FotosCarousel';
import './PanelComunidad.css';

function PanelComunidad() {
  
  const ROWS_QTTY = 18;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const comunidad = useSelector((store) => store.comunidad);
  const numDeptos = comunidad.selected.numDeptos;
  const unidades = useSelector((store) => store.unidades);
  const { isSelected, selected } = comunidad;
  const { promiseInProgress: comunidadInProgress } = usePromiseTracker({ area: 'area-comunidad', delay: 0 });
  const { promiseInProgress: comunidadFotosCarouselInProgress } = usePromiseTracker({ area: 'area-fotos-carousel', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      if (isSelected) {
        trackPromise(dispatch(getUnidades(selected.id, 0, ROWS_QTTY)), 'area-comunidad');
        trackPromise(dispatch(getFotos(selected.id, selected.direccion.folder_carousel)), 'area-fotos-carousel');
      }
    };

    onRender();
  }, [selected]);

  const nombre = () => (isSelected ? selected.nombre : '');
  const direccion = () => (isSelected ? selected.direccion.direccion : '');
  const latitud = () => (isSelected ? selected.direccion.foto_mapa.lat : -33.4246733);
  const longitud = () => (isSelected ? selected.direccion.foto_mapa.lng : -70.657467);
  const email = () => (isSelected ? selected.email : '');

  function handleMedidorChange(event, newValue) {
    if (newValue) {
      let numDepto = numDeptos.find((item) => item === newValue);
      if (numDepto) {
        navigate(`/comunidad/departamento/${numDepto}`);
      }
    }
  }

  function handlePageClick(data) {
    trackPromise(dispatch(getUnidades(selected.id, data.selected, ROWS_QTTY)), 'area-comunidad');
  }

  return (
    <>
      <Container fluid className='PanelComunidad d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión de la comunidad
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Comunidad</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='PanelComunidad d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <Autocomplete
            className='autocomplete bg-white'
            sx={{ width: 200 }}
            size='small'
            options={numDeptos}
            onChange={handleMedidorChange}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <FormControl {...params.inputProps} className='fst-normal bg-white' size='sm' placeholder='Numero depto.' />
              </div>
            )}
          />
        </Navbar>
      </Container>
      <Container fluid className='PanelComunidad'>
        <Row className='p-2'>
          <Col xs={12} md={11} lg={9} xl={8} xxl={6} className='p-2'>
            <Card className='shadow-sm'>
              <Card.Body>
                <Card.Title className='fw-bold'>Comunidad: {nombre()}</Card.Title>
                <Table borderless responsive className='fs-6 m-0' size='sm'>
                  <tbody>
                    <tr>
                      <td className='p-0 fw-bold text-nowrap ancho-15'>Dirección:</td>
                      <td className='p-0 text-nowrap'>{direccion()}</td>
                    </tr>
                    <tr>
                      <td className='p-0 fw-bold text-nowrap ancho-15'>e-mail:</td>
                      <td className='p-0 text-nowrap'>{email()}</td>
                    </tr>
                    <tr>
                      <td colSpan='2' className='p-0'>
                        <Row className='m-0'>
                          <Col xs={12} sm={6} className='p-0 pe-sm-1'>
                            <Map className='bg-light mt-2' lat={latitud()} lng={longitud()} />
                          </Col>
                          <Col xs={12} sm={6} className='p-0 ps-sm-1'>
                            <LoaderSpinner isLoading={comunidadFotosCarouselInProgress}>
                              {comunidadFotosCarouselInProgress ? (
                                <div className='d-flex align-items-center justify-content-center rectangle-placeholder fs-4 mt-2'>Comunidad: {nombre()}</div>
                              ) : (
                                <FotosCarousel className='bg-light mt-2' caption={direccion()} />
                              )}
                            </LoaderSpinner>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} xl={11} xxl={9} className='p-2'>
            <Card className='shadow-sm'>
              <Card.Header className='d-flex flex-wrap p-0'>
                <Navbar className='flex-fill'>
                  <Card.Title className='m-0 ms-3 fs-4 fw-bold'>Unidades - departamentos</Card.Title>
                </Navbar>
                <Navbar className='flex-fill justify-content-end'>
                  <ReactPaginate
                    previousLabel={'«'}
                    nextLabel={'»'}
                    breakLabel={'..'}
                    pageCount={Math.ceil(unidades.count / ROWS_QTTY)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    forcePage={unidades.page}
                    renderOnZeroPageCount={null}
                    containerClassName={'pagination pagination-sm m-0 me-2'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </Navbar>
              </Card.Header>
              <LoaderSpinner isLoading={comunidadInProgress}>
                <Card.Body>
                  <Table striped hover responsive size='sm' className='h6'>
                    <thead>
                      <tr className='text-center'>
                        <th>Código servicio</th>
                        <th>Departamento</th>
                        <th>Piso</th>
                        <th>Medidor</th>
                        <th>Business partner</th>
                        <th>Tipo tarifa</th>
                        <th>Último docto.</th>
                        <th>Tipo docto.</th>
                        <th>Última lectura [kWh]</th>
                        <th>Último consumo [kWh]</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unidades.rows.map((unidad) => (
                        <tr key={unidad.id}>
                          <td className='text-center'>{pintaNumero(unidad.codigo_servicio)}</td>
                          <td className='text-nowrap'>
                            <Link className='text-decoration-none' to={'/comunidad/departamento/' + unidad.numero}>
                              {unidad.numero}
                            </Link>
                          </td>
                          <td className='text-center text-nowrap'>{unidad.torre ? unidad.torre + '-' + unidad.piso : unidad.piso}</td>
                          <td className='text-center'>
                            <Link className='text-decoration-none' to={'/medidor/' + unidad.medidor}>
                              {unidad.medidor}
                            </Link>
                          </td>
                          <td className='text-center text-nowrap'>{unidad.business_partner}</td>
                          <td className='text-center'>{unidad.tipo_tarifa}</td>
                          <td className='text-end'>
                            <Link className='text-decoration-none' to={unidad.tipo_documento === 'BOLETA' ? `/boleta/${unidad.folio}` : `/factura/${unidad.folio}`}>
                              {pintaNumero(unidad.folio)}
                            </Link>
                          </td>
                          <td className='text-center'>{unidad.tipo_documento}</td>
                          <td className='text-end'>{pintaNumero(unidad.lectura_periodo)}</td>
                          <td className='text-end'>{pintaNumero(unidad.consumo_periodo)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PanelComunidad;
