import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const downloadDocumentosSlice = createSlice({
  name: 'downloadDocumentos',
  initialState: {
    rows: []
  },
  reducers: {
    setDocumentosToDownload: (state, action) => {
      state.rows = action.payload;
    }
  }
});

export const { setDocumentosToDownload } = downloadDocumentosSlice.actions;

// Action Creators
export const downloadDocumentos = (comunidadId, anno, mes) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:download-documentos&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
  if (item) {
    dispatch(setDocumentosToDownload(item));
  } else {
    try {
      const response = await API.get('ePlat', `/download-documentos?comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
      dispatch(setDocumentosToDownload(response));
      LocalCache.put(`KEY:download-documentos&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`, response);
    } catch (error) {
      console.log('DVD: ERROR Llamada a downloadDocumentos()', error);
    }
  }
};

export default downloadDocumentosSlice.reducer;
