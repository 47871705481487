import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import WarningMessage from '../componentes/WarningMessage';

function NotFoundPanel() {
  return (
    <Container fluid>
      <Row className='p-2'>
        <Col xs={12} md={12} className='p-2'>
          <WarningMessage>No se ha encontrado la página solicitada</WarningMessage>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFoundPanel;
