import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainPanel from '../paneles/MainPanel';
import PanelRentabilidad from '../paneles/rentabilidad/PanelRentabilidad';
import PanelBalanceEnergia from '../paneles/balance/PanelBalanceEnergia';
import PanelCompraEnergia from '../paneles/compra/PanelCompraEnergia';
import PanelVentaEnergia from '../paneles/venta/PanelVentaEnergia';
import PanelProcesoCierre from '../paneles/cierre/PanelProcesoCierre';
import PanelComunidad from '../paneles/comunidad/PanelComunidad';
import Departamento from '../paneles/comunidad/Departamento';
import PanelDocumentos from '../paneles/documentos/PanelDocumentos';
import DescargaSabana from '../paneles/documentos/DescargaSabana';
import DescargaDocumentos from '../paneles/documentos/DescargaDocumentos';
import PanelPagos from '../paneles/pagos/PanelPagos';
import DescargaPagosPeriodo from '../paneles/pagos/DescargaPagosPeriodo';
import DescargaSaldosCliente from '../paneles/pagos/DescargaSaldosCliente';
import CuentaCorriente from '../paneles/pagos/CuentaCorriente';
import DescargaCuentaCorriente from '../paneles/pagos/DescargaCuentaCorriente';
import DocumentosFolio from '../paneles/documentos/DocumentosFolio';
import BoletaFolio from '../paneles/boletas/BoletaFolio';
import FacturaFolio from '../paneles/facturas/FacturaFolio';
import DocumentosCodServicio from '../paneles/documentos/DocumentosCodServicio';
import PanelMedidor from '../paneles/medidor/PanelMedidor';
import Medidor from '../paneles/medidor/Medidor';
import PanelPerfil from '../paneles/perfil/PanelPerfil';
import NotFoundPanel from '../paneles/NotFoundPanel';
import Tarifas from '../paneles/cierre/Tarifas';
import GeneracionDocumentos from '../paneles/cierre/GeneracionDocumentos';
import PanelCambioClave from '../paneles/PanelCambioClave';

export default function EPlatMain() {
  return (
    <main className='eplat-remain-altura flex-grow-1 overflow-auto'>
      <Routes>
        <Route path='/' element={<MainPanel />} />
        <Route path='/rentabilidad' element={<PanelRentabilidad />} />
        <Route path='/balance' element={<PanelBalanceEnergia />} />
        <Route path='/compra-energia' element={<PanelCompraEnergia />} />
        <Route path='/venta-energia' element={<PanelVentaEnergia />} />
        <Route path='/proceso-cierre' element={<PanelProcesoCierre />} />
        <Route path='/proceso-cierre/tarifas' element={<Tarifas />} />
        <Route path='/proceso-cierre/generacion-documentos' element={<GeneracionDocumentos />} />
        <Route path='/comunidad' element={<PanelComunidad />} />
        <Route path='/comunidad/departamento' element={<Navigate to='/comunidad' replace />} />
        <Route path='/comunidad/departamento/:numDepto' element={<Departamento />} />
        <Route path='/medidor' element={<PanelMedidor />} />
        <Route path='/medidor/:numeroMedidor' element={<Medidor />} />
        <Route path='/documento' element={<PanelDocumentos />} />
        <Route path='/documento/cod-servicio' element={<Navigate to='/documento' replace />} />
        <Route path='/documento/cod-servicio/:codServicio' element={<DocumentosCodServicio />} />
        <Route path='/documento/folio' element={<Navigate to='/documento' replace />} />
        <Route path='/documento/folio/:folio' element={<DocumentosFolio />} />
        <Route path='/documento/descarga-tarifas-facturacion' element={<DescargaSabana />} />
        <Route path='/documento/descarga-documentos' element={<DescargaDocumentos />} />
        <Route path='/boleta' element={<Navigate to='/documento' replace />} />
        <Route path='/boleta/:folio' element={<BoletaFolio />} />
        <Route path='/factura' element={<Navigate to='/documento' replace />} />
        <Route path='/factura/:folio' element={<FacturaFolio />} />
        <Route path='/pago' element={<PanelPagos />} />
        <Route path='/pago/cta-cte' element={<Navigate to='/pago' replace />} />
        <Route path='/pago/cta-cte/:codServicio' element={<CuentaCorriente />} />
        <Route path='/pago/cta-cte/descarga-cuenta-corriente' element={<Navigate to='/pago' replace />} />
        <Route path='/pago/cta-cte/descarga-cuenta-corriente/:codServicio' element={<DescargaCuentaCorriente />} />
        <Route path='/pago/descarga-pagos-periodo' element={<DescargaPagosPeriodo />} />
        <Route path='/pago/descarga-saldos-cliente' element={<DescargaSaldosCliente />} />
        <Route path='/perfil' element={<PanelPerfil />} />
        <Route path='/clave' element={<PanelCambioClave />} />
        <Route path='*' element={<NotFoundPanel />} />
      </Routes>
    </main>
  );
}
