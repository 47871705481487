import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Breadcrumb, Table, Image, Row, Col, Card, Button } from 'react-bootstrap';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { downloadSaldos } from '../../redux/downloadSaldosSlice';
import { pintaFechaHora } from '../../libreria/utilidades';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function DescargaSaldosCliente() {
  const comunidad = useSelector((store) => store.comunidad);
  const saldosToDownload = useSelector((store) => store.downloadSaldos);

  const dispatch = useDispatch();
  const { promiseInProgress: downloadSaldosInProgress } = usePromiseTracker({ area: 'download-saldos', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(downloadSaldos(comunidad.selected.id)), 'download-saldos');
    };

    onRender();
  }, [comunidad.selected]);

  function toNumber(valor) {
    return valor ? (typeof valor === 'string' ? parseInt(valor) : valor) : 0;
  }

  function handleClickDescarga() {
    const fileName = `ePlat-Com.${comunidad.selected.id}-SaldosCliente.xlsx`;
    let data = [];
    for (let i = 0; i < saldosToDownload.rows.length; i++) {
      let row = new Object();
      row.comunidad_id = saldosToDownload.rows[i].comunidad_id;
      row.codido_servicio = saldosToDownload.rows[i].codigo_servicio;
      row.numero_depto = saldosToDownload.rows[i].numero;
      row.medidor_id = saldosToDownload.rows[i].medidor_id;
      row.business_partner = saldosToDownload.rows[i].business_partner;
      row.tipo_tarifa = saldosToDownload.rows[i].tipo_tarifa;
      row.folio_documento = saldosToDownload.rows[i].folio;
      row.periodo = saldosToDownload.rows[i].periodo;
      row.fecha_emision = pintaFechaHora(saldosToDownload.rows[i].fecha_emision);
      row.fecha_vencimiento = pintaFechaHora(saldosToDownload.rows[i].fecha_vencimiento);
      row.lectura_ultimo_periodo = toNumber(saldosToDownload.rows[i].lectura_periodo);
      row.consumo_ultimo_periodo = toNumber(saldosToDownload.rows[i].consumo_periodo);
      row.monto_documento = toNumber(saldosToDownload.rows[i].monto_documento);
      row.fecha_pago = saldosToDownload.rows[i].fecha_pago ? pintaFechaHora(saldosToDownload.rows[i].fecha_pago) : '';
      row.monto_pago = toNumber(saldosToDownload.rows[i].monto_pago);
      row.saldo = row.monto_documento - row.monto_pago;
      data.push(row);
    }
    let header = [
      'comunidad',
      'codigo_servicio',
      'numero_depto',
      'medidor',
      'business_partner',
      'tipo_tarifa',
      'folio_documento',
      'periodo',
      'fecha_emision',
      'fecha_vencimiento',
      'lectura_ultimo_periodo',
      'consumo_ultimo_periodo',
      'monto_documento',
      'fecha_pago',
      'monto_pago',
      'saldo_pendiente'
    ];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
    const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(finalData, fileName);
  }

  return (
    <>
      <Container fluid className='PanelPagos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/pago' className='text-decoration-none'>
                  Pagos
                </Link>
              </li>
              <Breadcrumb.Item active>Descarga saldos pendientes</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid>
        <Row className='p-2'>
          <Col xs={12} md={12} className='p-2'>
            <Card className='shadow-sm' style={{ maxWidth: '540px' }}>
              <LoaderSpinner isLoading={downloadSaldosInProgress}>
                <Card.Body>
                  <Table borderless className='m-0'>
                    <tbody>
                      <tr className='text-center align-middle'>
                        <td>
                          <Image fluid src={`${process.env.PUBLIC_URL}/images/success-icon.png`} style={{ minWidth: '40px' }} />
                        </td>
                        <td className='p-0'>
                          <Table borderless className='m-0'>
                            <tbody>
                              <tr>
                                <td>
                                  <Card.Title className='fs-3 fw-normal text-dark'>Se ha generado el reporte de detalle de saldos pendientes por cliente</Card.Title>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Button size='sm' className='fs-6 btn-outline-secondary text-nowrap btn-actualizar' variant='white' onClick={handleClickDescarga}>
                                    <DownloadRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />
                                    Descargar
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DescargaSaldosCliente;
