import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Breadcrumb, Table, Image, Row, Col, Card, Button } from 'react-bootstrap';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { downloadSabana } from '../../redux/downloadSabanaSlice';
import { pintaFechaHora, pintaPeriodo } from '../../libreria/utilidades';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function DescargaSabana() {
  const comunidad = useSelector((store) => store.comunidad);
  const periodo = useSelector((store) => store.periodo);
  const sabana = useSelector((store) => store.downloadSabana);

  const dispatch = useDispatch();
  const { promiseInProgress: downloadSabanaProgress } = usePromiseTracker({ area: 'download-sabana', delay: 0 });

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(downloadSabana(comunidad.selected.id, periodo.anno, periodo.mes)), 'download-sabana');
    };

    onRender();
  }, [comunidad.selected]);

  function handleClickDescarga() {
    const fileName = `ePlat-Com.${comunidad.selected.id}-TarifasFacturacion-${pintaPeriodo(periodo)}.xlsx`;
    let data = [];
    for (let i = 0; i < sabana.rows.length; i++) {
      let row = new Object();
      row.comunidad_id = sabana.rows[i].comunidad_id;
      row.periodo = sabana.rows[i].periodo;
      row.codido_servicio = sabana.rows[i].codigo_servicio;
      row.numero_depto = sabana.rows[i].numero_depto;
      row.medidor_id = sabana.rows[i].medidor_id;
      row.tipo_tarifa = sabana.rows[i].tipo_tarifa;
      row.business_partner = sabana.rows[i].business_partner;
      row.mes_lectura = sabana.rows[i].mes_lectura;
      row.ano_lectura = sabana.rows[i].ano_lectura;
      row.fecha_inicio_lectura = pintaFechaHora(sabana.rows[i].fecha_inicio_lectura);
      row.fecha_fin_lectura = pintaFechaHora(sabana.rows[i].fecha_fin_lectura);
      row.lectura_periodo = sabana.rows[i].lectura_periodo;
      row.lectura_periodo_anterior = sabana.rows[i].lectura_periodo_anterior;
      row.consumo_periodo = sabana.rows[i].consumo_periodo;
      row.energia = sabana.rows[i].energia;
      row.energia_invierno = sabana.rows[i].energia_invierno;
      row.energia_iva = sabana.rows[i].energia_iva;
      row.energia_invierno_iva = sabana.rows[i].energia_invierno_iva;
      row.limite_invierno = sabana.rows[i].limite_invierno;
      row.demanda_suministrada_leida = sabana.rows[i].demanda_suministrada_leida;
      row.demanda_suministrada_facturada = sabana.rows[i].demanda_suministrada_facturada;
      row.demanda_hora_punta_leida = sabana.rows[i].demanda_hora_punta_leida;
      row.demanda_hora_punta_facturada = sabana.rows[i].demanda_hora_punta_facturada;
      row.transmision = sabana.rows[i].transmision;
      row.transmision_iva = sabana.rows[i].transmision_iva;
      row.cargo_arriendo_medidor = sabana.rows[i].cargo_arriendo_medidor;
      row.cargo_arriendo_medidor_iva = sabana.rows[i].cargo_arriendo_medidor_iva;
      row.cargo_fijo = sabana.rows[i].cargo_fijo;
      row.cargo_fijo_iva = sabana.rows[i].cargo_fijo_iva;
      row.cargo_corte_repo = sabana.rows[i].cargo_corte_repo;
      row.cargo_corte_repo_iva = sabana.rows[i].cargo_corte_repo_iva;
      row.cargo_servicio_publico = sabana.rows[i].cargo_servicio_publico;
      row.demanda_suministrada = sabana.rows[i].demanda_suministrada;
      row.demanda_suministrada_iva = sabana.rows[i].demanda_suministrada_iva;
      row.demanda_horapunta = sabana.rows[i].demanda_horapunta;
      row.demanda_horapunta_iva = sabana.rows[i].demanda_horapunta_iva;
      row.lectura_react_periodo = sabana.rows[i].lectura_react_periodo;
      row.lectura_react_periodo_anterior = sabana.rows[i].lectura_react_periodo_anterior;
      row.reactiva_periodo = sabana.rows[i].reactiva_periodo;
      row.rec_mfp = sabana.rows[i].rec_mfp;
      row.rec_mfp_iva = sabana.rows[i].rec_mfp_iva;
      row.total_mes_afecto = sabana.rows[i].total_mes_afecto;
      row.total_mes_exento = sabana.rows[i].total_mes_exento;
      row.interes_mes_devengado = sabana.rows[i].interes_mes_devengado;
      row.interes_mes_devengado_iva = sabana.rows[i].interes_mes_devengado_iva;
      row.cargo_fuera_plazo = sabana.rows[i].cargo_fuera_plazo;
      row.cargo_fuera_plazo_iva = sabana.rows[i].cargo_fuera_plazo_iva;
      row.saldo_mes_anterior = sabana.rows[i].saldo_mes_anterior;
      row.iva_mes = sabana.rows[i].iva_mes;
      row.total_iva = sabana.rows[i].total_iva;
      row.total_mes_neto = sabana.rows[i].total_mes_neto;
      row.total_a_pagar = sabana.rows[i].total_a_pagar;
      row.fecha_emision = pintaFechaHora(sabana.rows[i].fecha_emision);
      row.fecha_vencimiento = pintaFechaHora(sabana.rows[i].fecha_vencimiento);
      row.tipo_documento = sabana.rows[i].tipo_documento;
      row.folio = sabana.rows[i].folio;
      row.link_pdf = sabana.rows[i].tipo_documento === 'BOLETA' ? sabana.rows[i].pdf_boleta : sabana.rows[i].pdf_factura;
      data.push(row);
    }
    let header = [
      'comunidad',
      'periodo',
      'codigo_servicio',
      'numero_depto',
      'medidor',
      'tipo_tarifa',
      'business_partner',
      'mes_lectura',
      'año_lectura',
      'fecha_inicio_lectura',
      'fecha_fin_lectura',
      'lectura_periodo',
      'lectura_periodo_anterior',
      'consumo_periodo',
      'energía',
      'energia_invierno',
      'energía_iva',
      'energia_invierno_iva',
      'limite_invierno',
      'demanda_suministrada_leída',
      'demanda_suministrada_facturada',
      'demanda_hora_punta_leída',
      'demanda_hora_punta_facturada',
      'transmisión',
      'transmisión_iva',
      'cargo_arriendo_medidor',
      'cargo_arriendo_medidor_iva',
      'cargo_fijo',
      'cargo_fijo_iva',
      'cargo_corte_repo',
      'cargo_corte_repo_iva',
      'cargo_servicio_publico',
      'demanda_suministrada',
      'demanda_suministrada_iva',
      'demanda_horapunta',
      'demanda_horapunta_iva',
      'lectura_react_periodo',
      'lectura_react_periodo_anterior',
      'reactiva_periodo',
      'rec_mfp',
      'rec_mfp_iva',
      'total_mes_afecto',
      'total_mes_exento',
      'interes_mes_devengado',
      'interes_mes_devengado_iva',
      'cargo_fuera_plazo',
      'cargo_fuera_plazo_iva',
      'saldo_mes_anterior',
      'iva_mes',
      'total_iva',
      'total_mes_neto',
      'total_a_pagar',
      'fecha_emision',
      'fecha_vencimiento',
      'tipo_documento',
      'folio_boleta',
      'link_pdf_boleta'
    ];
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [header]);
    XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true });
    const finalData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(finalData, fileName);
  }

  return (
    <>
      <Container fluid className='PanelPagos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/documento' className='text-decoration-none'>
                  Facturas y boletas
                </Link>
              </li>
              <Breadcrumb.Item active>Descarga tarifas y facturación período {pintaPeriodo(periodo)}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid>
        <Row className='p-2'>
          <Col xs={12} md={12} className='p-2'>
            <Card className='shadow-sm' style={{ maxWidth: '540px' }}>
              <LoaderSpinner isLoading={downloadSabanaProgress}>
                <Card.Body>
                  <Table borderless className='m-0'>
                    <tbody>
                      <tr className='text-center align-middle'>
                        <td>
                          <Image fluid src={`${process.env.PUBLIC_URL}/images/success-icon.png`} style={{ minWidth: '40px' }} />
                        </td>
                        <td className='p-0'>
                          <Table borderless className='m-0'>
                            <tbody>
                              <tr>
                                <td>
                                  <Card.Title className='fs-3 fw-normal text-dark'>Se ha generado el reporte de tarifas y facturación para el período: {pintaPeriodo(periodo)}</Card.Title>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <Button size='sm' className='fs-6 btn-outline-secondary text-nowrap btn-actualizar' variant='white' onClick={handleClickDescarga}>
                                    <DownloadRoundedIcon sx={{ fontSize: 16 }} className='icono-centrado me-2' />
                                    Descargar
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </LoaderSpinner>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DescargaSabana;
