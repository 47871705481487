import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, defaults } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { rangos } from '../../componentes/RangoPeriodos';
import moment from 'moment';

defaults.font.family = 'Montserrat';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);

function GraficoLecturasComunidad(props) {
  const { lecturas } = useSelector((store) => store.totalesMedidores);
  function getPeriodo(fecha) {
    let ano = fecha.year();
    let mes = fecha.month() + 1;
    return ano.toString() + '-' + (mes < 10 ? `0${mes.toString()}` : mes.toString());
  }
  /** Se construirán las series de datos sobre la base de el rango de periodos seleccionado. */
  let serieConsumo = [];
  let labels = [];
  let colorConsumo = [];
  let rangoSelected = props.rango;
  let mesIndex = moment(rangos[rangoSelected].inicio);
  let mesFin = moment(rangos[rangoSelected].fin);
  let iteraciones = mesFin.diff(mesIndex, 'months') + 1;
  iteraciones = iteraciones > 13 ? 13 : iteraciones;
  for (let i = 0; i < iteraciones; i++) {
    let periodo = getPeriodo(mesIndex);
    let periodoLectura = lecturas.find((item) => item.periodo === periodo);
    let consumo = periodoLectura ? periodoLectura.consumo : 0;
    serieConsumo.push(consumo);
    colorConsumo.push('rgb(31,78,121,0.65)');
    labels.push(periodo);
    mesIndex.add(1, 'months');
  }
  /** Ahora se realiza la configuración del grafico. */
  const options = {
    responsive: true,
    animation: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Períodos',
          padding: 1,
          font: { size: 10 }
        },
        ticks: {
          padding: 1,
          maxRotation: 0,
          font: {
            size: 8,
            lineHeight: 1
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Consumo [kWh]',
          padding: 1,
          font: { size: 9 }
        },
        ticks: {
          padding: 2,
          font: { size: 10 }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: `Consumo energía activa: ${rangos[rangoSelected].opcion}`
      }
    },
    elements: {
      point: {
        radius: 2
      },
      line: {
        borderWidth: 1,
        borderColor: '#6d757c'
      }
    }
  };
  /** Ahora se carga etiquetas, datos y colores en el gráfico. */
  const datos = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Consumo',
        data: serieConsumo,
        backgroundColor: colorConsumo
      }
    ]
  };

  return <Line {...props} options={options} data={datos} />;
}

export default GraficoLecturasComunidad;
