import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col, Card, Navbar, Breadcrumb, FormControl, Dropdown } from 'react-bootstrap';
import { useParams, useNavigate, Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentosFolio } from '../../redux/documentosFolioSlice';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoaderSpinner from '../../libreria/LoaderSpinner';
import { pintaFecha, pintaNumero, pintaPesos, pintaEstado } from '../../libreria/utilidades';
import CardPlaceHolder from '../../componentes/CardPlaceHolder';
import WarningMessage from '../../componentes/WarningMessage';
import SearchIcon from '@mui/icons-material/Search';
import SearchById from '../../componentes/SearchById';
import './DocumentosFolio.css';

function DocumentosFolio() {
  const { folio } = useParams();
  const comunidad = useSelector((store) => store.comunidad);
  const documentos = useSelector((store) => store.documentosFolio);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker({ delay: 0 });

  useEffect(() => {
    const onRender = () => {
      trackPromise(dispatch(getDocumentosFolio(comunidad.selected.id, folio)));
    };

    onRender();
  }, [comunidad.selected, folio]);

  function handleDocumentoSearch(value) {
    navigate(`/documento/folio/${Number(value)}`);
  }

  return (
    <>
      <Container fluid className='DocumentosFolio d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='white' className='flex-fill border-bottom altura-navbar'>
          <Container fluid className='ms-4'>
            <Breadcrumb className='h4 fw-bold m-0' listProps={{ className: 'm-0' }}>
              <Breadcrumb.Item active className='d-none d-lg-inline text-secondary'>
                Gestión del servicio
              </Breadcrumb.Item>
              <li className='breadcrumb-item d-none d-lg-inline text-primary'>
                <Link to='/documento' className='text-decoration-none'>
                  Facturas y boletas
                </Link>
              </li>
              <Breadcrumb.Item active>Folio {pintaNumero(folio)}</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </Navbar>
      </Container>
      <Container fluid className='PanelDocumentos d-flex flex-wrap p-0 shadow-sm'>
        <Navbar bg='warning' className='flex-fill border-bottom altura-navbar'>
          <SearchIcon className='icono-centrado ms-3' />
          <i className='ms-1 fw-bold fst-normal me-1 d-none d-md-inline'>Búsqueda</i>
          <div className='vr mx-2'></div>
          <SearchById className='me-2' placeholder='Folio docto.' btntext='Buscar' onClick={handleDocumentoSearch} />
        </Navbar>
      </Container>
      <Container fluid>
        <Row className='p-2'>
          <Col xs={12} xl={11} xxl={9} className='p-2'>
            <LoaderSpinner isLoading={promiseInProgress}>
              {documentos ? (
                <Card className='shadow-sm'>
                  <Card.Header className='bg-light d-flex flex-wrap p-0'>
                    <Navbar className='flex-fill'>
                      <Card.Title className='fs-4 m-0 ms-3 fw-bold'>
                        Documento Folio: <i className='fst-normal fw-normal'>{pintaNumero(folio)}</i>
                      </Card.Title>
                    </Navbar>
                  </Card.Header>
                  <Card.Body>
                    <Table striped hover responsive size='sm' className='h6'>
                      <thead>
                        <tr className='text-center'>
                          <th>Tipo</th>
                          <th>Período</th>
                          <th>Fecha emisión</th>
                          <th>Fecha vencimiento</th>
                          <th>Lectura [kWh]</th>
                          <th>Consumo [kWh]</th>
                          <th>Folio</th>
                          <th>Estado</th>
                          <th>Monto</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentos.rows.map((documento) => (
                          <tr key={documento.documento_id}>
                            <td className='text-center'>{documento.tipo_documento}</td>
                            <td className='text-center'>{documento.periodo}</td>
                            <td className='text-center text-nowrap'>{pintaFecha(documento.fecha_emision)}</td>
                            <td className='text-center text-nowrap'>{pintaFecha(documento.fecha_vencimiento)}</td>
                            <td className='text-end'>{pintaNumero(documento.lectura_actual)}</td>
                            <td className='text-end'>{pintaNumero(documento.consumo)}</td>
                            <td className='text-end'>
                              <Link className='text-decoration-none' to={documento.tipo_documento === 'BOLETA' ? `/boleta/${documento.folio}` : `/factura/${documento.folio}`}>
                                {pintaNumero(documento.folio)}
                              </Link>
                            </td>
                            <td className='text-center'>{pintaEstado(documento.estado)}</td>
                            <td className='text-end text-nowrap'>{pintaPesos(parseInt(documento.total))}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              ) : promiseInProgress ? (
                <CardPlaceHolder />
              ) : (
                <WarningMessage>No se han encontrado documentos para el folio: {folio}.</WarningMessage>
              )}
            </LoaderSpinner>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DocumentosFolio;
