import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import './SearchById.css';

const ahora = moment(),
  currentYear = moment(),
  lastYear = moment().subtract(1, 'years');

export const rangos = [
  {
    id: 0,
    opcion: 'Últimos 13 meses',
    // Se restan trece meses, y no doce, porque siempre estamos mirando un mes atras, ya que la facturacion es a mes vencido.
    inicio: ahora.clone().subtract(13, 'months').startOf('month').format(),
    fin: ahora.clone().endOf('month').format()
  },
  {
    id: 1,
    opcion: `Año ${currentYear.year()}`,
    inicio: currentYear.clone().startOf('year').format(),
    fin: currentYear.clone().endOf('year').format()
  },
  {
    id: 2,
    opcion: `Año ${lastYear.year()}`,
    inicio: lastYear.clone().startOf('year').format(),
    fin: lastYear.clone().endOf('year').format()
  }
];

function RangoPeriodos(props) {
  return (
    <Dropdown onSelect={props.onSelect}>
      <Dropdown.Toggle variant='light' size='sm' id='dropdown-filtro-periodos' className='h5 m-0 ms-2 me-2 border'>
        {rangos[props.selected].opcion}
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount='true' align='end'>
        {rangos.map((item) => (
          <Dropdown.Item key={item.id} eventKey={item.id}>
            {item.opcion}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default RangoPeriodos;
