import React from 'react';
import Card from 'react-bootstrap/Card';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, defaults } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import moment from 'moment';

defaults.font.family = 'Montserrat';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

function GraficoPagos() {
  const { rows } = useSelector((store) => store.pagosPeriodo);
  const { fecha_vencimiento } = useSelector((store) => store.totalesPagos);
  /** El código a continuacion crea los arreglos labels[], colores[] y
   * montos[] para pintar el Grafico de pagos en el periodo seleccionado. */
  let labels = [];
  let colores = [];
  let montos = [];
  // Si no hay fecha vencimiento se crea una fecha muy futura, año 3000, para pintar montos vencidos.
  let fecha_limite = fecha_vencimiento ? moment(fecha_vencimiento).endOf('day') : moment({ year: 3000 });
  for (let i = 0; i < rows.length; i++) {
    let diaIndex = moment(rows[i].dia);
    labels.push(diaIndex.format('DD-MM-YYYY'));
    if (diaIndex.isAfter(fecha_limite)) colores.push('#b73f50');
    else colores.push('#7f7f7f');
    montos.push(rows[i].monto_pagado);
  }
  /** Ahora se realiza la configuración del grafico. */
  const options = {
    responsive: true,
    animation: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Días',
          padding: 1,
          font: { size: 10 }
        },
        ticks: {
          padding: 1,
          maxRotation: 0,
          font: {
            size: 8,
            lineHeight: 1
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Monto $M',
          padding: 1,
          font: { size: 9 }
        },
        ticks: {
          padding: 2,
          font: { size: 10 }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: 'Montos de pagos por día'
      }
    }
  };
  /** Ahora se carga etiquetas, datos y colores en el gráfico. */
  const datos = {
    labels,
    datasets: [
      {
        label: 'Montos $M',
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        data: montos,
        backgroundColor: colores
      }
    ]
  };

  return (
    <Card className='shadow-sm'>
      <Card.Body className='pt-0 pb-1'>
        <Bar options={options} data={datos} style={{ maxHeight: '11rem' }} />
      </Card.Body>
    </Card>
  );
}

export default GraficoPagos;
