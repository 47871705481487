import React from 'react';
import './LoginHome.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Login from '../componentes/Login';

function LoginHome() {
  return (
    <div className='LoginHome'>
      <Container fluid>
        <Row>
          <Col xs={12} md={4} lg={3} className='eplat-login-fondo'></Col>
          <Col xs={12} md={8} lg={9}>
            <div className='eplat-login-remain-altura d-flex align-items-center justify-content-center'>
              <Login />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginHome;
