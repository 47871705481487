import React from 'react';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';

function CardPlaceHolder() {
  return (
    <Card className='shadow-sm'>
      <Card.Body>
        <Card.Title>
          <Placeholder xs={6} size='lg' style={{ color: '#a6a6a6' }} />
        </Card.Title>
        <Card.Text style={{ color: '#bfbfbf' }}>
          <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} /> <Placeholder xs={6} /> <Placeholder xs={8} />
        </Card.Text>
        <Placeholder.Button variant='secondary' xs={6} />
      </Card.Body>
    </Card>
  );
}

export default CardPlaceHolder;
