import React from 'react';
import { useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import './ComunidadActual.css';

function ComunidadActual() {
  const comunidad = useSelector((store) => store.comunidad);

  return (
    <Nav as='ul' className='ComunidadActual d-flex flex-column h6'>
      <Nav.Item as='li' className='text-muted'>
        Comunidad
      </Nav.Item>
      <Nav.Item as='li' className='ancho-texto h4 m-0 fw-bold text-primary text-truncate'>
        {comunidad.selected.nombre}
      </Nav.Item>
    </Nav>
  );
}

export default ComunidadActual;
