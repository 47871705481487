import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const downloadSabanaSlice = createSlice({
  name: 'downloadSabana',
  initialState: {
    rows: []
  },
  reducers: {
    setSabana: (state, action) => {
      state.rows = action.payload;
    }
  }
});

export const { setSabana } = downloadSabanaSlice.actions;

// Action Creators
export const downloadSabana = (comunidadId, anno, mes) => async (dispatch, getState) => {
  let item = LocalCache.get(`KEY:downloadSabana&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
  if (item) {
    dispatch(setSabana(item));
  } else {
    try {
      const response = await API.get('ePlat', `/tarificacion-sabana-periodo?comunidad=${comunidadId}&ano=${anno}&mes=${mes}`);
      let sabana = response;
      dispatch(setSabana(sabana));
      LocalCache.put(`KEY:downloadSabana&comunidad=${comunidadId}&ano=${anno}&mes=${mes}`, sabana);
    } catch (error) {
      console.log('DVD: ERROR Llamada a downloadSabana()', error);
    }
  }
};

export default downloadSabanaSlice.reducer;
