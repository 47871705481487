import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';

export const medidorSlice = createSlice({
  name: 'medidor',
  initialState: {
    cabecera: null,
    lecturas: []
  },
  reducers: {
    setMedidor: (state, action) => {
      state.cabecera = action.payload.cabecera;
      state.lecturas = action.payload.lecturas;
    }
  }
});

export const { setMedidor } = medidorSlice.actions;

// Action Creators
export const getMedidor = (comunidadId, medidorId, rango) => async (dispatch) => {
  const inicio = rango.inicio;
  const fin = rango.fin;
  let item = LocalCache.get(`KEY:medidor&comunidad=${comunidadId}&medidor=${medidorId}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setMedidor(item));
  } else {
    try {
      const response = await API.get('ePlat', `/medidor?comunidad=${comunidadId}&medidor=${medidorId}&inicio=${inicio}&fin=${fin}`);
      let medidorInfo = { cabecera: response.medidor, lecturas: response.lecturas.reverse() };
      dispatch(setMedidor(medidorInfo));
      LocalCache.put(`KEY:medidor&comunidad=${comunidadId}&medidor=${medidorId}&inicio=${inicio}&fin=${fin}`, medidorInfo);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getMedidor()', error);
    }
  }
};

export default medidorSlice.reducer;
