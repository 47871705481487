import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, defaults } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { rangos } from '../../componentes/RangoPeriodos';
import moment from 'moment';

defaults.font.family = 'Montserrat';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

function GraficoRentabilidad(props) {
  const rentabilidad = useSelector((store) => store.rentabilidad);
  function getPeriodo(fecha) {
    let ano = fecha.year();
    let mes = fecha.month() + 1;
    return ano.toString() + '-' + (mes < 10 ? `0${mes.toString()}` : mes.toString());
  }
  /** Se construirán las series de datos sobre la base de el rango de periodos seleccionado. */
  let serieVenta = [];
  let serieCompra = [];
  let serieMargen = [];
  let labels = [];
  let colVenta = [];
  let colCompra = [];
  let colMargen = [];
  let rangoSelected = props.rango;
  let mesIndex = moment(rangos[rangoSelected].inicio);
  let mesFin = moment(rangos[rangoSelected].fin);
  let iteraciones = mesFin.diff(mesIndex, 'months') + 1;
  iteraciones = iteraciones > 13 ? 13 : iteraciones;
  for (let i = 0; i < iteraciones; i++) {
    let periodo = getPeriodo(mesIndex);
    let periodoTot = rentabilidad.rows.find((item) => item.periodo === periodo);
    let totalVenta = periodoTot ? periodoTot.neto_venta / 1000 : 0;
    let totalCompra = periodoTot ? periodoTot.neto_compra / 1000 : 0;
    let totalMargen = periodoTot ? periodoTot.margen / 1000 : 0;
    serieVenta.push(totalVenta);
    colVenta.push('#7c94bc');
    serieCompra.push(totalCompra);
    colCompra.push('#ebcf64');
    serieMargen.push(totalMargen);
    colMargen.push('#1f4e79');
    labels.push(periodo);
    mesIndex.add(1, 'months');
  }
  /** Ahora se realiza la configuración del grafico. */
  const options = {
    responsive: true,
    animation: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Períodos',
          padding: 1,
          font: { size: 10 }
        },
        ticks: {
          padding: 1,
          maxRotation: 0,
          font: {
            size: 8,
            lineHeight: 1
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Margen $M',
          padding: 1,
          font: { size: 9 }
        },
        ticks: {
          padding: 2,
          font: { size: 10 }
        }
      },
      y1: {
        title: {
          display: true,
          text: 'Monto $M',
          padding: 1,
          font: { size: 9 }
        },
        ticks: {
          padding: 2,
          font: { size: 10 }
        },
        position: 'right',
        grid: { drawOnChartArea: false }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 20,
          padding: 15,
          font: { size: 10 },
          color: '#1f4e79'
        },
        align: 'center'
      },
      title: {
        display: true,
        text: `Rentabilidad: ${rangos[rangoSelected].opcion}`
      }
    },
    elements: {
      point: {
        radius: 4
      },
      line: {
        borderWidth: 1,
        borderColor: '#6d757c'
      }
    }
  };
  /** Ahora se carga etiquetas, datos y colores en el gráfico. */
  const datos = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Margen $M',
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        data: serieMargen,
        backgroundColor: colMargen,
        yAxisID: 'y'
      },
      {
        type: 'bar',
        label: 'Venta $M',
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        data: serieVenta,
        backgroundColor: colVenta,
        yAxisID: 'y1'
      },
      {
        type: 'bar',
        label: 'Compra $M',
        barPercentage: 0.95,
        categoryPercentage: 0.95,
        data: serieCompra,
        backgroundColor: colCompra,
        yAxisID: 'y1'
      }
    ]
  };

  return <Chart {...props} options={options} data={datos} />;
}

export default GraficoRentabilidad;
