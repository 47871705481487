import { createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { LocalCache } from '../libreria/LocalCache';
import moment from 'moment';

export const totalesMedidoresSlice = createSlice({
  name: 'totalesMedidores',
  initialState: {
    data: {
      total: 0,
      activos: 0,
      medidoresBT1: 0,
      medidoresBT43: 0,
      inactivos: 0
    },
    lecturas: []
  },
  reducers: {
    setTotalesMedidores: (state, action) => {
      state.data = action.payload.data;
      state.lecturas = action.payload.lecturas;
    }
  }
});

export const { setTotalesMedidores } = totalesMedidoresSlice.actions;

// Action Creators
export const getTotalesMedidores = (comunidadId, rango) => async (dispatch) => {
  const inicio = rango.inicio;
  const fin = rango.fin;
  let item = LocalCache.get(`KEY:totalesMedidores&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
  if (item) {
    dispatch(setTotalesMedidores(item));
  } else {
    try {
      const response = await API.get('ePlat', `/total-medidores?comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`);
      let cabecera = prepararDataCabecera(response.data);
      let lecturas = completaDatosFaltantes(response.lecturas, inicio, fin);
      let info = { data: cabecera, lecturas: lecturas };
      dispatch(setTotalesMedidores(info));
      LocalCache.put(`KEY:totalesMedidores&comunidad=${comunidadId}&inicio=${inicio}&fin=${fin}`, info);
    } catch (error) {
      console.log('DVD: ERROR Llamada a getTotalesMedidores()', error);
    }
  }
};

function prepararDataCabecera(data) {
  let cabecera = new Object();
  let total = 0;
  let activos = 0;
  let medidoresBT1 = 0;
  let medidoresBT43 = 0;
  for (let i = 0; i < data.length; i++) {
    total = total + data[i].total;
    activos = activos + data[i].activos;
    medidoresBT1 = medidoresBT1 + (data[i].tipo_tarifa === 'bt1' ? data[i].activos : 0);
    medidoresBT43 = medidoresBT43 + (data[i].tipo_tarifa === 'bt4.3' ? data[i].activos : 0);
  }
  cabecera.total = total;
  cabecera.activos = activos;
  cabecera.medidoresBT1 = medidoresBT1;
  cabecera.medidoresBT43 = medidoresBT43;
  cabecera.inactivos = total - activos;
  return cabecera;
}

function getPeriodo(fecha) {
  let ano = fecha.year();
  let mes = fecha.month() + 1;
  return ano.toString() + '-' + (mes < 10 ? `0${mes.toString()}` : mes.toString());
}

function completaDatosFaltantes(datos, inicio, fin) {
  let lecturas = [];
  let mesIndex = moment(inicio);
  let mesFin = moment(fin);
  let iteraciones = mesFin.diff(mesIndex, 'months') + 1;
  iteraciones = iteraciones > 13 ? 13 : iteraciones;
  for (let i = 0; i < iteraciones; i++) {
    let periodo = getPeriodo(mesIndex);
    let periodoLectura = datos.find((item) => item.periodo === periodo);
    let consumo = periodoLectura ? periodoLectura.consumo : 0;
    lecturas.push({ periodo: periodo, consumo: consumo });
    mesIndex.add(1, 'months');
  }
  return lecturas;
}

export default totalesMedidoresSlice.reducer;
